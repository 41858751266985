import React, {useState} from "react";
import {apiQueryCustomerListBySuperAdmin, apiQueryProjectListBySuperAdmin} from "../api/apis";

export const ProjectManagementContext = React.createContext();

export const ProjectManageProvider = (props) => {
    const [projectManageList, setProjectManageList] = useState({});
    const defaultPageSize = 10;

    const getProjectManagementList = (params) =>{
        apiQueryProjectListBySuperAdmin(params).then((res)=>
            setProjectManageList(res))
    }

    return (
        <ProjectManagementContext.Provider value={{
            projectManageList,
            setProjectManageList,
            getProjectManagementList,
            defaultPageSize}}>
            {props.children}
        </ProjectManagementContext.Provider>
    )
}