import React, {useContext, useState} from "react";
import "./link.less";
import {Image, Switch, Button, Divider, Modal, Form, Upload, message} from "antd";
import ProTable from '@ant-design/pro-table';
import {
    LawFirmActiveImg
} from "../../constants/images";
import {
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import Input from "antd/es/input";
import {LawFirmContext} from "../../provider/lawFirm_provider";
import {useDidMount} from "../../common/state";

export function LinkPage() {

    const tabColumns = [
        {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
        },
        {
            title: '名称',
            dataIndex: 'linkName',
        },
        {
            title: '网址',
            dataIndex: 'link',
            render: (dom) => (
                <a target="_blank" href={dom}>{dom}</a>
            ),
        },
    ];

    const linkList = [
        {
            linkName:'国家企业信用信息公示系统（湖北）',
            link:'http://hb.gsxt.gov.cn/index.html'
        },
        {
            linkName:'企查查',
            link:'https://www.qcc.com/?utm_source=baidu1&utm_medium=cpc&utm_term=pzsy'
        },
        {
            linkName:'中国执行信息网',
            link:'http://zxgk.court.gov.cn'
        },
        {
            linkName:'中国裁判文书网',
            link:'https://wenshu.court.gov.cn'
        },
        {
            linkName:'北大法宝',
            link:'http://www.pkulaw.cn/'
        },
        {
            linkName:'中国司法案例网',
            link:'https://anli.court.gov.cn/'
        },
        {
            linkName:'最高人民法院指导案例',
            link:'http://www.court.gov.cn/'
        },
        {
            linkName:'无讼案例',
            link:'https://www.itslaw.com/home'
        },
        {
            linkName:'天眼查',
            link:'https://www.tianyancha.com/'
        },
        {
            linkName:'全国法院破产重整案件信息网',
            link:'http://pccz.court.gov.cn/'
        },
        {
            linkName:'中国庭审公开网',
            link:'http://tingshen.court.gov.cn/'
        },
        {
            linkName:'最高人民法院诉讼服务网',
            link:'http://ssfw.court.gov.cn/ssfww'
        },
        {
            linkName:'最高人民法院诉讼文书样式',
            link:'http://www.court.gov.cn/susongyang'
        },
        {
            linkName:'阿里拍卖 司法',
            link:'https://sf.taobao.com/'
        }
    ]

    return (
        <div className="law-firm card-box">
            <div className='law-firm-title'>
                <div className='law-firm-title-left'>
                    <Image src={LawFirmActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>常用工具</span>
                </div>
            </div>
            <div className='project-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={linkList}
                    rowKey="key"
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
        </div>
    )
}