import Col from "antd/es/grid/col";
import Divider from "antd/es/divider";
import Row from "antd/es/grid/row";
import React, {useContext, useEffect, useState} from "react";
import Menu from "antd/es/menu";
import {
    IconInstitutionDark,
    IconInstitutionLight,
    IconLawDocumentActiveImg,
    IconLawDocumentImg,
    IconMineDocumentActiveImg,
    IconMineDocumentImg,
    IconProjectDocumentActiveImg, IconProjectDocumentImg,
    IconShareDocumentActiveImg,
    IconShareDocumentImg,
} from "../../constants/images";
import Image from "antd/es/image";
import {RightOutlined} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {Form, Input, Modal, Space, Tag, Tooltip} from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import Button from "antd/es/button";
import {ProjectListContext} from "../../provider/project_list_provider";
import {useDidMount} from "../../common/state";
import {ProjectDetailDocument} from "../project/projectDetailDocument";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import "./userDocument.less";
import {ProjectFileContext} from "../../provider/file_provider";


export function UserDocument() {

    const [index, setIndex] = useState("1");
    const [isAddVisible, setIsAddVisible] = useState(false);
    const [userInfoDetail, setUserInfoDetail] = useState(false);

    useDidMount(()=>{
        let userInfoDetail = localStorage.getItem("userInfoDetail")
        setUserInfoDetail(eval("(" + userInfoDetail + ")"))
    })

    const callback = ( {key} ) => {
        setIndex(key)
    };

    return (
        <div className="user-document">
            <Row justify="center">
                <Col span={6}>
                    <DocumentationMenu index = {index} callback = {callback}/>
                </Col>
                <Col span={2} className='col-divider'>
                    <Divider type="vertical" orientation="center" className='divider-title'/>
                </Col>
                <Col span={16} className = 'content-right'>
                    {
                        index ==='1'?<ProjectDetailDocument ownerType={0}/>:
                            (index ==='3'?<MyOffice officeId={userInfoDetail.officeId}/>:<MyProjectList />)
                    }
                </Col>
            </Row>
            <AddModal isAddVisible={isAddVisible} setIsAddVisible={setIsAddVisible}/>
        </div>
    )
}

function MyOffice(props) {
    const {officeId} = props
    return (
        <ProjectDetailDocument ownerType={3} officeId={officeId}/>
    );

}

function MyProjectList() {
    const [isDoc, setIsDoc] = useState(false)
    const {getProjectList,defaultPageSize,joinList,setJoinList} = useContext(ProjectListContext)
    const [proId, setProId] = useState()
    useDidMount(()=>{
        getProjectList({
            searchName:'',
            stateList: [],
            searchType:"1", //1已加入
            sourceUserName:'',
            pageNo: 0,
            size:defaultPageSize
        })
    })

    function toProjectDoc(value){
        setProId(value)
        setJoinList([])
        setIsDoc(true)
    }

    function toProjectList(){
        getProjectList({
            searchName:'',
            stateList: [],
            searchType:"1", //1已加入
            sourceUserName:'',
            pageNo: 0,
            size:defaultPageSize
        })
        setIsDoc(false)
    }

    function onPageChange(page, size) {
        getProjectList({
            searchType:"0", //0全部，1已加入
            pageNo: page-1,
            size:size
        })
    }

    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: joinList?.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };


    const tabColumns = [
        {
            title:"项目名称",
            key:'projectName',
            dataIndex:'projectName',
        },
        {
            title: '负责人',
            key: 'adminName',
            dataIndex: 'adminName',
        },
        {
            title: '案号',
            key: 'projectNum',
            dataIndex: 'projectNum',
        },
        {
            title: '立案时间',
            key: 'createTime',
            dataIndex: 'createTime',
            valueType:'date',
        },
        {
            title:'操作',
            valueType: 'option',
            render: (_,record) => (
                <a  onClick={()=>toProjectDoc(record.projectId)}>查看资料库</a>
            ),
        }
    ];

    return (
        <div className='doc-project'>
           <div style={{textAlign:'left',paddingBottom:'10px',fontSize:'20px'}}>
               <a onClick={()=>toProjectList()}>{isDoc?"返回上一级":"项目列表"}</a>
           </div>
            {
                isDoc?<ProjectDetailDocument ownerType={1} projectId={proId}/>:
                    <ProTable
                        columns={tabColumns}
                        dataSource={joinList.list}
                        rowKey={r => (r.projectId)}
                        pagination={paginationProps}
                        options={false}
                        search={false}
                        dateFormatter="string"
                    />
            }
        </div>
    );

}

function DocumentationMenu(props) {

    const {index,callback} = props

    return (
        <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={[]}
            mode="vertical"
            theme="light"
            className = "document-menu"
            onClick={callback}
        >
            <Menu.Item key="1">
                <DocumentMenuItem isActive = {index==="1"} menuTitle ={"我的资料库"}
                                  img={index==="1"?IconMineDocumentActiveImg:IconMineDocumentImg}/>
            </Menu.Item>
            <Divider type="horizontal" orientation="center" className='horizontal-divider'/>
            {/*<Menu.Item key="2">*/}
            {/*    <DocumentMenuItem isActive = {index==="2"} menuTitle ={"共享给我的"}*/}
            {/*                      img={index==="2"?IconShareDocumentActiveImg:IconShareDocumentImg}/>*/}
            {/*</Menu.Item>*/}
            {/*<Divider type="horizontal" orientation="center" className='horizontal-divider'/>*/}
            <Menu.Item key="3">
                <DocumentMenuItem isActive = {index==="3"} menuTitle ={"律所资料库"}
                                  img={index==="3"?IconLawDocumentActiveImg:IconLawDocumentImg}/>
            </Menu.Item>
            <Divider type="horizontal" orientation="center" className='horizontal-divider'/>
            <Menu.Item key="4">
                <DocumentMenuItem isActive = {index==="4"} menuTitle ={"项目资料库"}
                                  img={index==="4"?IconProjectDocumentActiveImg:IconProjectDocumentImg}/>
            </Menu.Item>
            <Divider type="horizontal" orientation="center" className='horizontal-divider'/>

        </Menu>
    )
}

//目录item
function DocumentMenuItem(props){

    const {isActive,menuTitle,img} = props

    return (
        <div className="menu-item-background">
            <Row justify="space-around"  align="middle">
                <Col span = {6} className='img-box'>
                    <Image
                        src={img}
                        preview={false}
                        width = {35}
                    />
                </Col>
                <Col span = {15}>{menuTitle}</Col>
                <Col span = {3}>
                    {isActive? <RightOutlined className ={"text-active"}/>:<div/>}
                </Col>

            </Row>
        </div>
    )
}


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
//添加资料库弹窗
function AddModal(props){

    const {isAddVisible, setIsAddVisible} = props

    const handleOk = () => {
        setIsAddVisible(false);
    };

    const handleCancel = () => {
        setIsAddVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isAddVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>新建资料库</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="资料库名称"
                                name="name"
                                rules={[{ required: true, message: '请输入资料库名称' }]}
                            >
                                <Input placeholder="请输入资料库名称" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}