import React, {useContext, useEffect, useMemo, useState} from "react";
import {RootContext} from "./root_provider";
import {
    apiBindWx,
    apiGetUserInfo,
    apiLeaveOffice,
    apiLoginBySms,
    apiUnbindWx,
    apiWxLogin,
    quitLogin,
    UpdateNew
} from "../api/apis";
import {browserHistory} from "../router";
import {message} from "antd";
import {AppStore} from "../store/RootStore";
import {useConstruct} from "../common/state";

export const UserInfoContext = React.createContext();

/**
 * 全局User Info
 * @param props
 * @returns {*}
 * @constructor
 */
export const UserInfoProvider = (props) => {
    const userStore = AppStore.userStore;
    const {setGlobalLoading} = useContext(RootContext)
    const [userInfo, setUserInfo] = useState(null)
    const [userInfoDetail, setUserInfoDetail] = useState(null)
    const [token, setToken] = useState(null)
    const [login, setLogin] = useState(false)

    useConstruct(() => {
        setUserInfo(JSON.parse(localStorage.getItem("userInfo")))
        setToken(localStorage.getItem("token"))
    })

    const setUser = (login, token, userInfo) => {
        setLogin(login)
        setToken(token);
        setUserInfo(userInfo)
        localStorage.setItem("login", login);
        localStorage.setItem("token", token);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }

    const testLogin = () => {
        setUser(true, "test", {userName: "哈哈哈哈哈哈"})
        browserHistory.push("/");
    }

    const doLogin = (username, verifyCode) => {
        apiLoginBySms({
            userName: username,
            verifyCode: verifyCode,
        }).then((res) => {
            setUser(true, res.token, res.basicUserInfo)
            getUserInfoDetail()
            message.success('登录成功', 1).then(() => {
                browserHistory.push("/");
            })
        })
    }

    //微信登录请求
    const doWxLogin = async (code) => {
        try {
            const res = await apiWxLogin(code)
            setUser(true, res.token, res.basicUserInfo)
            await getUserInfoDetail()
            browserHistory.push("/");
            await message.success('微信登录成功', 3)
        } catch (e) {
            throw e
        }
    }

    //绑定微信
    const bindWx = async (code) => {
        try {
            await apiBindWx(code)
            getUserInfoDetail()
            message.success('微信绑定成功', 3)
        } catch(e) {
            console.log(e)
            message.success('微信绑定失败', 3)
            throw e
        }
    }

    // 解除绑定微信
    const unBindWx = async () => {
        try {
            await apiUnbindWx()
            getUserInfoDetail()
            message.success('微信解绑成功', 3)
        } catch(e) {
            message.success('微信解绑失败', 3)
            console.log(e)
        }
    }

    const doLogout = async () => {
        quitLogin().then((res) => {
            localStorage.setItem("login", false);
            localStorage.setItem("token", null);
            localStorage.setItem("userInfo", null);
            setLogin(false)
            setUserInfo({})
            userStore.clearToken()
            browserHistory.push("/login");
        })
    }


    const getUserInfoDetail = () => {
        return apiGetUserInfo().then((res) => {
            if(res.professionalField!=undefined){
                let b = '';
                const professionalFields = [
                    '民商事代理',
                    '刑事代理',
                    '常年法律顾问及专项法律服务',
                    '知识产权相关',
                    '公司并购、破产与清算',
                    '证券法律服务'
                ];
                for(let item of [...res.professionalField.replace(/,/g, "")]){
                    b += professionalFields[item-1] + ' '
                }
                console.log(b)
                res.professionalFieldnew = b
            }
             setUserInfoDetail(res)
             localStorage.setItem("userInfoDetail", JSON.stringify(res));
             console.log(res)

        })
    }
    // 教育背景专业领域
    const getDateUser = (a,b) => {
        // console.log(a)
        // console.log(b)
        // {
        //     education:a
        //     professionalField:b
        // }
        b!=null?b = b.toString():b=null;
        console.log(a,b)
        return UpdateNew(
            {
                    education:a,
                    professionalField:b,
            }
        ).then((res) => {
            // console.log(res)
            console.log('Yes')
            // setUserInfoDetail(res)
            // localStorage.setItem("userInfoDetail", JSON.stringify(res));
        })
    }
    // 经典案例个人荣誉
    const getCasesHonorUser = (a,b) => {
        // console.log(a)
        // console.log(b)
        // {
        //     education:a
        //     professionalField:b
        // }
        console.log(a);
        console.log(b);
        b!=null?b = b.toString():b=null;
        return UpdateNew(
            {
                cases:a,
                personalHonor:b,
            }
        ).then((res) => {
            // console.log(res)
            console.log('Yes')
            // setUserInfoDetail(res)
            // localStorage.setItem("userInfoDetail", JSON.stringify(res));
        })
    }

    // 修改头像
    const setHeadImg = (a) => {
        return UpdateNew(
            {
                headImg:a,
            }
        ).then((res) => {
            // console.log(res)
            console.log('Yes')
        })
    }
    const leaveOffice = () => {
        apiLeaveOffice({}).then((res) => {
                browserHistory.push("/login");
            }
        )
    }

    return (
        <UserInfoContext.Provider value={{
            userInfo, doLogin, doLogout, login, testLogin, token, getUserInfoDetail, userInfoDetail, leaveOffice,
            doWxLogin, bindWx, unBindWx,getDateUser, getCasesHonorUser, setHeadImg
        }}>
            {props.children}
        </UserInfoContext.Provider>
    )
}
