import {Col, Dropdown, Image, Input, Menu, Space, Tabs, Tag, Tooltip} from "antd";
import ProTable from "@ant-design/pro-table";
import {CaretDownOutlined, RightOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import {SearchDark, SearchImg} from "../../constants/images";
import React, {useContext, useEffect, useState} from "react";
import {useDidMount} from "../../common/state";
import {ProjectListContext} from "../../provider/project_list_provider";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {ProjectDetailContext} from "../../provider/project_detail_provider";

const projectStatusList = ["预立案",'进行中','已完结','已搁置']
const projectLevelList = ["无",'低','中','高']
const cateList  = ["全部","预立案","进行中","已完结","已搁置","进行中+预立案"];
const cateSearchList  = ["项目名称","案源律师名称"];
export function ProjectList(props) {
    const {setIndex,cateText,setCateText,cateValue,setCateValue,inputValue,setInputValue,cateSearch,setCateSearch,cateSearchValue,setCateSearchValue} = props
    const { TabPane } = Tabs;
    const {projectTypeList,getProjectTypeList} = useContext(ProjectCreatContext)
    const {getProjectDetail,getProjectAj} = useContext(ProjectDetailContext)
    const {getProjectList,defaultPageSize,joinList} = useContext(ProjectListContext)
    const [ projectTypeMap, setProjectTypeMap] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    useDidMount(()=>{
        getProjectTypeList()
    })

    function handleUpdate(id) {
        console.log(2134)
        getProjectDetail({
            id:id,
        })
        getProjectAj({
            id:id,
        })
        setIndex('2')
    }

    useEffect(()=>{
        let list = []
        projectTypeList.map((item)=>{
            list = list.concat(item.secondList)
        })
        setProjectTypeMap(list)
    },[projectTypeList])

    const selectMenuOnClick = ({ key }) => {
        setCateText(cateList[key])
        if(key==0){
            setCateValue([])
        }else if(key==5){
            setCateValue([0,1])
        }else{
            setCateValue([key-1])
        }
    };

    const searchOnClick = async () => {
        //搜索方法
        if (cateSearchValue == 0) {
            await getProjectList({
                searchName: inputValue,
                stateList: cateValue,
                searchType: "1", //1已加入
                sourceUserName: '',
                pageNo: 0,
                size: pageSize
            })
        } else {
            await getProjectList({
                searchName: '',
                stateList: cateValue,
                searchType: "1", //1已加入
                sourceUserName: inputValue,
                pageNo: 0,
                size: pageSize
            })
        }
    };

    const searchOnChange = (e) => {
        setInputValue(e.target.value)
    };

    const selectMenu = (
        <Menu onClick={selectMenuOnClick}>
            <Menu.Item key="0">{cateList[0]}</Menu.Item>
            <Menu.Item key="1">{cateList[1]}</Menu.Item>
            <Menu.Item key="2">{cateList[2]}</Menu.Item>
            <Menu.Item key="3">{cateList[3]}</Menu.Item>
            <Menu.Item key="4">{cateList[4]}</Menu.Item>
            <Menu.Item key="5">{cateList[5]}</Menu.Item>
        </Menu>
    );


    const onClick = ({ key }) => {
        console.log("key",key)
        setCateSearch(cateSearchList[key])
        setCateSearchValue(key)
    };

    const menu = (
        <Menu onClick={onClick}>
            <Menu.Item key="0">{cateSearchList[0]}</Menu.Item>
            <Menu.Item key="1">{cateSearchList[1]}</Menu.Item>
        </Menu>
    );

    function onPageChange(size) {
        setPageSize(size)
    }

    const  paginationProps = {
        showSizeChanger: true,
        defaultPageSize:defaultPageSize,
        onShowSizeChange:(current,size)=>onPageChange(size),
        current:joinList?.pageNo+1,
        total: joinList?.totalNum,
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    const tabColumns = [
        {
            title:"项目信息"
            //     (
            //     <div className='project-box-title'>
            //         共{joinList.totalNum}个项目
            //         <Tooltip placement="top" title="0个项目处于审批中，点击查看">
            //             <div className='project-box-title-tip' onClick={()=>{console.log("前去审批页面")}}>0个项目处于审批中<RightOutlined /></div>
            //         </Tooltip>
            //     </div>
            // )
            ,
            // width:380,
            key:'projectName',
            dataIndex:'projectName',
            render: (dom, record) => (
                <div className='project-box-item' style={{cursor: 'pointer'}} onClick={()=>{handleUpdate(record.projectId)}}>
                    <Space className='project-box-item-space'>
                        {/*{record.focusState==='1'?"":<Tag color='#FFAB3B' style={{marginRight:'0px'}}>已关注</Tag>}*/}

                        <span className='project-box-tab-title'>{dom}</span>
                        <Tag style={{ padding: '0 10px',borderRadius: '25px',margin:' 0 0 0 2px'}}>{projectStatusList[record.state]}</Tag>
                    </Space>
                    <div className='project-box-item-info'>{record.fromName}</div>
                    <div className='project-box-item-info'>项目类型：{projectTypeMap[record.caseType-1]?.value}</div>
                </div>
            ),
        },
        {
            title: '案号',
            key: 'projectNum',
            dataIndex: 'projectNum',
        },
        {
            title: '客户信息',
            key: 'customerList',
            dataIndex: 'customerList',
            render: (dom) => (
               <div>
                   {
                       dom.map((item,index)=>
                           <span>{item.customerName+(index===dom.length-1?"":'/')}</span>
                       )
                   }
               </div>
            ),
        },
        // {
        //     title: '最新动态',
        //     key: 'recentNews',
        //     dataIndex: 'recentNews',
        //     sorter: (a, b) => a.recentNews - b.recentNews,
        // },
        {
            title: '案源律师',
            key: 'sourceUserName',
            dataIndex: 'sourceUserName',
        },
        {
            title: '立案时间',
            key: 'createTime',
            dataIndex: 'createTime',
            valueType:'date',
            sorter: (a, b) => a.createDate - b.createDate,
        },
        {
            title: '累计计时',
            key: 'totalSecond',
            dataIndex: 'totalSecond',
            valueType:'time',
            sorter: (a, b) => a.totalTime - b.totalTime,
        },
        {
            title: '任务',
            key: 'taskNum',
            dataIndex: 'taskNum',
            sorter: (a, b) => a.task - b.task,
        },
        {
            title:'项目等级',
            key: 'projectLevel',
            dataIndex: 'projectLevel',
            render: (dom, record) => (
                <div>
                    {projectLevelList[dom]}
                </div>
            ),
        },
        {
            title:'',
            width: 100,
            valueType: 'option',
            render: (_,record) => (
                <div style={{display:'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center'}}>
                    {/*{record.focusState==='0'?<StarFilled style={{ fontSize: '20px',marginBottom:'20%',color:'#FFAB3B'}}/>:<StarOutlined style={{ fontSize: '20px',marginBottom:'20%'}}/>}*/}
                    {record.belongMember==0?<span>未加入</span>:<span>已加入</span>}
                </div>
            ),
        }
    ];

    return (
        <div className="project-list card-box normal-tabs">
            <Tabs defaultActiveKey="1" tabBarGutter={40}>
                {/*<TabPane tab="所有项目" key="1">*/}
                {/*    <div className='project-box'>*/}
                {/*        <ProTable*/}
                {/*            columns={tabColumns}*/}
                {/*            dataSource={projectList.list}*/}
                {/*            rowKey={r => (r.projectId)}*/}
                {/*            pagination={paginationProps}*/}
                {/*            options={false}*/}
                {/*            search={false}*/}
                {/*            dateFormatter="string"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</TabPane>*/}
                {/*<TabPane tab="我关注的" key="3">*/}
                {/*    <div className='project-box'>*/}
                {/*        <ProTable*/}
                {/*            columns={tabColumns}*/}
                {/*            request={(params, sorter, filter) => {*/}
                {/*                // 表单搜索项会从 params 传入，传递给后端接口。*/}
                {/*                console.log(params, sorter, filter);*/}
                {/*                return Promise.resolve({*/}
                {/*                    data: tableListDataSource,*/}
                {/*                    success: true,*/}
                {/*                });*/}
                {/*            }}*/}
                {/*            rowKey="key"*/}
                {/*            pagination={{*/}
                {/*                showQuickJumper: true,*/}
                {/*            }}*/}
                {/*            options={false}*/}
                {/*            search={false}*/}
                {/*            dateFormatter="string"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</TabPane>*/}
                <TabPane tab="已加入的" key="1">
                    <div className='project-box'>
                        <ProTable
                            columns={tabColumns}
                            request={async (params, sorter) => {
                                let key;
                                let value;
                                if(sorter!=null && Object.keys(sorter).length>0){
                                   key =  Object.keys(sorter)[0]
                                    console.log(key)
                                   value = Object.values(sorter)[0]
                                    console.log(value)
                                }
                                const res = await getProjectList({
                                    searchName: cateSearchValue==0?inputValue:"",
                                    stateList: cateValue,
                                    searchType: "1", //1已加入
                                    sourceUserName: cateSearchValue==0?'':inputValue,
                                    pageNo: params.current-1,
                                    size: params.pageSize,
                                    sidx:key,
                                    order:value
                                });
                                return Promise.resolve({
                                    data: res.list,
                                    success: true,
                                    total:res.totalNum
                                });
                            }}
                            dataSource={joinList.list}
                            rowKey={r => (r.projectId)}
                            pagination={paginationProps}
                            options={false}
                            search={false}
                            dateFormatter="string"
                        />
                    </div>
                </TabPane>
            </Tabs>

            <div className='search-box'>
                <div className='search-cate'>
                    <Dropdown overlay={selectMenu} placement="bottomCenter">
                        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {cateText} <CaretDownOutlined />
                        </span>
                    </Dropdown>
                </div>
                <div className='search-box-input'>
                    <Input size="large" placeholder="按名称搜索" className='title-input'
                           prefix={
                               <Dropdown overlay={menu} >
                                   <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                       {cateSearch}<CaretDownOutlined />
                                   </a>
                               </Dropdown> }
                           suffix={
                               <Image src={SearchImg} preview={false} width={20} onClick={searchOnClick}/>
                           }
                           onPressEnter={searchOnClick}
                           onChange={(e) =>{searchOnChange(e)}}
                           value={inputValue}
                    />
                    <div className='add-box'><a onClick={()=>{setIndex('3')}}>+新建</a></div>
                </div>
            </div>

        </div>
    )
}