import api from './api';
import {useRequest} from "ahooks";

// export function ahooksPost(url,request) {
//     const { data, error, loading } = useRequest(
//             url,
//     {
//                 requestMethod: (param) => api.post(param),
//                 initialData: request
//             },
//         );
//     if (error) {
//         return null;
//     }
//     if (loading) {
//         return null;
//     }
//     return data?.data;
// }
//
// export function ahooksGet(url,request) {
//     const { data, error, loading } = useRequest(
//         url,
// {
//             requestMethod: (param) => api.get(param),
//             initialData: request
//         },
//     );
//     if (error) {
//         return null;
//     }
//     if (loading) {
//         return null;
//     }
//     return data?.data;
// }

//登录
export function doLoginRequest(userName,password,platform) {
    return api.post('/api/auth/login',{userName,password,platform})
}
//重置密码
export function doResetPwd(oldPwd,newPwd1,newPwd2) {
    return api.post('/api/auth/updatePwd',{oldPwd,newPwd1,newPwd2})
}
//找回密码
export function doFindPwd(userName,pwd,verifyCode) {
    return api.post('/api/auth/findPwd',{userName,pwd,verifyCode})
}

//发送验证码
export function sendSMSCode({userName,recaptcha}) {
    return api.post('/api/auth/requestLoginSms',{userName,recaptcha});
}
//注册
//    请求 URL:
export function doRegister(userName,pwd,inviteCode,verifyCode) {
    return api.post("/api/auth/register",{userName,pwd,inviteCode,verifyCode})
}
//退出登录
export function quitLogin() {
    return api.post("/api/auth/logout");
}

//测试
export function testApi() {
    return api.post("test");
}

// 请求登录验证码
export const apiRequestLoginSms = async (userName, recaptcha)=>
    api.post("/api/auth/requestLoginSms", {userName, recaptcha})

// 登录 -验证码
export const apiLoginBySms = async ({userName, verifyCode})=>
    api.post("api/auth/loginByVerifyCode", {userName, verifyCode})

// 超级管理员创建律所
export const apiOfficeCreate = async ({address,adminPhone, adminRealName,brief,logo,name})=>
    api.post("api/office/create", {address,adminPhone,adminRealName,brief,logo,name})


// 创建客户
export  function apiCreateCustomer(params) {
    return api.post('api/customer/createCustomerByLawyer',params);
}

// 超级管理员设置律所状态
export const apiSetOfficeState = async ({officeId,state})=>
    api.post("api/office/setOfficeState", {officeId,state})


//获取客户列表
export function apiCustomerList(params) {
    return api.post('api/customer/queryCustomerList',params);
}

//获取客户统计数据
export  function apiCustomerData(params) {
    return api.post('api/customer/queryCustomerNum',params);
}
//获取客户详情
export  function apiCustomerDetail(params) {
    return api.get('api/customer/queryCustomerDetail/'+params.id,params);
}
//修改客户详情
export  function apiCustomerUpdate(params) {
    return api.post('api/customer/updateCustomerByLawyer',params);
}

//获取律所列表
export function apiLawFirmList(params) {
    return api.post('api/office/query',params);
}

//获取律所列表
export function apiLawAddUser(params) {
    return api.post('api/office/addUser',params);
}

//创建团队
export function apiCreateTeam(params) {
    return api.post('api/team/create',params);
}

//修改团队名称
export function apiUpdateTeamName(params) {
    return api.post('api/team/updateTeamName',params);
}

//团队管理员移交管理员权限
export function apiAccreditAdminAuth(params) {
    return api.post('api/team/accreditAdminAuth',params);
}

//团队管理员邀请律师加入团队
export function apiInviteJoinTeam(params) {
    return api.post('api/team/inviteJoinTeam',params);
}

//团队管理员将团队用户踢出团队
export function apiDismissalTeamUser(params) {
    return api.post('api/team/dismissalTeamUser',params);
}

//获取常用团队列表
export function apiQueryTeamList(params) {
    return api.post('api/team/queryTeamList');
}

//获取不常用团队列表
export function apiQueryFewTeamList(params) {
    return api.post('api/team/queryFewTeamList');
}

//获取用户在当前律所已加入的团队列表
export function apiQueryCurJoinedTeam(params) {
    return api.get('api/team/queryCurJoinedTeam');
}

//获取团队成员列表
export function apiQueryTeamUserList(params) {
    return api.post('api/team/queryTeamUserList',params);
}

//用户退出团队
export function apiQuitTeam(params) {
    return api.post('api/team/quitTeam',params);
}

//用户删除团队
export function apiSetTeamUsualStatus(params) {
    return api.post('api/team/setTeamUsualStatus',params);
}

//获取律所所有成员列表以及团队加入情况
export function apiQueryTeamUserJoinList(params) {
    return api.post('api/team/queryTeamUserJoinList',params);
}

//查询当前律所的用户列表
export function apiQueryUserListByCurOfficeId(params) {
    return api.post('api/user/queryUserListByCurOfficeId',params);
}

//查询等级列表
export function apiQueryAllGrades() {
    return api.post('api/user/allGrades');
}

//管理员调整用户等级
export function apiSetUserGrade(params) {
    return api.post('api/user/setUserGrade',params);
}

//获取所有职位信息
export function apiGetAllPosition() {
    return api.post('api/user/allPosition');
}

//管理员给用户添加职位
export function apiAddUserPosition(params) {
    return api.post('api/user/addUserPosition',params);
}


//创建任务
export function apiCreateTask(params) {
    return api.post('api/task/create',params);
}

//查询任务
export function apiQueryTask(params) {
    return api.post('api/task/query',params);
}

//修改任务
export function apiUpdateTask(params) {
    return api.post('api/task/update',params);
}

//任务开始计时
export function apiTaskStartTimer(params) {
    return api.post('api/task/startTimer',params);
}

//任务结束计时
export function apiTaskStopTimer(params) {
    return api.post('api/task/stopTimer',params);
}


//任务列表
export function apiTaskList(params) {
    return api.post('api/task/list',params)

}

//超级管理员为律所添加管理员
export function apiSetAdminToOffice(params) {
    return api.post('api/office/setAdminToOffice',params);
}

//超级管理员取消律所管理员
export function apiCancelAdminByOfficeId(params) {
    return api.post('api/office/cancelAdminByOfficeId',params);
}

//用章列表
export function apiStampList(params) {
    return api.post('api/project/stampList',params);
}

//用章详情
export function apiStampDetail(params) {
    return api.get('api/project/stampDetail/'+params.id);
}

//用章申请
export function apiStampCreate(params) {
    return api.post('api/project/createStamp',params);
}

//超级管理员获取客户列表
export function apiQueryCustomerListBySuperAdmin(params) {
    return api.post('api/customer/queryCustomerListBySuperAdmin',params);
}

//查询用户列表
export function apiGetUserList(params) {
    return api.post('api/user/list',params);
}

//查询用户列表
export function apiGetUserInfo(params) {
    return api.post('api/user/info',params);
}

//律所管理员将用户踢出律所
export function apiExpelUserByAdmin(params) {
    return api.post('api/user/expelUserByAdmin',params);
}

//律所管理员重新启用离职律师
export function apiRestartLawyer(params) {
    return api.post('api/user/restartLawyer',params);
}

//律所管理员删除律师记录
export function apiDeleteLawyerRecord(params) {
    return api.post('api/user/deleteLawyerRecord',params);
}

//律师离职
export function apiLeaveOffice(params) {
    return api.post('api/user/leaveOffice',params);
}

//项目-获取项目类型
export function apiGetProjectType() {
    return api.get('api/project/getProjectType');
}

//项目-获取项目审级
export function apiGetProjectAudit() {
    return api.get('api/project/getProjectAudit');
}

//部门页面获取项目列表
export function apiQueryProjectByTeam(params) {
    return api.post('api/project/queryProjectByTeam',params);
}

//项目-获取项目列表
export function apiGetProjectList(params) {
    return api.post('api/project/queryProjectList',params);
}

//超级管理员获取项目列表
export function apiQueryProjectListBySuperAdmin(params) {
    return api.post('api/project/queryProjectBySuper',params);
}

//获取项目详情
export function apiQueryProjectDetail(params) {
    return api.get('api/project/getProjectDetail/'+params.id)}

//项目-获取当前团队成员列表
export function apiQueryCurWorkingTeamUser(params) {
    return api.post('api/team/queryCurWorkingTeamUser',params);
}

//项目-获取当前团队成员列表
export function apiGetCurOfficeUserList(params) {
    return api.post('api/user/getCurOfficeUserList',params);
}

//项目-获取客户列表
export function apiQueryAllCustomer(params) {
    return api.post('api/customer/queryAllCustomer',params);
}

//项目-创建项目
export function apiCreateProject(params) {
    return api.post('api/project/createProject',params);
}

//项目-添加项目成员
export function apiAddProjectUser(params) {
    return api.post('api/project/addProjectUser',params);
}

//项目-移除项目成员
export function apiRemoveProjectUser(params) {
    return api.post('api/project/removeProjectUser',params);
}

//项目-设置项目管理员
export function apiSetProjectAdmin(params) {
    return api.post('api/project/setProjectAdmin',params);
}

//项目-移除项目成员
export function apiUpdateProject(params) {
    return api.post('api/project/updateProject',params);
}

//项目-获取项目列表（不分页）
export function apiQueryProjectBySelf() {
    return api.get('api/project/queryProjectBySelf');
}

//项目-获取项目成员列表
export function apiQueryMembersByProject(params) {
    return api.get('api/project/queryMembersByProject/'+params.id);
}

//项目-获取项目任务列表
export function apiListByProjectId(params) {
    return api.post('api/task/listByProjectId',params);
}

//项目-获取项目用章列表
export function apiGetStampList(params) {
    return api.post('api/project/stampList',params);
}

//项目-获取项目用章详情
export function apiGetStampDetail(params) {
    return api.get('api/project/stampDetail/'+params.id);
}

//项目-用章申请
export function apiCreateStamp(params) {
    return api.post('api/project/createStamp',params);
}

//项目-获取当前律所的特定职位人员 风控：r_risk 行政：r_administrative 财务：r_finance
export function apiQueryPositionByOfficeId(params) {
    return api.post('api/user/queryPositionByOfficeId',params);
}

//项目-新建合同
export function apiCreateContract(params) {
    return api.post('api/project/createContract',params);
}

//项目-获取合同列表
export function apiGetContractList(params) {
    return api.post('api/project/contractList',params);
}

//项目-获取合同详情
export function apiGetContractDetail(params) {
    return api.get('api/project/contractDetail/'+params.id);
}

//项目-新建发票
export function apiCreateInvoice(params) {
    return api.post('api/project/createInvoice',params);
}

//项目-获取发票列表
export function apiGetInvoiceList(params) {
    return api.post('api/project/invoiceList',params);
}

//项目-获取发票详情
export function apiGetInvoiceDetail(params) {
    return api.get('api/project/invoiceDetail/'+params.id);
}

//新建文件夹
export function apiCreateFolder(params) {
    return api.post('api/fm/create_folder',params);
}

//新建文件夹
export function changeFileName(fileId, fileName) {
    return api.post('api/fm/change_name',{fileId, fileName});
}

//上传图片
export function apiCreateImageFile(params) {
    return api.post('api/fm/create_image_file',params);
}

//新建文件
export function apiCreateFile(params) {
    return api.post('api/fm/create_file',params);
}

//新建普通文件
export function apiCreateNormalFile(params) {
    return api.post('api/fm/create_normal_file',params);
}

//确定文件
export function apiConfirmFile(params) {
    return api.post('api/fm/confirm_file',params);
}
//上传法大大文件
export function apiUploadFaaFile(params) {
    return api.post('api/fm/upload_faa_File',params);
}
//浏览文件
export function apiGetView(params) {
    return api.post('api/fm/view',params);
}

//删除文件*文件夹
export function apiDeleteFile(params) {
    return api.post('api/fm/delete',params);
}

//下载文件
export function apiDownloadFile(params) {
    return api.get('api/fm/download/'+params.fileId);
}

//获取审批列表
export function apiGetApprovalList(params) {
    return api.post('api/approval/list',params);
}

//获取未处理的审批数量
export function apiGetApprovalNum(params) {
    return api.post('api/approval/query_category_num',params);
}

//审批通过
export function apiPassApproval(params) {
    return api.post('api/approval/pass',params);
}

//审批驳回
export function apiRejectApproval(params) {
    return api.post('api/approval/reject',params);
}

//改变发票领取状态
export function apiUpdateInvoiceReceiveStatus(params) {
    return api.post('api/project/updateInvoiceReceiveStatus',params);
}

//创建项目审批
export function apiCreateProjectApproval(params) {
    return api.post('api/approval/create_project_approval',params);
}


//修改文书编号
export function apiUpdateDocumentsNumber(params) {
    return api.post('api/project/updateDocumentsNumber',params);
}

//项目名称查询利冲
export function apiQueryConflictByProjectName(params) {
    return api.post('api/project/queryConflictByProjectName',params);
}

//客户名称查询利冲
export function apiQueryConflictByCustomerName(params) {
    return api.post('api/project/queryConflictByCustomerName',params);
}

//获取项目模板列表
export function apiQueryTaskTempList(params) {
    return api.post('api/task/queryTaskTempList',params);
}

//获取项目动态
export function apiQueryProjectMsg(params) {
    return api.post('api/project/queryProjectMsg',params);
}

//获取用户地位列表
export function apiQueryCustomerPosition() {
    return api.get('api/project/queryCustomerPosition');
}

//获取项目的客户列表
export function queryCustomerListByProjectId(params) {
    return api.post('api/customer/queryCustomerListByProjectId',params);
}

//确认发票领取状态
export function updateInvoiceConfirmStatus(params) {
    return api.post('api/project/updateInvoiceConfirmStatus',params);
}

//确认发票废弃状态
export function apiUpdateInvoiceAbandonStatus(params) {
    return api.post('api/project/updateInvoiceAbandonStatus',params);
}

//填写发票回款
export function updateInvoiceReceivable(params) {
    return api.post('api/project/updateInvoiceReceivable',params);
}

//填写发票编号
export function updateInvoiceNumber(params) {
    return api.post('api/project/updateInvoiceNumber',params);
}

//微信登录
export const apiWxLogin = (wxCode) => api.post('/api/auth/wxLogin',{wxCode})

// 绑定微信
export const apiBindWx = (wxCode) => api.post('/api/auth/bindWx',{wxCode})

export const apiUnbindWx = () => api.post('/api/auth/unbindWx')



// 新增修改用户信息
export function UpdateNew(params) {
    return api.post('api/user/updateUser',params);
}
//新增编辑查询卡
export function apiUpdateProjectNew(params) {
    return api.post('api/project/createProjectUser',params);
}
// 修改
export function apiUpdateProjectDelet(params) {
    return api.post('api/project/updateProjectUser',params);
}
// 查询
export function apiQueryProjectDetailAj(params) {
    return api.get('api/project/getProjectUser/'+params.id)
}
// 删除查询卡
export function setProjectAdminSc(params) {
    return api.post('api/project/deleteProjectUser',params);
}
