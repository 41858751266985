import React, {useContext, useEffect, useState} from "react";
import {
    Row,
    Col,
    Divider,
    Menu,
    Modal,
    Form,
    Dropdown,
    Button,
    DatePicker,
    Upload,
    Input,
    InputNumber,
    Radio,
    Checkbox, Tooltip, message, Select
} from "antd";
import {CaretDownOutlined, CopyOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {useDidMount} from "../../common/state";
import {ProjectFinanceContext} from "../../provider/project_finance_provider";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {ProjectStampContext} from "../../provider/project_stamp_provider";
import moment from "moment";
import {UploadListBox} from "../../common/uploadList";
import {ProjectFileContext} from "../../provider/file_provider";
import copy from "copy-to-clipboard";

const { Option } = Select;

export function ProjectDetailFinance() {

    const invoiceTypeList = ["增值税普通发票","增值税专用发票"]
    const contractStatusTypeList = ["待审核","已审核","已驳回"]
    const invoiceStatusTypeList = ["待开票","已开票","已驳回"]
    const [ isContractModal, setIsContractModal] = useState(false);
    const [ isInvoiceModal, setIsInvoiceModal] = useState(false);
    const [contractDetailModel,setContractDetailModel] = useState(false)
    const [isInvoiceDetail,setIsInvoiceDetail] = useState(false)
    const { contractList,getContractList,
        contractDetail,getContractDetail,
        createContract,defaultPageSize,
        invoiceList,getInvoiceList,
        invoiceDetail,getInvoiceDetail,
        createInvoice,getCurCustomerList,curCustomerList,confirmInvoiceStatus} = useContext(ProjectFinanceContext)
    const { projectDetail } = useContext(ProjectDetailContext)
    const [userInfoDetail,setUserInfoDetail]  = useState({})
    const {queryPositionByOfficeId,riskUser} = useContext(ProjectStampContext)

    const [invoicePage,setInvoicePage] = useState(0)
    const [customerModel,setCustomerModel] = useState({})


    useDidMount(()=>{
        getContractList({
            id: projectDetail.id,
            pageNo: 0,
            size: defaultPageSize
        })
        getInvoiceList({
            id: projectDetail.id,
            pageNo: 0,
            size: defaultPageSize
        })
        let userInfoDetail = localStorage.getItem("userInfoDetail")
        setUserInfoDetail(eval("(" + userInfoDetail + ")"))
    })

    function contractOnPageChange(page, size) {
        getContractList({
            id: projectDetail.id,
            pageNo: page,
            size: size
        })
    }

    const  contractPaginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: contractList?.totalNum,
        onChange:(page,size)=>contractOnPageChange(page-1,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    function invoiceOnPageChange(page, size) {
        getInvoiceList({
            id: projectDetail.id,
            pageNo: page,
            size: size
        })
        setInvoicePage(page)
    }

    const  invoicePaginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: invoiceList?.totalNum,
        onChange:(page,size)=>invoiceOnPageChange(page-1,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    function onClickContractDetail(value){
        getContractDetail({id:value}).then(()=>{
            setContractDetailModel(true)
        })

    }

    function onClickInvoiceDetail(value){
        getInvoiceDetail({id:value}).then(()=>{
            setIsInvoiceDetail(true)
        })

    }

    function confirmFun(id){
        confirmInvoiceStatus({id:id}).then(()=>{
            getInvoiceList({
                id: projectDetail.id,
                pageNo: invoicePage,
                size: defaultPageSize
            })
        })
    }

    const tabColumns = [
        {
            title: '合同名称',
            dataIndex: 'contractName',
            key: 'contractName',
            render: (dom,record) => (
                <a onClick={()=>onClickContractDetail(record.id)}>
                    {dom}
                </a>
            ),
        },
        {
            title: '合同金额（元）',
            dataIndex: 'contractAmount',
            key: 'contractAmount',
        },
        {
            title: '状态',
            key: 'auditState',
            dataIndex: 'auditState',
            render: (dom) => (
                <div>
                    {contractStatusTypeList[dom]}
                </div>
            ),
        },
        {
            title: '创建日期',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType:'dateTime',
        },
    ];

    const invoiceTabColumns = [
        {
            title: '发票号码',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            render: (dom,record) => (
                <a onClick={()=>onClickInvoiceDetail(record.id)}>
                    {dom}
                </a>
            ),
        },
        {
            title: '发票类型',
            dataIndex: 'invoiceType',
            key: 'invoiceType',
            render: (dom) => (
                <div>
                    {invoiceTypeList[dom]}
                </div>
            ),
        },
        {
            title: '发票金额（含税）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
        },
        {
            title: '申请人',
            key: 'applyName',
            dataIndex: 'applyName',
        },
        {
            title: '状态',
            dataIndex: 'auditState',
            key: 'auditState',
            render: (dom) => (
                <div>
                    {invoiceStatusTypeList[dom]}
                </div>
            ),
        },
        {
            title: '开票时间',
            dataIndex: 'issueDate',
            key: 'issueDate',
        },
        {
            title: '领取状态',
            dataIndex: 'receiveStatus',
            key: 'receiveStatus',
            render: (dom) => (
                <div>
                    {dom==0?"未领取":"已领取"}
                </div>
            ),
        },
        {
            title: '申请人确认',
            dataIndex: 'confirmStatus',
            key: 'confirmStatus',
            render: (dom,recond) => (
                <div>
                    {recond.receiveStatus==0?"":(dom==0?<button className='confirmBtn' onClick={()=>confirmFun(recond.id)}>确认领取</button>:'已确认')}
                </div>
            ),
        },
    ];

    return(
        <div className='finance-box'>
            <div className='finance-box-title'>
                <Row justify='start'>
                    <Col span={1} className='title-name'>合同</Col>
                    <Col span={4} className='title-left'></Col>
                    <Col span={15}></Col>
                    <Col span={4} className='title-right'><a onClick={()=>{setIsContractModal(true);queryPositionByOfficeId({name:"r_administrative"})}}><PlusOutlined />  新增合同</a></Col>
                </Row>
            </div>
            <Divider type="horizontal" orientation="center" className='divider-title' />
            <div className='pro-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={contractList.list}
                    rowKey="key"
                    pagination={contractPaginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <div className='finance-box-title'>
                <Row justify='start'>
                    <Col span={1} className='title-name'>发票</Col>
                    <Col span={4} className='title-left'></Col>
                    <Col span={15}></Col>
                    <Col span={4} className='title-right'><a onClick={()=>{setIsInvoiceModal(true);queryPositionByOfficeId({name:"r_finance"});getCurCustomerList({id:projectDetail.id})}}><PlusOutlined />  新增发票</a></Col>
                </Row>
            </div>
            <Divider type="horizontal" orientation="center" className='divider-title'/>
            <div className='pro-box'>
                <ProTable
                    columns={invoiceTabColumns}
                    dataSource={invoiceList.list}
                    rowKey="key"
                    pagination={invoicePaginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <ContractModal isContractModal={isContractModal} setIsContractModal={setIsContractModal} projectDetail={projectDetail}
                           userInfoDetail={userInfoDetail} riskUser={riskUser} createContract={createContract} getContractList={getContractList}/>
            <InvoiceModal isInvoiceModal={isInvoiceModal} setIsInvoiceModal={setIsInvoiceModal} projectDetail={projectDetail} curCustomerList={curCustomerList}
                          userInfoDetail={userInfoDetail} riskUser={riskUser} createInvoice={createInvoice} getInvoiceList={getInvoiceList}
                          setCustomerModel={setCustomerModel} customerModel={customerModel}/>

            <ContractDetailModal setContractDetailModel={setContractDetailModel} contractDetailModel={contractDetailModel} contractDetail={contractDetail}/>

            <InvoiceDetailModal isInvoiceDetail={isInvoiceDetail} setIsInvoiceDetail={setIsInvoiceDetail} invoiceDetail={invoiceDetail}/>
        </div>
    )

}

const layout = {
    wrapperCol: { span: 20 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


//新建合同弹窗
export function ContractModal(props){

    const { TextArea } = Input;

    const {isContractModal,setIsContractModal,projectDetail,userInfoDetail,riskUser,createContract,getContractList} = props
    const sealTypeList  = ["公章","合同专用章","见证章","财务章","无需盖章"];
    const [ contractTypeText, setContractTypeText] = useState("公章");
    const [ contractTypeValue, setContractTypeValue] = useState(0);
    const [ contractEg, setContractEg] = useState("");//备注
    const [contractFileList,setContractFileList] = useState([])
    const [contractForm] = Form.useForm();

    const handleOk = () => {
        setIsContractModal(false);
    };

    const handleCancel = () => {
        setIsContractModal(false);
    };

    const onChange = e => {
        console.log('Change:', e.target.value);
        setContractEg(e.target.value)
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        console.log("contractFileList",contractFileList)
        if(contractFileList.length==0){
            message.error('附件不能为空')
            return
        }
        createContract({
            annex: contractFileList,
            contractAmount: e.contractAmount,
            contractName: e.contractName,
            // contractNo: e.contractNo,
            // expiresTime: e.expiresTime,
            mark: contractEg,
            projectId: projectDetail.id,
            signedTime: e.signedTime,
            stampNum: e.stampNum==undefined?1:e.stampNum,
            stampType: contractTypeValue,
            targetAmount: e.targetAmount
        }).then(()=>{
            getContractList({
                id: projectDetail.id,
                pageNo: 0,
                size: 5
            })
            setIsContractModal(false)
            setContractFileList([])
            contractForm.resetFields()
        })
    }

    const onFinishFailed= (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const contractMenuOnClick = ({ key }) => {
        setContractTypeText(sealTypeList[key])
        setContractTypeValue(key)
    };

    const contractTypeMenu = (
        <Menu onClick={contractMenuOnClick}>
            <Menu.Item key="0">{sealTypeList[0]}</Menu.Item>
            <Menu.Item key="1">{sealTypeList[1]}</Menu.Item>
            <Menu.Item key="2">{sealTypeList[2]}</Menu.Item>
            <Menu.Item key="3">{sealTypeList[3]}</Menu.Item>
            <Menu.Item key="4">{sealTypeList[4]}</Menu.Item>
        </Menu>
    );

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isContractModal}
                   onOk={handleOk} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>新增合同</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            form={contractForm}
                            initialValues={{
                                projectName:projectDetail.name,
                                projectNum:projectDetail.projectNum,
                                customer:projectDetail?.customerList?.length??0>0?projectDetail?.customerList[0]?.name:'',
                                sealUser:userInfoDetail.realName,
                                stampNum:1
                            }}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <div className='model-title'>合同信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属项目</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>委托方</div>
                                    <Form.Item
                                        name="customer"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>合同名称</div>
                                    <Form.Item
                                        name="contractName"
                                        rules={[{ required: true ,message:'合同名称为必填字段'}]}
                                    >
                                        <Input placeholder="请填写合同名称"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*<Row className='model-row'>*/}
                            {/*    <Col span={12}>*/}
                            {/*        <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>合同编号</div>*/}
                            {/*        <Form.Item*/}
                            {/*            name="contractNo"*/}
                            {/*            rules={[{ required: true ,message:'合同编号为必填字段'}]}*/}
                            {/*        >*/}
                            {/*            <Input placeholder="请填写合同编号"/>*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>合同签订日</div>
                                    <Form.Item
                                        name="signedTime"
                                        rules={[{ required: true ,message:'合同签订日为必填字段'}]}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="合同签订日"/>
                                    </Form.Item>
                                </Col>
                                {/*<Col span={12}>*/}
                                {/*    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>合同期满日</div>*/}
                                {/*    <Form.Item*/}
                                {/*        name="expiresTime"*/}
                                {/*        rules={[{ required: true ,message:'合同期满日为必填字段'}]}*/}
                                {/*    >*/}
                                {/*        <DatePicker style={{ width: '100%' }} placeholder="合同期满日"/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>
                            <div className='model-title'> 收费信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={24}>
                                    <Form.Item
                                        name="targetAmount"
                                    >
                                        <Input placeholder="风险代理约定"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <Form.Item
                                        name="contractAmount"
                                    >
                                        <Input placeholder="合同金额（元）"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>合同盖章信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row' justify="space-between">
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章申请人</div>
                                    <Form.Item
                                        name="sealUser"
                                    >
                                       <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>盖章类型</div>
                                    <Form.Item
                                        name="stampType"
                                    >
                                        <Dropdown overlay={contractTypeMenu} placement="bottomCenter" arrow>
                                            <Button>{contractTypeText} <CaretDownOutlined /></Button>
                                        </Dropdown>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>盖章份数</div>
                                    <Form.Item
                                        name="stampNum"
                                    >
                                        <InputNumber min={1} max={1000} keyboard='true'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <UploadListBox title={"上传附件"} ownerType={1} setOtherList={setContractFileList} otherList={contractFileList}
                                           projectId={projectDetail.id} isUpdate={false}/>
                            <div style={{color:'#ff4d4f',fontSize:'14px', marginTop: '5px', marginRight:'4px'}}>请上传doc, docx, pdf类型</div>
                            <div className='model-audit'>
                                <span>审核人:</span><span>{riskUser.name}</span>
                            </div>
                            <div className='model-title'>备注</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <TextArea rows={4} className='model-textArea' onChange={onChange}/>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

//新建发票弹窗
function InvoiceModal(props){

    const { TextArea } = Input;

    const {isInvoiceModal,setIsInvoiceModal,projectDetail,userInfoDetail,riskUser,createInvoice,getInvoiceList,curCustomerList,setCustomerModel,customerModel} = props

    const [ invoiceEg, setInvoiceEg] = useState("");//备注

    const [invoiceType, setInvoiceType] = useState(0);
    const [invoiceFileList,setInvoiceFileList] = useState([])

    const [form] = Form.useForm();

    const egOnChange = e => {
        setInvoiceEg(e.target.value)
    };

    const handleOk = () => {
        setIsInvoiceModal(false);
        setCustomerModel({})
        form.setFieldsValue({customerId:null})
        form.setFieldsValue({phone:''})
        form.setFieldsValue({identity:''})
        form.setFieldsValue({bankName:''})
        form.setFieldsValue({bankCardNumber:''})
        form.setFieldsValue({address:''})
        form.setFieldsValue({taxAmount:''})
        form.setFieldsValue({issueDate:''})
    };

    const handleCancel = () => {
        setIsInvoiceModal(false);
        setCustomerModel({})
        form.setFieldsValue({customerId:null})
        form.setFieldsValue({phone:''})
        form.setFieldsValue({identity:''})
        form.setFieldsValue({bankName:''})
        form.setFieldsValue({bankCardNumber:''})
        form.setFieldsValue({address:''})
        form.setFieldsValue({taxAmount:''})
        form.setFieldsValue({issueDate:''})
    };

    function onFormFinish(e) {
        if(invoiceFileList.length==0){
            message.error('附件为必传字段')
            return
        }
        createInvoice({
            address: e.address,
            annex: invoiceFileList,
            invoiceType: invoiceType,
            issueDate: e.issueDate,
            mark: invoiceEg,
            phone: e.phone,
            // postCode: e.postCode,
            projectId: projectDetail.id,
            taxAmount: e.taxAmount,
            title: e.title,
            customerId:e.customerId,
            bankCardNumber: e.bankCardNumber,
            bankName:e.bankName,
            taxpayerID: e.identity
        }).then(()=>{
            getInvoiceList({
                id: projectDetail.id,
                pageNo: 0,
                size: 5
            })
            setIsInvoiceModal(false)
            setCustomerModel({})
            setInvoiceFileList([])
            form.resetFields()
        })
    }

    const onFinishFailed= (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onInvoiceTypeChange = e => {
        setInvoiceType(e.target.value);
    };

    function selectOnChange(value){
        curCustomerList.map((item)=>{
            if(item.customerId==value){
                setCustomerModel(item)
                form.setFieldsValue({phone:item.phone})
                form.setFieldsValue({identity:item.identity})
                form.setFieldsValue({bankName:item.bankName})
                form.setFieldsValue({bankCardNumber:item.bankCardNumber})
                form.setFieldsValue({address:item.address})
            }
        })
    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isInvoiceModal}
                   onOk={handleOk} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>新增发票</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            form={form}
                            initialValues={{
                                projectName:projectDetail.name,
                                projectNum:projectDetail.projectNum,
                                sealUser:userInfoDetail.realName,
                                belongs:projectDetail.groupName,
                            }}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <div className='model-title'>基本信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属项目</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属</div>
                                    <Form.Item
                                        name="belongs"
                                    >
                                      <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>申请人</div>
                                    <Form.Item
                                        name="sealUser"
                                    >
                                       <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>发票信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={24} className='flex-col'>
                                    <div className='project-input-title' style={{marginBottom:'24px'}}><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>发票类型</div>
                                    <Form.Item
                                        name="invoiceType"
                                    >
                                        <Radio.Group onChange={onInvoiceTypeChange} value={invoiceType} defaultValue={0}>
                                            <Radio value={0}>增值税普通发票</Radio>
                                            <Radio value={1}>增值税专用发票</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>发票抬头</div>
                                    <Form.Item
                                        name="customerId"
                                        rules={[{ required: true ,message:'发票抬头为必选字段'}]}
                                    >
                                        <Select
                                            className = 'select-item'
                                            placeholder="客户名称"
                                            bordered={false}
                                            onChange={selectOnChange}
                                            dropdownRender={menu => (
                                                <div className='select-dropdown'>
                                                    {menu}
                                                </div>
                                            )}
                                        >
                                            {curCustomerList.map(item => (
                                                <Option key={item.customerId} value={item.customerId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>含税金额</div>
                                    <Form.Item
                                        name="taxAmount"
                                        rules={[{ required: true ,message:'含税金额为必填字段'}]}
                                    >
                                        <Input placeholder="发票含税金额"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>开具日期</div>
                                    <Form.Item
                                        name="issueDate"
                                        rules={[{ required: true ,message:'开具日期为必选字段'}]}
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="请选择发票开具日期"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                            <Col span={24}>
                                <div className='project-input-title'>{invoiceType==0?'':<span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>}纳税人识别号/身份证号</div>
                                <Form.Item
                                    name="identity"
                                    rules={[{ required: invoiceType==1 ,message:'纳税人识别号/身份证号为必填字段'}]}
                                >
                                    <Input placeholder='纳税人识别号/身份证号'/>
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>{invoiceType==0?'':<span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>}开户行</div>
                                    <Form.Item
                                        name="bankName"
                                        rules={[{ required: invoiceType==1 ,message:'开户行为必填字段'}]}
                                    >
                                        <Input placeholder='开户行'/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>{invoiceType==0?'':<span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>}银行卡号</div>
                                    <Form.Item
                                        name="bankCardNumber"
                                        rules={[{ required: invoiceType==1 ,message:'银行卡号为必填字段'}]}
                                    >
                                        <Input placeholder='银行卡号'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>{invoiceType==0?'':<span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>}电话</div>
                                    <Form.Item
                                        name="phone"
                                        rules={[{ required: invoiceType==1 ,message:'电话为必填字段'}]}
                                    >
                                        <Input placeholder='电话'/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>{invoiceType==0?'':<span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>}地址</div>
                                    <Form.Item
                                        name="address"
                                        rules={[{ required: invoiceType==1 ,message:'地址为必填字段'}]}
                                    >
                                        <Input placeholder='地址'/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className='model-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>附件(上传已盖章PDF格式的合同文件)</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <UploadListBox title={"上传附件"} ownerType={1} setOtherList={setInvoiceFileList} otherList={invoiceFileList}
                                           projectId={projectDetail.id} isUpdate={false}/>
                            <div className='model-audit'>
                                <span>审核人:</span><span>{riskUser.name}</span>
                            </div>
                            <div className='model-title'>备注</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <TextArea rows={4} className='model-textArea' onChange={egOnChange}/>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

//合同详情弹窗
export function ContractDetailModal(props){

    const { TextArea } = Input;
    const { downloadFile } = useContext(ProjectFileContext)
    const {contractDetailModel,setContractDetailModel,contractDetail} = props
    const sealTypeList  = ["公章","合同专用章","见证章","财务章","无需盖章"];

    const handleOk = () => {
        setContractDetailModel(false);
    };

    const handleCancel = () => {
        setContractDetailModel(false);
    };

    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    function copyValue(value){
        copy(value)
        message.success("复制成功").then()
    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={contractDetailModel}
                   onOk={handleOk} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>合同详情</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                projectName:contractDetail.projectName,
                                projectNum:contractDetail.projectNumber,
                                customer:(contractDetail?.principal?.length??0)>0?contractDetail?.principal[0]:'',
                                contractName:contractDetail.contractName,
                                signedTime:moment(contractDetail.signedTime),
                                targetAmount:contractDetail.targetAmount,
                                contractAmount:contractDetail.contractAmount,
                                sealUser:contractDetail.applyName,
                                stampType:sealTypeList[contractDetail.stampType],
                                stampNum:contractDetail.stampNum,
                                mark:contractDetail.mark
                            }}
                        >
                            <div className='model-title'>合同信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属项目</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.projectName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.projectNumber)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>委托方</div>
                                    <Form.Item
                                        name="customer"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue((contractDetail?.principal?.length??0)>0?contractDetail?.principal[0]:'')}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>合同名称</div>
                                    <Form.Item
                                        name="contractName"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.contractName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>合同签订日</div>
                                    <Form.Item
                                        name="signedTime"
                                    >
                                        <DatePicker style={{ width: '100%' }} placeholder="合同签订日" disabled/>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <div className='model-title'>收费信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <div className='project-input-title'>风险代理约定</div>
                                <Col span={24}>
                                    <Form.Item
                                        name="targetAmount"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.targetAmount)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>合同金额（元）</div>
                                    <Form.Item
                                        name="contractAmount"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.contractAmount)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>合同盖章信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row' justify="space-between">
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章申请人</div>
                                    <Form.Item
                                        name="sealUser"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.applyName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章类型</div>
                                    <Form.Item
                                        name="stampType"
                                    >
                                       <Input disabled suffix={
                                           <Tooltip title="复制">
                                               <CopyOutlined onClick={()=>copyValue(sealTypeList[contractDetail.stampType])}/>
                                           </Tooltip>
                                       }/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章份数</div>
                                    <Form.Item
                                        name="stampNum"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(contractDetail.stampNum)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>合同附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                contractDetail?.annex?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }

                            <div className='model-audit'>
                                <span>审核人:</span><span>{contractDetail.auditName}</span>
                            </div>
                            <div className='model-title'>备注 <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(contractDetail.mark)}/></div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Form.Item
                                name="mark"
                            >
                                <TextArea rows={4} className='model-textArea' disabled={true}/>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

//发票详情弹窗
export function InvoiceDetailModal(props){

    const { TextArea } = Input;
    const { downloadFile } = useContext(ProjectFileContext)
    const {isInvoiceDetail,setIsInvoiceDetail,invoiceDetail} = props
    const taxTypeList  = ["境内6%","境外16%"];

    const handleOk = () => {
        setIsInvoiceDetail(false);
    };

    const handleCancel = () => {
        setIsInvoiceDetail(false);
    };

    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    function copyValue(value){
        copy(value)
        message.success("复制成功").then()
    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isInvoiceDetail}
                   onOk={handleOk} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>发票详情</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                projectName:invoiceDetail.projectName,
                                projectNum:invoiceDetail.projectNumber,
                                belongs:invoiceDetail.groupName,
                                sealUser:invoiceDetail.applyName,
                                invoiceType:invoiceDetail.invoiceType==0?"增值税普通发票":"增值税专用发票",
                                receiveStatus:invoiceDetail.receiveStatus==0?"未领取":"已领取",
                                confirmStatus:invoiceDetail.confirmStatus==0?"未确认领取":"已确认领取",
                                title:invoiceDetail.title,
                                taxAmount:invoiceDetail.taxAmount,
                                taxpayerID:invoiceDetail.taxpayerID,
                                // taxType:taxTypeList[invoiceDetail.taxType],
                                issueDate:moment(invoiceDetail.issueDate),
                                invoiceNumber:invoiceDetail.invoiceNumber,
                                bank:invoiceDetail.bank,
                                bankNumber:invoiceDetail.bankNumber,
                                phone:invoiceDetail.phone,
                                address:invoiceDetail.address,
                                // postCode:invoiceDetail.postCode,
                                mark:invoiceDetail.mark,
                                receivable:invoiceDetail.receivable
                            }}
                        >

                            <div className='model-title'>基本信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属项目</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.projectName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.projectNumber)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>所属</div>
                                    <Form.Item
                                        name="belongs"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.groupName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>申请人</div>
                                    <Form.Item
                                        name="sealUser"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.applyName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>发票信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={24} className='flex-col'>
                                    <div className='project-input-title' style={{marginBottom:'24px'}}>发票类型</div>
                                    <Form.Item
                                        name="invoiceType"
                                    >
                                       <Input disabled suffix={
                                           <Tooltip title="复制">
                                               <CopyOutlined onClick={()=>copyValue(invoiceDetail.invoiceType==0?"增值税普通发票":"增值税专用发票")}/>
                                           </Tooltip>
                                       }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24} className='flex-col'>
                                    <div className='project-input-title' style={{marginBottom:'24px'}}>是否领取</div>
                                    <Form.Item
                                        name="receiveStatus"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.receiveStatus==0?"未领取":"已领取")}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24} className='flex-col'>
                                    <div className='project-input-title' style={{marginBottom:'24px'}}>是否确认领取</div>
                                    <Form.Item
                                        name="confirmStatus"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.confirmStatus==0?"未确认领取":"已确认领取")}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>发票抬头</div>
                                    <Form.Item
                                        name="title"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.title)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>发票含税金额</div>
                                    <Form.Item
                                        name="taxAmount"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.taxAmount)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>纳税人识别号/身份证号</div>
                                    <Form.Item
                                        name="taxpayerID"
                                    >
                                        <Input disabled placeholder="纳税人识别号" suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.taxpayerID)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                {/*<Col span={12}>*/}
                                {/*    <div className='project-input-title'>税金类型</div>*/}
                                {/*    <Form.Item*/}
                                {/*        name="taxType"*/}
                                {/*    >*/}
                                {/*        <Input disabled suffix={*/}
                                {/*            <Tooltip title="复制">*/}
                                {/*                <CopyOutlined onClick={()=>copyValue(taxTypeList[invoiceDetail.taxType])}/>*/}
                                {/*            </Tooltip>*/}
                                {/*        }/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                <Col span={12}>
                                    <div className='project-input-title'>发票开具日期</div>
                                    <Form.Item
                                        name="issueDate"
                                    >
                                        <DatePicker style={{ width: '100%' }} disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>发票编号</div>
                                    <Form.Item
                                        name="invoiceNumber"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.invoiceNumber)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>开户行</div>
                                    <Form.Item
                                        name="bank"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.bank)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>银行卡号</div>
                                    <Form.Item
                                        name="bankNumber"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.bankNumber)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>电话</div>
                                    <Form.Item
                                        name="phone"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.phone)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>地址</div>
                                    <Form.Item
                                        name="address"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.address)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>回款</div>
                                    <Form.Item
                                        name="receivable"
                                    >
                                        <Input disabled suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(invoiceDetail.receivable)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                {/*<Col span={12}>*/}
                                {/*    <div className='project-input-title'>邮编</div>*/}
                                {/*    <Form.Item*/}
                                {/*        name="postCode"*/}
                                {/*    >*/}
                                {/*        <Input disabled suffix={*/}
                                {/*            <Tooltip title="复制">*/}
                                {/*                <CopyOutlined onClick={()=>copyValue(invoiceDetail.postCode)}/>*/}
                                {/*            </Tooltip>*/}
                                {/*        }/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>
                            <Row className='model-row'>

                            </Row>

                            <div className='model-title'>附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                invoiceDetail?.annex?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                            <div className='model-audit'>
                                <span>审核人:</span><span>{invoiceDetail?.auditName}</span>
                            </div>
                            <div className='model-title'>备注 <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(invoiceDetail.mark)}/></div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Form.Item
                                name="mark"
                            >
                                <TextArea rows={4} className='model-textArea' disabled={true}/>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
