import React, {useContext, useState} from "react";
import {
    apiCreateCustomer,
    apiCreateTask,
    apiCustomerData,
    apiCustomerDetail,
    apiCustomerList,
    apiCustomerUpdate, apiGetProjectList, apiListByProjectId,
    apiOfficeCreate, apiQueryMembersByProject,
    apiQueryProjectBySelf,
    apiQueryTask,
    apiTaskList,
    apiTaskStartTimer,
    apiTaskStopTimer,
    apiUpdateTask
} from "../api/apis";
import {message} from "antd";
import dayjs from "dayjs";

export const TaskContext = React.createContext();

/**
 * 任务
 * @param props
 * @returns {*}
 * @constructor
 */

export const TaskProvider = (props) => {
    const [isCreateVisible, setIsCreateVisible] = useState(false);
    const [isUpdateVisible, setIsUpdateVisible] = useState(false);
    const [taList, setTaList] = useState({});
    const [taDetail,setTaDetail] = useState({});
    const [timingOf,setTimingOf] = useState({});//计时中的那条记录
    const [members,setMembers] = useState([]);
    const [projectTaskList,setProjectTaskList] = useState([]);
    const defaultPageSize = 10;

    async function taskCreate(params){
       await apiCreateTask(params)
       message.success('创建成功',1)
    }

    const taskList = (params) =>{
        apiTaskList(params).then((res)=>{
            setTaList(res)
        })
    }

    async function taskDetail (params){
         let res = await apiQueryTask(params)
         setTaDetail(res);
    }

    //开始计时
    const taskStartTimer = (params) =>{
        apiTaskStartTimer(params).then((res)=>{
            setTimingOf(res)
            message.success('开始计时',1).then(()=>{
            })
            taskDetail({
                id: params.ownerId
            })
        })
    }

    //结束计时
    const taskStopTimer = (params) =>{
        apiTaskStopTimer(params).then((res)=>{
            message.success('计时结束',1).then(()=>{
            })
            taskDetail({
                id: taDetail.id
            })
        })
    }

    //


    async function taskUpdate(params){
        await apiUpdateTask(params).then((res)=>{
            if(params.state==2){
                message.success('删除成功',1).then(()=>{
                })
            }else{
                message.success('修改成功',1).then(()=>{
                })
            }
            if(params.state !==2) {
                taskDetail({
                    id: taDetail.id
                })
            }
        })
    }

    async function taskUpdateProjectDetail(params){
        await apiUpdateTask(params).then((res)=>{
            message.success('删除成功',1).then(()=>{
            })
        })
    }

    const queryMembersByProject = (params) =>{
        apiQueryMembersByProject(params).then((res)=> {
                setMembers(res)
            }
        )
    }

    async function listByProjectId(params) {
        return  new Promise((resolve) => {
            apiListByProjectId(params).then((res)=>{
                setProjectTaskList(res)
                resolve(res)
            })
        })
    }


    return (
        <TaskContext.Provider
            value={{
                isCreateVisible,
                setIsCreateVisible,
                isUpdateVisible,
                setIsUpdateVisible,
                taDetail,
                taList,
                timingOf,
                setTaDetail,
                taskList,
                taskCreate,
                taskUpdate,
                taskDetail,
                taskStartTimer,
                taskStopTimer,
                members,
                queryMembersByProject,
                listByProjectId,
                projectTaskList,
                defaultPageSize,
                taskUpdateProjectDetail
            }}>
            {props.children}
        </TaskContext.Provider>
    )
}
