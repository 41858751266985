import axios from 'axios';
import CustomConfig from '../env/env';
import {AppStore}from "../store/RootStore"
import {message} from "antd";

axios.defaults.timeout = 15000;

const isAbsolutePath = url => /^(http|https|http:|https:|\/\/)/.test(url);

const BASE_URL = CustomConfig.BASE_URL;

function fixConfigs(config) {
  const fixConfig = {...config};
  if (config.baseURL || isAbsolutePath(config.url)) {
    return fixConfig;
  }

  fixConfig.headers = {
    ...fixConfig.headers,
    authorization: localStorage.getItem("token"),
  };


  return {
    ...fixConfig,
    baseURL: BASE_URL,
  };
}

function setRequestConfig(config) {
  return Promise.resolve(fixConfigs(config));
}

const handleError = (err) => {
  if (err.response && err.response.status === 400){
    message.error(err.response.data.message)
    // const userStore = AppStore.userStore;
    // userStore.logout()
  }
  if (err.response && (err.response.status === 401 || err.response.status === 402 )) {
    //返回login界面
    const userStore = AppStore.userStore;
    userStore.logout()
    // localStorage.setItem("login", "0");
    // localStorage.setItem("token", null);
    // localStorage.setItem("userInfo", null);
  }

  // if(err.toString() === "Error: Network Error"){
  //     message.error('网络错误')
  //     const userStore = AppStore.userStore;
  //     userStore.clearToken()
  // }

  return Promise.reject(err);
};

export const fileUploadUrl=`${BASE_URL}/api/file/upload_file`;

axios.defaults.crossDomain = true

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,POST,PUT,POST";
axios.defaults.headers.common['Access-Control-Allow-Headers'] = "x-requested-with,content-type";
axios.interceptors.request.use(config => setRequestConfig(config), error =>  handleError(error));

axios.interceptors.response.use(response => response.data, error => handleError(error));

export default axios;

