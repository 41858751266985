import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {CaretDownFilled, EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import {Avatar, Button, Divider, Dropdown, Form, Image, Menu, Modal, Radio, Space, Tabs} from "antd";
import {ROLE_ADMIN} from "../../constants/names";
import {DepartmentContext} from "../../provider/department_provider";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {useDidMount} from "../../common/state";
import ProTable from "@ant-design/pro-table";
import {TeamProjectListPage} from "../department/department";
import "./humanResource.less";
import {LawFirmActiveImg} from "../../constants/images";
import Input from "antd/es/input";

const defaultPageSize = 10;

const { TabPane } = Tabs;

export const HumanResource=forwardRef((props, ref) =>  {

    const {isModalVisible,setIsModalVisible,getTeamProjectList,
        getOfficeUserList} = useContext(DepartmentContext)

    const [pageSize, setPageSize] = useState(10);

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getOfficeUser,
    }));

    const getOfficeUser = ()=>{
        getOfficeUserList({
            pageNo: 0,
            size:pageSize
        });
        getTeamProjectList({
            pageNo: 0,
            size:pageSize
        })
    }



    return(
        <div className='card-box human-resource-page'>
            <div className='institution-firm-title'>
                <div className='institution-firm-title-left'>
                    <Image src={LawFirmActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>人资管理</span>
                </div>
                <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={()=>{setIsModalVisible(true)}}>添加用户</Button>
            </div>
            <div className='content-body'>
                <Tabs defaultActiveKey="1" tabBarGutter={40} >
                    <TabPane tab="成员" key="1">
                       <OfficeUserListPage setPageSize={setPageSize}/>
                    </TabPane>
                    <TabPane tab="项目" key="2">
                        <TeamProjectListPage/>
                    </TabPane>
                </Tabs>
            </div>
            <AddUserModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}/>

        </div>

    )
})

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};

//添加用户弹窗
function AddUserModal(props){

    const {isModalVisible,setIsModalVisible} = props

    const {lawAddUser} = useContext(DepartmentContext)

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        lawAddUser({
            phone:e.phone,
            realName:e.realName
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isModalVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>新增用户</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="手机号"
                                name="phone"
                                rules={[{ required: true, message: '请输入用户手机号码' }]}
                            >
                                <Input placeholder="请输入用户手机号码" className='content-model-body-form-input'/>
                            </Form.Item>
                            <Form.Item
                                label="姓名"
                                name="realName"
                                rules={[{ required: true, message: '请输入用户姓名' }]}
                            >
                                <Input placeholder="请输入用户姓名" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//律所成员列表
function OfficeUserListPage(props){
    const {setPageSize} = props

    const [userPageNo,setUserPageNo] =  useState(0)

    const {
        officeUserList,getOfficeUserList,gradesList,setUserGrade,positionList,
        getAllPositionList,expelUserByAdmin,restartLawyer,deleteLawyerRecord,
        setUserPosition
    } = useContext(DepartmentContext)

    const {userInfo}  = useContext(UserInfoContext)

    const [column,setColumn] = useState([])

    useEffect(()=>{
        let list = []
        list.push(...beforeColumn)
        positionList.map((item)=>{
            list.push(
                {
                    title:item.positionName,
                    dataIndex: item.position,
                    hideInSearch:true,
                    render: (dom, record) => (
                        <Radio disabled={false} checked={Array.from(record.lawPosition, ({position}) => position).indexOf(item.position) !== -1}
                               onClick={()=>{if(Array.from(record.lawPosition, ({position}) => position).indexOf(item.position) === -1&&record.onJob === 1) setUserPositionFun(record.id,item.position)}}/>
                    ),
                }
            )
        })
        list.push(...afterColumn)
        setColumn(list)
    },[positionList,userPageNo])


    //调整用户等级
    const setUserGradeFun = (grade,userId) => {
        if(userInfo !== null && userInfo?.role !== null){
            if(userInfo?.role === ROLE_ADMIN){
                if(userId === undefined) return
                setUserGrade(userId,grade,userPageNo)
            }
        }
    }

    //设置用户职位
    const setUserPositionFun = (userId,position) => {
        if(userInfo !== null && userInfo?.role !== null){
            if(userInfo?.role === ROLE_ADMIN){
                if(userId === undefined) return
                setUserPosition(userId,position,userPageNo)
            }
        }
    }

    //停职用户
    const expelUserByAdminFun = (userId) => {
        if(userInfo !== null && userInfo?.role !== null){
            if(userInfo?.role === ROLE_ADMIN){
                if(userId === undefined) return
                expelUserByAdmin(userId,userPageNo)
            }
        }
    }

    //重启用户
    const restartLawyerFun = (userId) => {
        if(userInfo !== null && userInfo?.role !== null){
            if(userInfo?.role === ROLE_ADMIN){
                if(userId === undefined) return
                restartLawyer(userId,userPageNo)
            }
        }
    }

    //删除用户
    const deleteLawyerRecordFun = (userId) => {
        if(userInfo !== null && userInfo?.role !== null){
            if(userInfo?.role === ROLE_ADMIN){
                if(userId === undefined) return
                deleteLawyerRecord(userId,userPageNo)
            }
        }
    }

    useDidMount(()=>{
        if(userInfo?.role === ROLE_ADMIN){
            getAllPositionList()
        }
    })

    const beforeColumn = [
        {
            title:'成员名',
            width:200,
            dataIndex:'realName',
            render: (dom, record) => (
                <Space>
                    <Avatar size={25} src={record.headImg}/>
                    {record.realName}
                </Space>
            ),
        },{
            title:'手机号',
            width:200,
            dataIndex:'username',
            hideInSearch:true,
            render: (dom, record) => (
                <Space>
                    {record.username}
                </Space>
            ),
        },{
            title:'所属律所',
            width:200,
            dataIndex:'officeName',
            hideInSearch:true,
            render: (dom, record) => (
                <Space>
                    {record.officeName}
                </Space>
            ),
        },{
            title: '级别',
            dataIndex: 'lawGradeVO',
            hideInSearch:true,
            render: (dom, record) => (
                <Dropdown overlay={(
                    <Menu>
                        {gradesList.map(item => (
                            <Menu.Item key={item.grade} onClick={()=>{setUserGradeFun(item.grade,record.id)}}>{item.gradeName}</Menu.Item>
                        ))}
                    </Menu>
                )} trigger={['click']}>
                    <span>
                        {record.lawGradeVO?.gradeName??'实习律师'} <CaretDownFilled />
                    </span>
                </Dropdown>
            ),
        },
    ]

    const afterColumn = [
        {
            title:'状态',
            dataIndex: 'onJob',
            hideInSearch:true,
            render: (dom, record) => (
                <Space>
                    {dom === 1?'在职':'离职'}
                </Space>
            ),
        },
        {
            title:'操作',
            valueType: 'option',
            hideInSearch:true,
            render: (_,record) => [
                <Dropdown overlay={userInfo.role===ROLE_ADMIN?(record.onJob === 1?
                    <Menu>
                        <Menu.Item key="1" onClick={()=>{expelUserByAdminFun(record.id)}}>停职</Menu.Item>
                        <Menu.Item key="2" onClick={()=>{deleteLawyerRecordFun(record.id)}}>删除</Menu.Item>
                    </Menu>
                    :<Menu>
                        <Menu.Item key="1" onClick={()=>{restartLawyerFun(record.id)}}>启用</Menu.Item>
                        <Menu.Item key="2" onClick={()=>{deleteLawyerRecordFun(record.id)}}>删除</Menu.Item>
                    </Menu>):<div/>
                } placement="bottomCenter" >
                    <EllipsisOutlined style={{fontSize:"20px"}}/>
                </Dropdown>]
        }
    ]

    function onPageChange(size) {
        setPageSize(size)
    }

    const  officeUserPaginationProps = {
        showSizeChanger: true,
        defaultPageSize:defaultPageSize,
        onShowSizeChange:(current,size)=>onPageChange(size),
        total: officeUserList.totalNum,
        current:officeUserList?.pageNo+1,
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    return (
        <div className='project-box'>
            <ProTable
                columns={column}
                dataSource={officeUserList.list}
                pagination={officeUserPaginationProps}
                rowKey={r => (r.id)}
                options={false}
                search={{
                    defaultCollapsed: false,
                    labelWidth: 'auto',
                    optionRender: (searchConfig, formProps, dom) => [
                        ...dom.reverse(),
                    ],
                }}
                dateFormatter="string"
                request={async (params, sort, filter) => {
                    const res = await getOfficeUserList({
                        searchName: params.realName,
                        pageNo: params.current-1,
                        size: params.pageSize
                    });
                    setUserPageNo(params.current)
                    return Promise.resolve({
                        data: res.list,
                        success: true,
                        total:res.totalNum
                    });
                }}
            />
        </div>)


}

