import React, {useState} from "react";
import {
    apiCreateCustomer,
    apiCustomerData,
    apiCustomerDetail,
    apiCustomerList,
    apiCustomerUpdate,
    apiOfficeCreate
} from "../api/apis";
import {message} from "antd";
export const CustomerContext = React.createContext();

/**
 * 客户
 * @param props
 * @returns {*}
 * @constructor
 */
export const CustomerProvider = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cuList, setCuList] = useState({});
    const [cuData, setCuData] = useState({});
    const [cuDetail,setCuDetail] = useState({});
    const [searchParams,setSearchParams] = useState({searchType:undefined , pageNo:0, size:10,searchName:''});

    const customerCreate = (params) => {
        apiCreateCustomer(params).then((res)=>{
            console.log('1111',res.data)
            message.success('创建成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新
            customerList(searchParams)
            customerData({})

        })
    }

    const customerList = async (params) => {
        let res = await apiCustomerList(params)
        setCuList(res)
        return res
    }

    const customerData = (params) =>{
        apiCustomerData(params).then((res)=>{
            setCuData(res)
        })
    }

    const customerDetail = (params) =>{
        apiCustomerDetail(params).then((res)=>{
            setCuDetail(res);
            setIsModalVisible(true);
        })
    }

    const customerUpdate = (params) =>{
        apiCustomerUpdate(params).then((res)=>{
            message.success('修改成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新
            customerList(searchParams)
            customerData({})
        })
    }

    return (
        <CustomerContext.Provider
            value={{
                isModalVisible,
                setIsModalVisible,
                customerCreate,
                customerList,
                customerData,
                customerDetail,
                customerUpdate,
                cuList,
                cuData,
                cuDetail,
                setCuDetail,
                searchParams,
                setSearchParams,
            }}>
            {props.children}
        </CustomerContext.Provider>
    )
}
