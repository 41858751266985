import React, {useState} from "react";
import {apiGetUserList} from "../api/apis";

export const UserManagementContext = React.createContext();

export const UserManageProvider = (props) => {
    const [userManageList, setUserManageList] = useState({});
    const defaultPageSize = 10;

    const getUserManagementList = (params) =>{
        apiGetUserList(params).then((res)=>
            setUserManageList(res))
    }

    return (
        <UserManagementContext.Provider value={{userManageList,setUserManageList,getUserManagementList,defaultPageSize}}>
            {props.children}
        </UserManagementContext.Provider>
    )
}