import { Dropdown, Menu,Divider, Tabs, Tag} from "antd";
import {
    CloseOutlined,
} from "@ant-design/icons";
import React, {useContext, useEffect, useState} from "react";
import {ProjectDetailWraparound} from "./projectDetailWraparound";
import {ProjectDetailFinance} from "./projectDetailFinance";
import {ProjectDetailSeal} from "./projectDetailSeal";
import {ProjectDetailTask} from "./projectDetailTask";
import {ProjectDetailDocument} from "./projectDetailDocument";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {ProjectDocument} from "./ProjectDocument";
import {ProjectClient} from './ProjectClient'

const projectStatusList = ["预立案",'进行中','已完结','已搁置']

export function ProjectDetail(props) {
    const {setIndex} = props
    const { TabPane } = Tabs;

    const {projectDetail,createProjectApproval,getProjectMsg} = useContext(ProjectDetailContext)
    const {getCurOfficeUserList} = useContext(ProjectCreatContext)


    const [statusMenu,setStatusMenu] = useState(<Menu/>)

    const updateCreateProjectApproval = (secondType) =>{
        createProjectApproval({
            "secondType": secondType,
            "sourceId": projectDetail.id
        })
    }

    useEffect(()=>{
        let menu = []
        if(projectDetail.state === 0){
            menu = <Menu>
                <Menu.Item>
                    <a color="orange" onClick={()=>{updateCreateProjectApproval(1)}}>进行中</a>
                </Menu.Item>
            </Menu>
        }
        if(projectDetail.state === 1){
            menu = <Menu>
                <Menu.Item>
                    <a color="orange" onClick={()=>{updateCreateProjectApproval(2)}} >已结项</a>
                    <a color="orange" onClick={()=>{updateCreateProjectApproval(4)}}>已搁置</a>
                </Menu.Item>
            </Menu>
        }

        if(projectDetail.state === 2){
            menu = <Menu>
                <Menu.Item>
                    <a color="orange" onClick={()=>{updateCreateProjectApproval(3)}}>进行中</a>
                </Menu.Item>
            </Menu>
        }
        if(projectDetail.state === 3){
            menu = <Menu>
                <Menu.Item>
                    <a color="orange" onClick={()=>{updateCreateProjectApproval(3)}}>进行中</a>
                </Menu.Item>
            </Menu>
        }
        setStatusMenu(menu)
    },[projectDetail])

    const onChange = activeKey => {
        if(activeKey==1){
            getCurOfficeUserList()
            getProjectMsg({
                id:projectDetail.id,
                pageNo:0,
                size:10
            })
        }
    };


    return (
        <div className='project-detail card-box'>
            <div className='project-item-title'>
                <div className='project-item-title-name'>
                    {projectDetail.name??'-'}

                    <Dropdown overlay={statusMenu}>

                        <Tag color="orange">{projectStatusList[projectDetail.state]??'-'}</Tag>
                        {/*<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                        {/*    Hover me <DownOutlined />*/}
                        {/*</a>*/}
                    </Dropdown>


                </div>
                <div className='project-item-title-right'>
                    {/*<StarOutlined className='icon-style'/>*/}
                    {/*<Divider type="vertical" orientation="center" className='divider-title'/>*/}
                    {/*<EllipsisOutlined className='icon-style'/>*/}
                    {/*<Divider type="vertical" orientation="center" className='divider-title'/>*/}
                    <CloseOutlined className='icon-style' onClick={()=>{setIndex('1')}}/>
                </div>
            </div>
            <Divider type="horizontal" orientation="center" className='horizontal-divider-width'/>
            <div className='normal-tabs'>
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="概览" key="1">
                        <ProjectDetailWraparound projectId={projectDetail.id}/>
                    </TabPane>
                    <TabPane tab="财务" key="2">
                        <ProjectDetailFinance />
                    </TabPane>
                    <TabPane tab="用章" key="3">
                        <ProjectDetailSeal />
                    </TabPane>
                    <TabPane tab="任务" key="4">
                        <ProjectDetailTask />
                    </TabPane>
                    <TabPane tab="文档" key="5">
                        <ProjectDocument projectId={projectDetail.id} ownerType={1}/>
                    </TabPane>
                    <TabPane tab="客户相关" key="6">
                        <ProjectClient projectId={projectDetail.id}/>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}