import React, {useContext, useRef, useState} from 'react';
import {
    Steps,
    Row,
    Col,
    Image,
    Divider,
    Input,
    Avatar,
    Tabs,
    Menu,
    Dropdown,
    Form,
    Layout,
    Popover,
    Space,
    Button, Modal, message
} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import './basicLayout.less';
import {
    CaretDownOutlined,
    UserOutlined
} from '@ant-design/icons';
import {
    LogoImg,
    SearchImg,
    HomeImg,
    HomeActiveImg,
    MineImg,
    MineActiveImg,
    ProjectImg,
    ProjectActiveImg,
    AuditImg,
    AuditActiveImg,
    CustomerImg,
    CustomerActiveImg,
    DepartmentImg,
    DepartmentActiveImg,
    FinanceActiveImg,
    FinanceImg,
    UserActiveImg,
    UserImg,
    LawFirmActiveImg,
    LawFirmImg,
    ApprovalIcon,
    PersonSetImg,
    wxChatCode,
    linkImg,
    linkActiveImg,
    ConflictImg, ConflictActiveImg
} from "../../constants/images";
import {User} from "../user/user";
import {Workbench} from "../workbench/workbench";
import {ProjectPage} from "../project/project";


import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';
import {Department} from "../department/department";
import {Approval} from "../approval/approval";
import {NoneDate} from "../../common/load";
import {LawFirmPage} from "../lawFirm/lawFirm";
import {ProjectManagementPage} from "../projectManagement/projectManagement";
import {CustomerManagementPage} from "../customerManagement/customerManagement";
import {UserManagementPage} from "../userManagement/userManagement";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {useDidMount} from "../../common/state";
import {ROLE_ADMIN, ROLE_SUPER_ADMIN} from "../../constants/names";
import {browserHistory} from "../../router";

import {CustomerPage} from "../customer/customer";
import {AppStore} from "../../store/RootStore";
import {urlList} from "../../common/tools";
import {Mine} from "../mine/mine";
import {Conflict} from "../conflict/conflict";
import {ProjectStampContext} from "../../provider/project_stamp_provider";
import {HumanResource} from "../humanResource/humanResource";
import {LinkPage} from "../link/link";


export function BasicLayout(){
    dayjs.locale('zh-cn')
    const [index, setIndex] = useState("1");
    const { userInfo, doLogout, login, token}  = useContext(UserInfoContext)
    const userStore = AppStore.userStore;
    const [isAdministrator, setIsAdministrator] = useState(false);//是否是管理员
    const [isLawAdmin, setIsLawAdmin] = useState(false);//是否是律所管理员
    const [isRisk, setIsRisk] = useState(false);//是否是律所管理员
    const [userInfoDetail, setUserInfoDetail] = useState({});

    const { TabPane } = Tabs;
    const customerRef = useRef(null);
    const workBenchRef = useRef(null);
    const userRef = useRef(null);
    const projectRef = useRef(null);
    const departmentRef = useRef(null);
    const approvalRef = useRef(null);
    const humanResourceRef = useRef(null);
    const { queryPositionByOfficeId }  = useContext(ProjectStampContext)
    useDidMount(()=>{
        let userInfoDetail = localStorage.getItem("userInfoDetail")
        setUserInfoDetail(eval("(" + userInfoDetail + ")"))
        console.log("urlList()",urlList())
        if(urlList().length <= 0){
            browserHistory.push(`/?index=${index}`);
        }else if(urlList().index >= 0 && urlList().index <= 5){
            setIndex(urlList().index)
        }
        if(token === null || token === ""){
            browserHistory.push("/login");
        }
        if(userInfo !== null && userInfo.role !== null){
            if(userInfo.role === ROLE_SUPER_ADMIN){
                setIsAdministrator(true)
            }
            if(userInfo.role === ROLE_ADMIN){
                setIsLawAdmin(true)
            }
        }
        queryPositionByOfficeId({name:"r_risk"}).then((res)=>{
            if(userInfo.id===res.id){
                setIsRisk(true)
            }
        })
        console.log(userInfo)

    })

    const onClick = ({ key }) => {
        console.log("key",key)
    };

    function toSet(){
        setIndex("10")
    }

    const userBox = (
        <div>
            <p><a onClick={()=>toSet()}>个人设置</a></p>
            <a onClick={()=>doLogout()}>退出登录</a>
        </div>
    )

    function callback(key) {
        console.log("key",key)
        setIndex(key);
        browserHistory.push(`/?index=${key}`);
        if(key === '1'){
            //调用子组件方法
            workBenchRef.current?.focusInput();
        }else if(key === '2'){
            //调用子组件方法
            userRef.current?.focusInput();
        }else if(key === '4'){
            //调用子组件方法
            customerRef.current?.focusInput();
        }else if(key === '3'){
            projectRef.current?.focusInput();
        }else if(key === '7'){
            departmentRef.current?.focusInput();
        }else if(key === '6'){
            approvalRef.current?.focusInput();
        }else if(key === '8'){
            humanResourceRef.current?.focusInput();
        }
    }


    return (
        <div>
            <Layout>
            <Header className='header'>
                <Row justify="center"  align="middle">
                    <Col span={4} className='col-title'>
                      律所云办公
                    </Col>
                    {/*<Col span={4} className='col-img'>*/}
                    {/*    <Image*/}
                    {/*        width={240}*/}
                    {/*        src={LogoImg}*/}
                    {/*        preview={false}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                    <Col span={2} className='col-divider'>
                        <Divider type="vertical" orientation="center" className='divider-title'/>
                    </Col>
                    <Col span={8} className='col-name'>
                        <div className='title-name'>
                            <span>{userInfoDetail?.officeName}</span>
                            <span className='user-name-title'>您好，<span className='user-name'>{userInfoDetail?.realName??userInfoDetail?.username}</span></span>
                        </div>
                        <div className='date'>今天是{dayjs().format("YYYY[年]MMMD日，dddd")}，开始您一天的工作吧～</div>
                    </Col>
                    <Col span={7} className='col-title'>
                        {/*<Input size="large" placeholder="请输入您想要搜索的内容" className='title-input'*/}
                        {/*       prefix={*/}
                        {/*           <Dropdown overlay={menu} >*/}
                        {/*               <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>*/}
                        {/*                   类别<CaretDownOutlined />*/}
                        {/*               </a>*/}
                        {/*           </Dropdown> }*/}
                        {/*       suffix={*/}
                        {/*           <Image src={SearchImg} preview={false} width={20}/>*/}
                        {/*       }*/}
                        {/*/>*/}
                    </Col>
                    <Col span={3} className='col-header'>
                        <Popover content={userBox}>
                            <Avatar size={64} icon={<UserOutlined />}/>
                        </Popover>
                    </Col>
                </Row>
            </Header>

            <Content className='content'  style={{minHeight: document.documentElement.clientHeight - 112}}>
                <div className={isAdministrator?"tab-list normal-tabs":"tab-list"}>
                            {
                                isAdministrator?(
                                    <Tabs defaultActiveKey={index} activeKey={index} onChange={callback} centered>
                                        <TabPane tab={
                                            <div className='tab-box'>
                                                <Image src={index==="1"?LawFirmActiveImg:LawFirmImg} preview={false} className='tab-img'/>
                                                律所
                                            </div>
                                        } key="1">
                                            <LawFirmPage/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="2"?ProjectActiveImg:ProjectImg}  preview={false} className='tab-img'/>
                                   项目
                               </span>} key="2">
                                            <ProjectManagementPage/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="3"?linkActiveImg:linkImg}  preview={false} className='tab-img'/>
                                   工具
                               </span>} key="3">
                                            <LinkPage/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="4"?MineActiveImg:MineImg} preview={false} className='tab-img'/>
                                   客户
                               </span>} key="4">
                                            <CustomerManagementPage/>
                                        </TabPane>

                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="5"?UserActiveImg:UserImg} preview={false} className='tab-img'/>
                                   用户
                               </span>} key="5">
                                            <UserManagementPage/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="6"?FinanceActiveImg:FinanceImg} preview={false} className='tab-img'/>
                                   财务
                           </span>} key="6">
                                            <NoneDate/>
                                        </TabPane>
                                        <TabPane key='10'>
                                            <Mine isAdministrator={isAdministrator}/>
                                        </TabPane>
                                    </Tabs>
                                ):(
                                    <Tabs defaultActiveKey={index} activeKey={index} onChange={callback} centered>
                                        <TabPane tab={
                                            <div className='tab-box'>
                                                <Image src={index==="1"?HomeActiveImg:HomeImg} preview={false} className='tab-img'/>
                                                工作台
                                            </div>
                                        } key="1">
                                            <Workbench  ref={workBenchRef}  onBottomClick={()=>{setIndex("2");console.log("index 2");}}/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="2"?MineActiveImg:MineImg} preview={false} className='tab-img'/>
                                   我的
                               </span>} key="2">
                                            <User ref ={userRef}/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="3"?ProjectActiveImg:ProjectImg}  preview={false} className='tab-img'/>
                                   项目
                               </span>} key="3">
                                            <ProjectPage ref={projectRef}/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="4"?CustomerActiveImg:CustomerImg} preview={false} className='tab-img'/>
                                   客户
                               </span>} key="4">
                                            <CustomerPage ref={customerRef}/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="5"?linkActiveImg:linkImg} preview={false} className='tab-img'/>
                                   工具
                               </span>} key="5">
                                            <LinkPage/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="6"?AuditActiveImg:AuditImg} preview={false} className='tab-img'/>
                                   审批
                           </span>} key="6">
                                            <Approval ref={approvalRef}/>
                                        </TabPane>
                                        <TabPane tab={
                                            <span className='tab-box'>
                                   <Image src={index==="7"?DepartmentActiveImg:DepartmentImg} preview={false} className='tab-img'/>
                                   部门
                           </span>} key="7">
                                            <Department ref={departmentRef}/>
                                        </TabPane>
                                        {
                                            isLawAdmin?
                                                <TabPane tab={
                                                    <span className='tab-box'>
                                   <Image src={index==="8"?UserActiveImg:UserImg} preview={false} className='tab-img'/>
                                   人资
                           </span>} key="8">
                                                    <HumanResource ref ={humanResourceRef}/>
                                                </TabPane> : null
                                        }
                                        {
                                                 <TabPane tab={
                                                    <span className='tab-box'>
                                   <Image src={index==="9"?ConflictActiveImg:ConflictImg} preview={false} className='tab-img'/>
                                   利冲
                           </span>} key="9">
                                                    <Conflict />
                                                </TabPane>
                                        }

                                        <TabPane key='10'>
                                             <Mine isAdministrator={isAdministrator}/>
                                        </TabPane>
                                    </Tabs>
                                )
                            }

                        </div>
            </Content>

            </Layout>
            {/*<div className='fotter'>湖北瀛楚律师事务所</div>*/}
            <div className='fotter'>律师事务所</div>
        </div>
    )
}
