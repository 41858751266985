import React, {useState} from "react";
import {
    apiGetProjectAudit,
    apiGetProjectList,
    apiGetProjectType,
    apiQueryPositionByOfficeId,
    apiQueryProjectBySelf
} from "../api/apis";

export const ProjectListContext = React.createContext();

export const ProjectListProvider = (props) => {
    const [joinList, setJoinList] = useState([]);
    const [noPageList, setNoPageList] = useState([]);
    const defaultPageSize = 5;

    async function getProjectList(params) {
        return  new Promise((resolve) => {
            apiGetProjectList(params).then((res)=>{
                setJoinList(res)
                resolve(res)
            })
        })
    }

    const queryProjectBySelf = () =>{
        apiQueryProjectBySelf().then((res)=> {
            setNoPageList(res)
            }
        )
    }



    return (
        <ProjectListContext.Provider value={{defaultPageSize,joinList,setJoinList,queryProjectBySelf,noPageList,getProjectList}}>
            {props.children}
        </ProjectListContext.Provider>
    )
}