import './approval.less'
import {ApprovalTable} from "./approval-table";
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import {Breadcrumb, Button, Card, Col, Divider, Image, Menu, message, Modal, Row, Tabs, Tooltip} from "antd";
import Text from "antd/es/typography/Text";
import {ApprovalIcon, IconWholeLight} from "../../constants/images";
import SubMenu from "antd/es/menu/SubMenu";
import {Documentation, Task} from "../user/user";
import {ApprovalContext} from "../../provider/approval_provider";
import {useDidMount} from "../../common/state";
import ExportJsonExcel from "js-export-excel";
const { TabPane } = Tabs;

export  const Approval = forwardRef((props, ref) => {

    const [tabIndex, setTabIndex] = useState("1");

    const [ pageNo, setPageNo] = useState(1);

    const {getApprovalNum,getApprovalList,defaultPageSize,secondType} = useContext(ApprovalContext)

    function callback(key) {
        setTabIndex(key);
        setPageNo(1)
        getApprovalNum({
            searchType:parseInt(key)});
        getApprovalList({
                searchName:'',
                searchType:parseInt(key),
                secondType:parseInt(secondType),
                pageNo: 0,
                size:defaultPageSize
            })
    }

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getApproval,
    }));

    useDidMount(()=>{
        setTabIndex(tabIndex);
        getApprovalNum({
            searchType:parseInt(tabIndex)})
    })

    function getApproval(){
        setTabIndex(tabIndex);
        getApprovalNum({
            searchType:parseInt(tabIndex)});
        getApprovalList({
            searchName:'',
            searchType:parseInt(tabIndex),
            secondType:parseInt(secondType),
            pageNo: 0,
            size:defaultPageSize
        })
    }

    return (
        <div className='approval'>
            <div className='approval-card card-box'>
                <Row className='approval-title'>
                    <Image src={ApprovalIcon} width={35} preview={false}/>审批
                </Row>
                <div className="approval-banner">
                    <Tabs defaultActiveKey={tabIndex} tabBarGutter={40} onChange={callback}>
                        <TabPane tab="我审批的" key="1">
                            <div/>
                        </TabPane>
                        <TabPane tab="我发起的" key="2">
                            <div/>
                        </TabPane>
                    </Tabs>
                </div>
                <ApprovalMenu tabIndex = {tabIndex} pageNo={pageNo} setPageNo={setPageNo}/>
            </div>

        </div>
    )
})

const ApprovalMenu = forwardRef((props, ref) =>{
    const {tabIndex,pageNo,setPageNo} = props
    const {approvalList,getApprovalList,defaultPageSize,
        approvalNum,secondType,setSecondType,getAllApprovalInvoiceList,
        approvalInvoiceList} = useContext(ApprovalContext)
    const [isModalVisible, setIsModalVisible] = useState(false)

    useDidMount(()=>{
        setPageNo(1)
        getApprovalList({
            searchName:'',
            searchType:parseInt(tabIndex),
            secondType:secondType,
            pageNo: 0,
            size:defaultPageSize
            })
        }
    )

    const typeList=['','立案审批','结项审批','重启项目审批',
        '搁置项目审批','合同审批','发票审批','用章审批','团队邀请'
    ]
    const approvalStatusList =['未审核','已通过','已驳回']

    const handleClick = value =>{
        setSecondType(value.key)
        setPageNo(1)
        getApprovalList({
            searchName:'',
            searchType:parseInt(tabIndex),
            secondType:parseInt(value.key),
            pageNo: 0,
            size:defaultPageSize
        })

    }

    const handleCancel=()=>{
        message.error('取消导出')
        setIsModalVisible(false)
    }

    const showModal = ()=>{
        getAllApprovalInvoiceList({
            page:0,
            size:0x7fffffff,
            searchType: 1,
            secondType: 6
        })
        setIsModalVisible(true)
    }

    //发票审核确认导出
    const checkOut = () =>{
        message.success('确认导出');
        setIsModalVisible(false)
        const data = approvalInvoiceList.list;//表格数据
        var option={};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if(data){
                    let obj = {
                        '序号': (parseInt(i)+1).toString(),
                        '抬头': data[i].title??'/',
                        '金额': data[i].taxAmount??'/',
                        '票号': data[i].invoiceNumber??'/',
                        '回款': data[i].receivable??'/',
                        '审核状态': approvalStatusList[data[i].state]??'/',
                        '废弃状态': data[i].abandonStatus==1?'已废弃':'正常',
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = '发票信息'
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                sheetFilter:['序号','抬头','金额','票号', '回款','审核状态','废弃状态'],
                sheetHeader:['序号','抬头','金额','票号', '回款','审核状态','废弃状态'],
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    return (
        <div>
            <Divider type="horizontal" orientation="center" className='divider-title'/>
            <Row>
                <Col span={6}  className='approval-menu'>
                    <Menu
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        onClick={handleClick}
                        style={{width:256,margin: '0 auto'}}
                    >

                        <SubMenu key="sub1"  title={'项目审批('+(approvalNum?.projectTotalNum??0).toString()+')'}>
                            <Menu.Item key={1}>
                                {typeList[1]+'('+(approvalNum?.projectStartNum??0).toString()+')'}</Menu.Item>
                            <Menu.Item key={3}>{typeList[3]+'('+(approvalNum?.projectRestartNum??0).toString()+')'}</Menu.Item>
                            <Menu.Item key={4}>{typeList[4]+'('+(approvalNum?.projectShelveNum??0).toString()+')'}</Menu.Item>
                            <Menu.Item key={2}>{typeList[2]+'('+(approvalNum?.projectEndNum??0).toString()+')'}</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2"  title={'行政审批('+(approvalNum?.administrationTotalNum??0).toString()+')'}>
                            <Menu.Item key={5}>{typeList[5]+'('+(approvalNum?.financeContractNum??0).toString()+')'}</Menu.Item>
                            <Menu.Item key={7}>{typeList[7]+'('+(approvalNum?.administrationStampNum??0).toString()+')'}</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3"  title={'财务审批('+(approvalNum?.financeTotalNum??0).toString()+')'}>
                            <Menu.Item key={6}>{typeList[6]+'('+(approvalNum?.financeInvoiceNum??0).toString()+')'}</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4"  title={'人事审批('+(approvalNum?.personnelTotalNum??0).toString()+')'}>
                            <Menu.Item key={8}>{typeList[8]+'('+(approvalNum?.personnelTeamNum??0).toString()+')'}</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Col>
                <Col span={1} className='approval-menu-divider'>
                    <Divider type="vertical" orientation="center" className='vertical-divider-title'/>
                </Col>
                <Col span={17}>
                    <div className='approval-content'>
                        <Row className='approval-row'>
                            <Col span={8} className='row-start'>
                                <Text className = 'approval-type'>{typeList[secondType]}</Text>
                                <Divider type='vertical' />
                                <Text className = 'approval-num'>共{approvalList.totalNum}条审批</Text>
                            </Col >
                            <Col span = {10}/>
                            {(secondType==6&&tabIndex==1)?
                                <Col span={6}>
                                    <Button type="primary" shape="round" size='large' onClick={() => {
                                        showModal()
                                    }}>导出表格</Button>
                                </Col> :<Col span={6}/>
                            }


                        </Row>
                        <ApprovalTable  tabIndex ={tabIndex} pageNo={pageNo} setPageNo={setPageNo}/>
                    </div>
                </Col>
            </Row>
            <Modal title="导出表格" visible={isModalVisible} onOk={checkOut} onCancel={handleCancel}>
                <p>确认导出表格？</p>
            </Modal>
        </div>
    )
});
