import ExportJsonExcel from 'js-export-excel';
import React, {useContext, useState} from "react";
import "./userManagement.less";
import  {Image,Modal, Switch, Button, Form, Row, Col, Select, DatePicker, message} from "antd";
import ProTable from '@ant-design/pro-table';
import { UserActiveImg} from "../../constants/images";
import Text from "antd/lib/typography/Text";
import {useDidMount} from "../../common/state";
import {UserManagementContext} from "../../provider/user_management_provider";
import {LawFirmContext} from "../../provider/lawFirm_provider";
import {DepartmentContext} from "../../provider/department_provider";
const { Option } = Select;
const { RangePicker } = DatePicker;


export function UserManagementPage() {
    const {userManageList,getUserManagementList,defaultPageSize} = useContext(UserManagementContext)
    const {allLawList,getAllLawList} = useContext(LawFirmContext)
    const {gradesList,getAllGradesList} = useContext(DepartmentContext)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const formSearchRef =React.createRef();

    useDidMount(() => {
        getAllGradesList()
        getAllLawList({
            pageNo: 0,
            size:100
        })
        getList()
    });


    const getList = (value) => {
        let searchValue = formSearchRef.current.getFieldsValue();
        getUserManagementList({
            grade: searchValue.grade,
            officeId: searchValue.lawName,
            state: searchValue.state,
            pageNo: value?.current??0,
            size: value?.size??defaultPageSize,
            startTime:searchValue.time === undefined ? undefined : searchValue.time[0],
            endTime:searchValue.time === undefined ? undefined : searchValue.time[1],
        })
    }

    function onPageChange(page, size) {
        getList({
            current:page-1,
            size:size
        })
    }

    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: userManageList.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    //重置
    const handleFormReset =()=>{
        formSearchRef.current.resetFields();
        getList()
    };

    //查询
    const handleSearch =(e)=>{
        getList()
    };

    //冻结
    const handleShelf =()=>{

    };

    const showModal = ()=>{
        getList({
            page:0,
            size:0x7fffffff
        })
        setIsModalVisible(true)
    }

    const handleCancel=()=>{
        message.error('取消导出')
        setIsModalVisible(false)
    }

    //确认导出
    const checkOut = () =>{
        message.success('确认导出');
        setIsModalVisible(false)
        const data = userManageList.list;//表格数据
        var option={};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if(data){
                    let obj = {
                        '真实姓名': data[i].realName??'/',
                        '联系电话': data[i].phone??'/',
                        '级别': data[i].lawGradeVO?.gradeName??'实习律师',
                        '所属律所': data[i].officeName??'/',
                        '状态': data[i].onJob===0?'在职':'离职',
                        '注册时间': data[i].registerTime.replace('T',' '),
                        '是否冻结': data[i].status === 0 ? '未冻结': '冻结',
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = '用户信息'
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                sheetFilter:['真实姓名','联系电话','级别', '所属律所', '状态','注册时间','是否冻结'],
                sheetHeader:['真实姓名','联系电话','级别', '所属律所', '状态','注册时间','是否冻结'],
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const tabColumns = [
        {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
        },
        {
            title: '真实名称',
            key:'realName',
            dataIndex: 'realName',
        },
        {
            title: '联系电话',
            key:'phone',
            dataIndex: 'phone',
        },
        {
            title: '级别',
            key:'lawGradeVO',
            dataIndex: 'lawGradeVO',
            render:(dom) => (
                <Text>{dom.gradeName??'实习律师'}</Text>
            ),
        },
        {
            title: '所属律所',
            key:'officeName',
            dataIndex: 'officeName',
        },
        {
            title:'状态',
            key:'onJob',
            dataIndex: 'onJob',
            render:(dom) => (
                <Text>{dom===0?'在职':'离职'}</Text>
                // <Switch disabled={true} defaultChecked={record.lawFirmState==='0'?false:true} />
            ),
        },
        {
            title:'添加时间',
            key:'registerTime',
            valueType: 'dateTime',
            dataIndex: 'registerTime',
        },
        {
            title:'是否冻结',
            key:'status',
            dataIndex: 'status',
            render:(dom) => (
                <Switch
                        defaultChecked={dom===0?false:true}
                        onChange={(e) => handleShelf(e, dom)}
                />
            ),
        },
    ];

    const renderSimpleForm =()=> {
        return (
            <Form
                ref={formSearchRef}
                onFinish={handleSearch.bind(this)}
                layout="inline">
                <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{width:'100%'}}>
                    <Col md={8} sm={24}>
                        <Form.Item label="所属律所" name="lawName">
                            <Select placeholder="请选择" allowClear>
                                {
                                    allLawList?.list?.map((item)=>
                                        <Option value={item.officeId} key={item.officeId}>{item.officeName}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="级别" name="grade">
                            <Select placeholder="请选择" allowClear>
                                {
                                    gradesList.map((item)=>
                                        <Option value={item.grade} key={item.grade}>{item.gradeName}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="状态" name="state">
                            <Select placeholder="请选择在职状态" allowClear>
                                <Option value={1} >离职</Option>
                                <Option value={0} >在职</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="添加时间" name="time">
                            <RangePicker allowClear  style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col md={16} sm={24}>
                     <span
                         style={{float:"right"}}
                     >
                       <Button type="primary" htmlType="submit">
                        查询
                        </Button>
                      <Button style={{ marginLeft: 8 }} onClick={handleFormReset} htmlType="reset">重置</Button>
                     </span>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <div className="user-management card-box">
            <div className='user-management-title'>
                <div className='user-management-title-left'>
                    <Image src={UserActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>用户管理</span>
                </div>
                <Button type="primary" shape="round" size='large' onClick={()=>{showModal()}}>导出表格</Button>
            </div>
            {/*<Divider type="horizontal" orientation="center" className='divider-title'/>*/}

            <div className={'tableListForm'}>{renderSimpleForm()}</div>
            <div className='project-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={userManageList.list}
                    rowKey={r => (r.id)}
                    pagination={paginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <Modal title="导出表格" visible={isModalVisible} onOk={checkOut} onCancel={handleCancel}>
                <p>确认导出表格？</p>
            </Modal>
        </div>
    )
}



