import React, {useState} from "react";
import {apiQueryCustomerListBySuperAdmin} from "../api/apis";

export const CustomerManagementContext = React.createContext();

export const CustomerManageProvider = (props) => {
    const [customerManageList, setCustomerManageList] = useState({});
    const defaultPageSize = 10;

    const getCustomerManagementList = (params) =>{
        apiQueryCustomerListBySuperAdmin(params).then((res)=>
            setCustomerManageList(res))
    }

    return (
        <CustomerManagementContext.Provider value={{
            customerManageList,
            setCustomerManageList,
            getCustomerManagementList,
            defaultPageSize}}>
            {props.children}
        </CustomerManagementContext.Provider>
    )
}