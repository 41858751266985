import React, {useState} from "react";
import {
    apiConfirmFile,
    apiCreateFile,
    apiCreateImageFile,
    apiCreateFolder,
    apiCreateNormalFile,
    apiDeleteFile,
    apiDownloadFile,
    apiGetView,
    apiUploadFaaFile,
} from "../api/apis";

export const ProjectFileContext = React.createContext();

export const ProjectFileProvider = (props) => {
    const [fileList, setFileList] = useState([]);
    const [projectFileList, setProjectFileList] = useState([]);
    const [downloadFileUrl, setDownloadFileUrl] = useState("");
    const [fileInfo, setFileInfo] = useState();
    const defaultPageSize = 5;

    //查看文件列表
    const getView = (params) =>{
        let filePro = {}
        apiGetView(params).then((res)=>{
            filePro.parentId = params.parentId==null?0:params.parentId
            filePro.list = res
            if(params.parentId==null){
                setFileList([].concat(filePro))
            }else{
                setFileList(fileList.concat(filePro))
            }
        })
    }

    //查看项目文件列表
    const getProjectView = (params) =>{
        let filePro = {}
        apiGetView(params).then((res)=>{
            filePro.parentId = params.parentId==null?0:params.parentId
            filePro.list = res
            if(params.parentId==null){
                setProjectFileList([].concat(filePro))
            }else{
                setProjectFileList(projectFileList.concat(filePro))
            }
        })
    }

    //创建文件夹
    async function createFolder(params){
        let type = false
        await apiCreateFolder(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    //创建文件
    async function createImageFile(params){
        let res = await apiCreateImageFile(params)
        setFileInfo(res)
        return res
    }

    //创建文件
    async function createFile(params){
        let res = await apiCreateFile(params)
        setFileInfo(res)
        return res
    }

    //创建普通文件
    async function createNormalFile(params){
        let res = await apiCreateNormalFile(params)
        setFileInfo(res)
        return res
    }

    //确认文件
    async function confirmFile(params){
        await apiConfirmFile(params)
        setTimeout(()=>{
             apiUploadFaaFile(params)
        }, 1000)
    }

    //确认文件
    async function confirmImageFile(params){
        await apiConfirmFile(params)
    }

    //删除文件
    async function deleteFile(params){
        await apiDeleteFile(params)
    }

    //下载文件
    async function downloadFile(params){
        let res = await apiDownloadFile(params)
        setDownloadFileUrl(res);
        return res
    }

    return (
        <ProjectFileContext.Provider value={{
            fileList,getView,setFileList,getProjectView,projectFileList,
            defaultPageSize,
            createFolder,
            createImageFile,
            createFile,
            confirmFile,
            confirmImageFile,
            deleteFile,
            downloadFile,downloadFileUrl,
            fileInfo,createNormalFile
        }}>
            {props.children}
        </ProjectFileContext.Provider>
    )
}
