import React from 'react';
import './App.less';
import {Login} from "./page/login/login";

export function App () {
    return(
        <div className="App">
            <Login/>
        </div>
    )
}
