import React, {useState} from "react";
import {apiQueryConflictByCustomerName, apiQueryConflictByProjectName} from "../api/apis";

export const ConflictContext = React.createContext();

export const ConflictProvider = (props) => {
    const [projectConflictList, setProjectConflictList] = useState([]);
    const [customerConflictList, setCustomerConflictList] = useState([]);

    const defaultPageSize = 5;

    const getProjectConflictList = (params) =>{
        apiQueryConflictByProjectName(params).then((res)=>
            setProjectConflictList(res))
        }

    const getCustomerConflictList = (params) =>{
        apiQueryConflictByCustomerName(params).then((res)=>
            setCustomerConflictList(res))
    }

    return (
        <ConflictContext.Provider value={{
            getProjectConflictList,projectConflictList,defaultPageSize,
            getCustomerConflictList,customerConflictList
        }}>
            {props.children}
        </ConflictContext.Provider>
    )
}