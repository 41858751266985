import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import reportWebVitals from './reportWebVitals';
import {Routers, WrappedApp} from "./router";
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN';
// index.js
import 'dayjs/locale/zh-cn'
import * as dayjs from "dayjs";
dayjs.locale('zh-cn')

ReactDOM.render(
      <ConfigProvider locale={zhCN}>
          <WrappedApp>
            <Routers />
          </WrappedApp>
      </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
