import React, {useState} from "react";
import {
    apiCreateStamp,
    apiGetStampDetail,
    apiGetStampList,
    apiQueryPositionByOfficeId,
    apiUpdateDocumentsNumber
} from "../api/apis";

export const ProjectStampContext = React.createContext();

export const ProjectStampProvider = (props) => {
    const [projectStampList, setProjectStampList] = useState([]);
    const [projectStampDetail, setProjectStampDetail] = useState({});
    const [riskUser, setRiskUser] = useState({});
    const defaultPageSize = 5;

    const getProjectStampList = (params) =>{
        apiGetStampList(params).then((res)=>{
                setProjectStampList(res)
        }
        )
    }

    const getProjectStampDetail = (params) =>{
        apiGetStampDetail(params).then((res)=>{
            setProjectStampDetail(res)
            }
        )
    }

    const updateDocumentsNumber = async (params) =>{
        await apiUpdateDocumentsNumber(params)
    }

    async function createProjectStamp(params){
        let type = false
        await apiCreateStamp(params).then(res=>{
             if(res.success){
                 type = true
             }else{
                 type = false
             }
         })
        return type
    }

    async function queryPositionByOfficeId(params) {
        return  new Promise((resolve) => {
             apiQueryPositionByOfficeId(params).then((res)=>{
                 setRiskUser(res)
                 resolve(res)
             })
        })
    }

    // async function queryPositionByOfficeId(params){
    //     let value;
    //     await apiQueryPositionByOfficeId(params).then((res)=>{
    //         setRiskUser(res)
    //         value = res
    //         }
    //     )
    //     return value
    // }



    return (
        <ProjectStampContext.Provider value={{
            getProjectStampList,projectStampList,
            getProjectStampDetail,projectStampDetail,
            createProjectStamp,defaultPageSize,
            queryPositionByOfficeId,riskUser,
            updateDocumentsNumber
        }}>
            {props.children}
        </ProjectStampContext.Provider>
    )
}