import React, {useState} from "react";
import {
    apiCreateProject,
    apiGetCurOfficeUserList,
    apiGetProjectAudit,
    apiGetProjectType, apiQueryAllCustomer, apiQueryCurJoinedTeam,
    apiQueryCurWorkingTeamUser, apiQueryTaskTempList,
    apiQueryTeamList,apiQueryCustomerPosition
} from "../api/apis";
import {message} from "antd";

export const ProjectCreatContext = React.createContext();

export const ProjectCreatProvider = (props) => {
    const [projectTypeList, setProjectType] = useState([]);
    const [projectAudit, setProjectAudit] = useState([]);
    const [teamUser, setTeamUser] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [curOfficeUserList, setCurOfficeUserList] = useState([]);
    const [allCustomer, setAllCustomer] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [customerPositionList, setcustomerPositionList] = useState([]);

    const getProjectTypeList = () =>{
        apiGetProjectType().then((res)=>{
            setProjectType(res)
        }
        )
    }

    const getProjectAudit = () =>{
        apiGetProjectAudit().then((res)=>{
            setProjectAudit(res)
            }
        )
    }

    const getTeamUser = (params) =>{
        apiQueryCurWorkingTeamUser(params).then((res)=>{
            setTeamUser(res)
            }
        )
    }

    const getTeamList = (params) =>{
        apiQueryCurJoinedTeam(params).then((res)=>{
            setTeamList(res)
            }
        )
    }

    const getCurOfficeUserList = (params) =>{
        apiGetCurOfficeUserList(params).then((res)=>{
            console.log(res)
            setCurOfficeUserList(res)
            }
        )
    }

    const getAllCustomer = (params) =>{
        apiQueryAllCustomer(params).then((res)=>{
            setAllCustomer(res)
            }
        )
    }

    const queryCustomerPosition = () =>{
        apiQueryCustomerPosition().then((res)=>{
            setcustomerPositionList(res)
            }
        )
    }



    const getTaskTempList = (params) =>{
        apiQueryTaskTempList(params).then((res)=>{
            setTempList(res)
            }
        )
    }

    async function createProject(params){
        let type = false
        await apiCreateProject(params).then(res=>{
             if(res.success){
                 type = true
             }else{
                 type = false
             }
         })
        return type
    }


    return (
        <ProjectCreatContext.Provider value={{
            projectTypeList, getProjectTypeList,
            projectAudit, getProjectAudit,
            teamUser,getTeamUser,
            teamList,getTeamList,
            curOfficeUserList,getCurOfficeUserList,
            allCustomer,getAllCustomer,
            createProject,getTaskTempList,tempList,
            customerPositionList,queryCustomerPosition
        }}>
            {props.children}
        </ProjectCreatContext.Provider>
    )
}