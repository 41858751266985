import {isNil} from "lodash"
import {browserHistory} from "../router";

export class UserStore {
   token = null;
   userInfo = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.token = localStorage.getItem("token");
    try {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    } catch (err) {
      console.log("get user info from storage failed!", err.message)
    }
  }

  isLogin = () => {
    return !isNil(this.token)
  };

  setToken = token => {
    localStorage.setItem("token", token);
    this.token = token
  };

  getToken = () => {
    return this.token;
  };

  setUserInfo = userInfo => {
    console.log("userInfo",userInfo)
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    // this.userInfo = userInfo
  };

  getUserInfo = ()=>{
    return this.userInfo;
  };

  clearToken = () =>{
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    this.token = null;
    this.userInfo = null;
  }

  logout = () => {
    this.clearToken()
    console.log("do logout")
    browserHistory.push("/login");
  };

  refresh = () => {
    console.log("refresh user info");
    if (!this.isLogin()) {
      return;
    }
  }

}
