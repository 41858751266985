// 修改文件夹名称
import {Button, Divider, Form, Input, Modal} from "antd";
import {changeFileName} from "../../api/apis";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};

export const ChangeFileNameModal = (props) =>{

    const {visible, setVisible, file, onChangeSuccess} = props

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        const fileName = e.name
        const fileId = file.fileId
        changeFileName(fileId, fileName).then(()=>{
            console.log("change file name success")
            setVisible(false);
            onChangeSuccess()
        });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={visible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>修改文件夹名称</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="新文件夹名称"
                                name="name"
                                rules={[{ required: true, message: '请输入新的文件夹名称' }]}
                            >
                                <Input placeholder={file.name} className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}