import React, {useContext, useEffect, useState} from "react";
import "./projectManagement.less";
import {Image, Switch, Button, Divider, Form, Row, Input, Col, Select, DatePicker, message, Modal} from "antd";
import ProTable from '@ant-design/pro-table';
import {ProjectActiveImg, UserActiveImg} from "../../constants/images";
import Text from "antd/lib/typography/Text";
import {LawFirmContext} from "../../provider/lawFirm_provider";
import {ProjectManagementContext} from "../../provider/project_manager_provider";
import ExportJsonExcel from "js-export-excel";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {useDidMount} from "../../common/state";
const { Option } = Select;
const { RangePicker } = DatePicker;

const projectState = ['预立案','进行中','已完成','已搁置']

export function ProjectManagementPage() {

    const formSearchRef = React.createRef();

    const {allLawList,getAllLawList} = useContext(LawFirmContext)
    const {projectManageList,getProjectManagementList,defaultPageSize} = useContext(ProjectManagementContext)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const {projectTypeList,getProjectTypeList} = useContext(ProjectCreatContext)
    const [ projectTypeMap, setProjectTypeMap] = useState([]);

    useDidMount(()=>{
        getAllLawList({
            pageNo: 0,
            size:100
        })
        getProjectTypeList()
        getList()
    })

    useEffect(()=>{
        let list = []
        projectTypeList.map((item)=>{
            list = list.concat(item.secondList)
        })
        setProjectTypeMap(list)
    },[projectTypeList])

    const getList = (value) => {
        let searchValue = formSearchRef.current.getFieldsValue();
        getProjectManagementList({
            state: searchValue.state,
            officeId: searchValue.lawName,
            caseType: searchValue.type,
            createStartTime:searchValue.time === undefined ? undefined : searchValue.time[0],
            createEndTime:searchValue.time === undefined ? undefined : searchValue.time[1],
            pageNo: value?.current??0,
            size: value?.size??defaultPageSize
        })
    }

    function onPageChange(page, size) {
        getList({
            current:page-1,
            size:size
        })
    }

    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: projectManageList?.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    //重置
    const handleFormReset =()=>{
        formSearchRef.current.resetFields();
        getList()
    };

    //查询
    const handleSearch =()=>{
        getList()
    };

    const showModal = ()=>{
        getList({
            page:0,
            size:0x7fffffff
        })
        setIsModalVisible(true)
    }

    const handleCancel=()=>{
        message.error('取消导出')
        setIsModalVisible(false)
    }



    const tabColumns = [
        {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
        },
        {
            title: '项目名称',
            key: 'projectName',
            dataIndex: 'projectName',
        },
        {
            title: '客户名称',
            key: 'customerName',
            dataIndex: 'customerName',
            render:(_,record) => (
                <Text>{record.customerList.length>0?record.customerList[0].customerName :'/'}</Text>
            ),
        },
        {
            title: '项目类型',
            key: 'caseTypeStr',
            dataIndex: 'caseTypeStr',
        },
        {
            title: '所属律所',
            key: 'officeName',
            dataIndex: 'officeName',
        },
        {
            title: '负责律师',
            key: 'adminName',
            dataIndex: 'adminName',
        },
        {
            title:'状态',
            key: 'state',
            dataIndex: 'state',
            render:(_,record) => (
                <Text>{projectState[record.state]}</Text>
            ),
        },
        {
            title:'立案时间',
            valueType: 'dateTime',
            key: 'createTime',
            dataIndex: 'createTime',
        },

    ];

    //确认导出
    const checkOut = () =>{
        message.success('确认导出');
        setIsModalVisible(false)
        const data = projectManageList.list;//表格数据
        var option={};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if(data){
                    let obj = {
                        '序号': (i+1).toString(),
                        '项目名称': data[i].projectName??'/',
                        '客户名称': data[i].customerList.length>0?data[i].customerList[0].customerName :'/',
                        '项目类型': data[i].caseTypeStr??'/',
                        '所属律所': data[i].officeName??'/',
                        '负责律师': data[i].adminName??'/',
                        '状态': projectState[data[i].state]??'/',
                        '立案时间': data[i].createTime.replace('T',' '),
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = '项目信息'
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                sheetFilter:['序号','项目名称','客户名称', '项目类型', '所属律所','负责律师','状态','立案时间'],
                sheetHeader:['序号','项目名称','客户名称', '项目类型', '所属律所','负责律师','状态','立案时间'],
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const renderSimpleForm =()=> {
        return (
            <Form
                ref={formSearchRef}
                onFinish={handleSearch.bind(this)}
                layout="inline">
                <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{width:'100%'}}>
                    <Col md={8} sm={24}>
                        <Form.Item label="所属律所" name="lawName">
                            <Select placeholder="请选择" allowClear>
                                {
                                    allLawList?.list?.map((item)=>
                                        <Option value={item.officeId} key={item.officeId}>{item.officeName}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="项目类型" name="type">
                            <Select
                                placeholder="请选择"
                                allowClear
                            >
                                {
                                    projectTypeMap.map((item) =>
                                        <Option value={item.key} key = {item.key}>{item.value}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="项目状态" name="state">
                            <Select placeholder="请选择"
                                    allowClear
                            >
                                <Option value={0}>预立案</Option>
                                <Option value={1}>进行中</Option>
                                <Option value={2}>已完成</Option>
                                <Option value={3}>已搁置</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={24}>
                        <Form.Item label="立案时间" name="time">
                            <RangePicker
                                style={{width:'100%'}}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={16} sm={24}>
                     <span
                         style={{float:"right"}}
                     >
                       <Button type="primary" htmlType="submit">
                        查询
                        </Button>
                      <Button style={{ marginLeft: 8 }} onClick={handleFormReset}>重置</Button>
                     </span>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <div className="project-management card-box">
            <div className='project-management-title'>
                <div className='project-management-title-left'>
                    <Image src={ProjectActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>项目管理</span>
                </div>
                <Button type="primary" shape="round" size='large' onClick={()=>{showModal()}}>导出表格</Button>
            </div>
            {/*<Divider type="horizontal" orientation="center" className='divider-title'/>*/}

            <div className={'tableListForm'}>{renderSimpleForm()}</div>
            <div className='project-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={projectManageList.list}
                    rowKey={r => (r.id)}
                    pagination={paginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <Modal title="导出表格" visible={isModalVisible} onOk={checkOut} onCancel={handleCancel}>
                <p>确认导出表格？</p>
            </Modal>
        </div>
    )
}
