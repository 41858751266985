import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Steps,
    message,
    Row,
    Col,
    Divider,
    Radio,
    Form,
    Select,
    Input,
    Tabs,
    Modal, Dropdown, Menu, TreeSelect
} from "antd";
import {remove} from "lodash";
import {
    DownOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {useDidMount} from "../../common/state";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {ProjectListContext} from "../../provider/project_list_provider";
import {UploadNormalBox} from "../../common/uploadNormal";
import {indexOf} from "lodash";
import {Model1_Img, Model2_Img} from "../../constants/images";

const { Step } = Steps;
const { Option } = Select;
const {TabPane} = Tabs;
const { SHOW_PARENT } = TreeSelect;
const steps = [
    {
        key:1,
        title: '填写项目信息',
        content: 'First-content',
    },
    {
        key:2,
        title: '填写项目流程',
        content: 'Second-content',
    },
    {
        key:3,
        title: '完成项目创建',
        content: 'Last-content',
    },
];
const sortList  = ["当事人","第三方","相关方","对方当事人关联公司","委托方","委托方关联公司","相关利益潜在第三方","合同特别限制代理方"];
const formItemLayoutWithOutLabel = {
    labelCol: { span: 4},
    wrapperCol: { span: 16},
};

export function ProjectCreat(props) {
    const {setIndex} = props
    const [value, setValue] = React.useState(0);

    const [isModel, setIsModel] = useState(true);
    const [isTemplateModel, setIsTemplateModel] = useState(false);
    const [firstForm, setFirstForm] = useState(false);

    const {projectTypeList,getProjectTypeList,projectAudit,getProjectAudit,getAllCustomer,allCustomer,
        teamList,getTeamList,curOfficeUserList,getCurOfficeUserList,createProject,getTaskTempList,tempList,queryCustomerPosition,customerPositionList} = useContext(ProjectCreatContext)
    const { userInfo  }  = useContext(UserInfoContext)
    const {getProjectList,defaultPageSize} = useContext(ProjectListContext)
    const [projectType, setProjectType] = useState(null);
    const [ sortText, setSortText] = useState([]);
    const [ sortValue, setSortValue] = useState([]);
    const [ projectDetail, setProjectDetail] = useState();
    const [contractFileList,setContractFileList] = useState([]);
    const [contractFileListShow,setContractFileListShow] = useState([]);
    const [sealFileList,setSealFileList] = useState([]);
    const [sealFileListShow,setSealFileListShow] = useState([]);
    const [userInfoDetail, setUserInfoDetail] = useState({});
    const [tempId, setTempId] = useState();

    const {getTeamUser,teamUser} = useContext(ProjectCreatContext)

    const firstFormValue =React.createRef();

    useDidMount(() => {
        getProjectTypeList()
        getProjectAudit()
        getTeamList()
        getCurOfficeUserList()
        getAllCustomer()
        getTaskTempList({
            pageNo: 0,
            size: 10
        })
        queryCustomerPosition()
        let userInfoDetail = localStorage.getItem("userInfoDetail")
        setUserInfoDetail(eval("(" + userInfoDetail + ")"))
    });

    const onChange = e => {
        console.log('radio checked-----------', e.target.value);
        setValue(e.target.value);
    };

    const [current, setCurrent] = React.useState(0);
    const next = () => {
        let searchValue = firstFormValue.current.getFieldsValue();
        console.log('Received values of form-------:', searchValue);
        if(searchValue.names==undefined){
            message.error("客户为必填字段,至少选择一个客户")
            return
        }else{
            let num = 0
            searchValue.names.map(
                (item)=>{
                    if(item!==undefined){
                        num++
                    }
                }
            )
            if(num==0){
                message.error("客户为必填字段,至少选择一个客户")
                return
            }
        }
        if(searchValue.groupId==undefined){
            message.error("所属团队为必填字段")
            return
        }
        if(searchValue.sourceUser==undefined){
            message.error("案源律师为必填字段")
            return
        }
        if(searchValue.undertakerUser==undefined){
            message.error("承办律师为必填字段")
            return
        }
        if(searchValue.name==undefined){
            message.error("项目名称为必填字段")
            return
        }
        if(searchValue.membersId==undefined){
            message.error("项目成员为必填字段")
            return
        }
        setFirstForm(searchValue)
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
        console.log('88888----firstForm',firstForm)
    };

    const done = () => {
        console.log('Received values of form one----------:', firstForm);
        let others = []
        firstForm?.other?.map(
            (item,index)=>{
                others = others.concat({type:sortValue[index],name:item})
            }
        )
        let membersIdList = []
        let index = indexOf(firstForm.membersId,userInfo.id)
        if(index<0 && firstForm.membersId){
            membersIdList = firstForm.membersId.concat(userInfo.id)
        }else{
            membersIdList = firstForm.membersId
        }
        createProject({
            adminId:userInfo.id,
            audit: firstForm.audit,
            customerPosition: firstForm.customerPosition,
            caseType: projectType,
            contract: contractFileList,//合同
            customerIds: firstForm.names,
            defendants: firstForm.defendants,
            detail: projectDetail,
            groupId: firstForm.groupId,
            membersId: membersIdList,//成员list
            name: firstForm.name,
            others: others,
            projectLevel: firstForm.projectLevel,
            projectNum: firstForm.projectNum,
            sourceUser: firstForm.sourceUser,
            stamp: sealFileList,//盖章
            state: value,
            undertakerUser: firstForm.undertakerUser,
            tempId:tempId
        }).then(res=>{
            if(res){
                message.success('创建成功',1)
                getProjectList({
                    searchType:"0", //0全部，1已加入
                    pageNo: 0,
                    size:defaultPageSize
                })
                getProjectList({
                    searchType:"1", //0全部，1已加入
                    pageNo: 0,
                    size:defaultPageSize
                })
                setIndex('1')
            }
        }
        )
    }


    return (
        <div className='project-creat card-box'>
            <Button className='return-btn' type="dashed" onClick={()=>{setIndex('1')}}>返回</Button>
            <Row className='creat-area'>
                <Col span={6} className='creat-steps'>
                    <Steps direction="vertical" current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={
                                <p style={{display:'flex'}}>
                                    <span>{item.title}</span>
                                    <span className='item-import' hidden={item.key===2}>*</span>
                                </p>
                            }
                            />
                        ))}
                    </Steps>
                </Col>
                <Col span={2} className='project-divider'>
                    <Divider type="vertical"  className='vertical-divider-title'/>
                </Col>
                <Col span={16} className='create-content'>
                    <div className="steps-content">
                        {current === 0 && (
                            <div className='first-step-area'>
                                <Radio.Group
                                        className='first-step-radio'
                                        onChange={onChange}
                                        value={value}
                                    >
                                        <Radio value={0}>预立案</Radio>
                                        <Radio value={1}>进行中</Radio>
                                </Radio.Group>
                                <FirstStepForm projectAudit={projectAudit} teamList={teamList} firstForm={firstForm} allCustomer={allCustomer}
                                               firstFormValue={firstFormValue} curOfficeUserList={curOfficeUserList} userInfoDetail={userInfoDetail}
                                               sortText={sortText} setSortText={setSortText} setSortValue={setSortValue} sortValue={sortValue}
                                               getTeamUser={getTeamUser} teamUser={teamUser} userInfo={userInfo} customerPositionList={customerPositionList}/>
                            </div>
                        )}
                        {current === 1 && (
                           <LastStepForm firstFormValue={firstFormValue}
                                         setProjectDetail={setProjectDetail} projectDetail={projectDetail} contractFileList={contractFileList}
                                         setContractFileList={setContractFileList} sealFileList={sealFileList} setSealFileList={setSealFileList}
                                         contractFileListShow={contractFileListShow} setContractFileListShow={setContractFileListShow}
                                         sealFileListShow={sealFileListShow} setSealFileListShow={setSealFileListShow}/>
                        )}
                    </div>
                    <div className="steps-action">
                        {current === 0 && (
                            <Button type="primary" onClick={() => next()}>
                                下一步
                            </Button>
                        )}
                        {current === 1 && (
                            <Button type="primary" onClick={() => done()}>
                                完成创建
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                上一步
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
            <StartModal isModel={isModel}  setIsModel={setIsModel} projectTypeList={projectTypeList} setProjectType={setProjectType} setIsTemplateModel={setIsTemplateModel} setIndex={setIndex}/>
            <TemplateModal isTemplateModel={isTemplateModel} setIsTemplateModel={setIsTemplateModel} tempList={tempList} setTempId={setTempId} setIndex={setIndex}/>
        </div>
    )
}

function FirstStepForm(props) {
    let [tProps,setTProps] = useState({}) ;
    let [tCustomerProps,setTCustomerProps] = useState({}) ;
    let [auditValue,setAuditValue] = useState([]);
    let [customerValue,setCustomerValue] = useState([]);
    let [treeData,setTreeData] = useState([]);
    let [cusData,setCusData] = useState([]);

    const projectLevelList = ['无','低','中','高']

    const {projectAudit,teamList,firstFormValue,curOfficeUserList,firstForm,allCustomer,sortText,setSortText,sortValue,setSortValue,userInfoDetail,
        getTeamUser,teamUser,userInfo,customerPositionList} = props
    let newData = []
    let newCustomerData = []

    function onChange(value) {
        setAuditValue(value)
    };

    function onChangeCus(value) {
        setCustomerValue(value)
    };

    useEffect(()=>{
        setTProps(
            {
                treeData,
                value: auditValue,
                onChange: onChange,
                treeCheckable: true,
                showCheckedStrategy: SHOW_PARENT,
                placeholder: '请选择代理审级',
                style: {
                    width: '100%',
                },
            }
        )
    },[treeData])

    useEffect(()=>{
        setTCustomerProps(
            {
                treeData:cusData,
                value: customerValue,
                onChange: onChangeCus,
                treeCheckable: false,
                showCheckedStrategy: SHOW_PARENT,
                placeholder: '请选择客户地位',
                style: {
                    width: '100%',
                },
            }
        )
    },[cusData])

    useEffect(()=>{
        if(projectAudit){
            projectAudit.forEach((item,index) => {
                newData.push({
                        title: item.firstName,
                        value: '100'+item.firstType,
                        key: '100'+item.firstType,
                        children: item.secondList,
                        disableCheckbox:true
                    },
                );
            });
            newData.forEach((_item, _index) => {
                if (_item.children && _item.children.length) {
                    _item.children.forEach((item) => {
                        if (Object.getOwnPropertyNames(item).length) {
                            item.title = item.title??item.value;
                            item.value = item.key;
                            item.key = item.key;
                        }
                    })
                }
            });
            setTreeData(newData)
        }
    },[projectAudit])

    useEffect(()=>{
        if(customerPositionList){
            customerPositionList.forEach((item,index) => {
                newCustomerData.push({
                        title: item.firstName,
                        value: '100'+item.firstType,
                        key: '100'+item.firstType,
                        children: item.secondList,
                        disableCheckbox:true,
                        selectable:false
                    },
                );
            });
            newCustomerData.forEach((_item, _index) => {
                if (_item.children && _item.children.length) {
                    _item.children.forEach((item) => {
                        if (Object.getOwnPropertyNames(item).length) {
                            item.title = item.title??item.value;
                            item.value = item.key;
                            item.key = item.key;
                        }
                    })
                }
            });
            setCusData(newCustomerData)
        }
    },[customerPositionList])


    const formItemLayout = {
        wrapperCol: { span: 16, offset: 0},
    };
    const addItemLayout = {
        wrapperCol: { span: 16, offset: 4},
    };

    const sortMenuOnClick = (e,index)=>{
        console.log("aaaaaaaaaaaa",e.key)
        let list = []
        let listvalue = []
        sortText[index] = sortList[e.key-1]
        sortValue[index] = e.key-1
        setSortText(list.concat(sortText))
        setSortValue(listvalue.concat(sortValue))
    }

    const removeOnclick = (index)=>{
        remove(sortText,function(e,i) {
            return index == i;
        })
    }

    useEffect(()=>{
        console.log("eeeeeeeee",sortText)
    },[sortText])

    const sortMenu =(index)=> {
        return (
            <Menu onClick={(e)=>sortMenuOnClick(e,index)}>
                <Menu.Item key="1" value="0">{sortList[0]}</Menu.Item>
                <Menu.Item key="2" value="1">{sortList[1]}</Menu.Item>
                <Menu.Item key="3" value="2">{sortList[2]}</Menu.Item>
                <Menu.Item key="4" value="3">{sortList[3]}</Menu.Item>
                <Menu.Item key="5" value="4">{sortList[4]}</Menu.Item>
                <Menu.Item key="6" value="5">{sortList[5]}</Menu.Item>
                <Menu.Item key="7" value="6">{sortList[6]}</Menu.Item>
                <Menu.Item key="8" value="7">{sortList[7]}</Menu.Item>
            </Menu>
        )
    }



    return(
        <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel} style={{marginTop:'10px'}}
              ref={firstFormValue}
              initialValues={
                  {
                  ...firstForm
                  }
              }
        >
            <Form.List name="names">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...formItemLayout}
                                key={field.key}
                                label={"客户"+(index+1)}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp='children'
                                        className = 'select-item'
                                        placeholder="客户"
                                        bordered={false}
                                        dropdownRender={menu => (
                                            <div className='select-dropdown'>
                                                {menu}
                                            </div>
                                        )}
                                    >
                                        {allCustomer.map(item => (
                                            <Option key={item.id} value={item.id}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item   {...addItemLayout} >
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加客户<span style={{color:'#ff4d4f',fontSize:'14px',marginLeft:'4px'}}>*</span>
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.List name='defendants'>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...formItemLayout}
                                required={false}
                                key={field.key}
                                label={"当事人"+(index+1)}
                            >
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    noStyle
                                >
                                    <Input className='input-item' bordered={false}/>
                                </Form.Item>
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item   {...addItemLayout}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加当事人
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.List name='other'>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                {...formItemLayout}
                                required={false}
                                key={field.key}
                                label={"第三方"+(index+1)}
                            >
                                <Dropdown overlay={sortMenu(index)} placement="bottomCenter" name='type'>
                                    <Button type="text" >{sortText[index]}<DownOutlined /></Button>
                                </Dropdown>
                                <Form.Item
                                    name={[field.name, 'name']}
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         whitespace: true,
                                    //         message: "请填写当事人姓名",
                                    //     },
                                    // ]}
                                    noStyle
                                >
                                    <Input className='input-item' bordered={false}/>
                                </Form.Item>
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => {remove(field.name);removeOnclick(index)}}
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item   {...addItemLayout}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                添加第三方
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item
                name="groupId"
                label="所属团队"
                rules={[{ required: true}]}
            >
                <Select
                    showSearch
                    optionFilterProp='children'
                    className = 'select-item'
                    placeholder="所属团队"
                    bordered={false}
                    dropdownRender={menu => (
                        <div className='select-dropdown'>
                            {menu}
                        </div>
                    )}
                    onSelect={(key)=>{getTeamUser({id:key})}}
                >
                    {teamList.map(item => (
                        <Option key={item.teamId} value={item.teamId}>{item.name}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="sourceUser"
                label="案源律师"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    optionFilterProp='children'
                    className = 'select-item'
                    placeholder="律师姓名"
                    bordered={false}
                    dropdownRender={menu => (
                        <div className='select-dropdown'>
                            {menu}
                        </div>
                    )}
                >
                    {curOfficeUserList?.map(item => (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="undertakerUser"
                label="承办律师"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    optionFilterProp='children'
                    className = 'select-item'
                    placeholder="律师姓名"
                    bordered={false}
                    dropdownRender={menu => (
                        <div className='select-dropdown'>
                            {menu}
                        </div>
                    )}
                >
                    {curOfficeUserList.map(item => (
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="audit"
                label="代理审级"
            >
                <TreeSelect {...tProps} />
            </Form.Item>
            <Form.Item
                name="customerPosition"
                label="客户地位"
            >
                <TreeSelect {...tCustomerProps} />
            </Form.Item>
            <Form.Item
                name="name"
                label="项目名称"
                rules={[{ required: true }]}
            >
                <Input className='input-item' bordered={false}  placeholder="请输入项目名称" style={{textAlign:'center'}}/>
            </Form.Item>
            {/*<Form.Item*/}
            {/*    name="projectNum"*/}
            {/*    label="案号"*/}
            {/*>*/}
            {/*    <Input className='input-item' bordered={false} />*/}
            {/*</Form.Item>*/}
            <Form.Item
                name="projectLevel"
                label="重要等级"
            >
                <Select
                    className = 'select-item'
                    placeholder="重要等级"
                    bordered={false}
                    dropdownRender={menu => (
                        <div className='select-dropdown'>
                            {menu}
                        </div>
                    )}
                >
                    {projectLevelList.map((item,index) => (
                        <Option key={index} value={index}>{item}</Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="membersId"
                label="项目成员"
                rules={[{ required: true }]}
            >
                <Select
                    mode="multiple"
                    bordered={false}
                    className = 'select-item'
                    placeholder="添加项目成员"
                    dropdownRender={menu => (
                        <div className='select-dropdown'>
                            {menu}
                        </div>
                    )}
                >
                    {teamUser?.map((item) => (
                        <Option key={item.userId} value={item.userId}>{item.realName+(item.userId===userInfo.id?"(管理员)":"")}</Option>
                    ))}
                </Select>
            </Form.Item>
            {/*<div className='table-box'>*/}
            {/*<Row justify="space-between">*/}
            {/*    <Col span={6}>项目成员</Col>*/}
            {/*    <Col span={6}>级别</Col>*/}
            {/*    <Col span={6}>项目管理员</Col>*/}
            {/*</Row>*/}
            {/*<Divider style={{width:'60%'}}/>*/}
            {/*<Row justify="space-between" style={{marginBottom:'20px'}}>*/}
            {/*    <Col span={6}><Avatar size={25} src={'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'}/>{userInfoDetail?.realName??''}</Col>*/}
            {/*    <Col span={6}>高级合伙人</Col>*/}
            {/*    <Col span={6}>*/}
            {/*        <Radio disabled={false} checked={true}></Radio>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {/*</div>*/}
        </Form>
    )
}

function LastStepForm(props) {
    const {projectDetail,setProjectDetail,contractFileList,setContractFileList,sealFileList,setSealFileList,
        contractFileListShow,setContractFileListShow,sealFileListShow,setSealFileListShow} = props
    const { TextArea } = Input;


    const detailOnChange = ({ target: { value } })=>{
        setProjectDetail(value)
    }

    return(
       <div className='finance-box'>
           <div className='finance-box-title'>
               <Row justify='space-between'>
                   <Col span={3} className='title-name'>委托合同</Col>
               </Row>
           </div>
           <Divider type="horizontal" orientation="center" className='divider-title' />

           <UploadNormalBox title={"上传合同"} otherList={contractFileList} setOtherList={setContractFileList} showOtherList={contractFileListShow}
                            setShowOtherList={setContractFileListShow}/>

           <div className='finance-box-title'>
               <Row justify='space-between'>
                   <Col span={3} className='title-name'>盖章文书</Col>
               </Row>
           </div>
           <Divider type="horizontal" orientation="center" className='divider-title' />

           <UploadNormalBox title={"盖章文书"} otherList={sealFileList} setOtherList={setSealFileList} showOtherList={sealFileListShow}
                            setShowOtherList={setSealFileListShow}/>

           <div className='finance-box-title'>
               <Row justify='space-between'>
                   <Col span={3} className='title-name'>项目详情</Col>
               </Row>
           </div>
           <Divider type="horizontal" orientation="center" className='divider-title' />
           <TextArea
               rows={4}
               className='model-textArea'
               value={projectDetail}
               onChange={detailOnChange}/>
           <div style={{height:'60px'}}></div>
       </div>
    )
}

//选择项目类型弹窗
function StartModal(props){

    const {isModel,setIsModel,projectTypeList,setProjectType,setIsTemplateModel,setIndex} = props

    const handleOk = () => {
        setIsModel(false);
        setIsTemplateModel(true)
    };

    const handleCancel = (key) => {
        setProjectType(key)
        setIsModel(false);
        setIsTemplateModel(true)
    };

    return (
        <div className='content-model-start normal-tabs'>
            <Modal getContainer={false} visible={isModel} onCancel={()=>{setIndex('1')}}
                   onOk={handleOk}  width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <div className='content-model-title-name'>选择项目类型</div>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <Row className='select-box' justify='space-between'>
                        {
                            projectTypeList[0]?.secondList.map(val =>(
                                val?.key<5?
                                    <Col span={6} key={val?.key} style={{textAlign:'left'}}>
                                        <Button className='select-item' onClick={()=>handleCancel(val?.key)}>{val?.value}</Button>
                                    </Col>:
                                    <Col span={24} key={val?.key} style={{textAlign:'left'}}>
                                        <Button className='select-item' onClick={()=>handleCancel(val?.key)}>{val?.value}</Button>
                                    </Col>
                                )
                            )
                        }
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};

//选择模板弹窗
function TemplateModal(props){

    const {isTemplateModel,setIsTemplateModel,tempList,setTempId,setIndex} = props

    const imgList = [Model1_Img,Model2_Img]

    const handleOk = () => {
        setIsTemplateModel(false);
    };

    const handleCancel = (id) => {
        setTempId(id)
        setIsTemplateModel(false);
    };

    return (
        <div className='content-model-template normal-tabs'>
            <Modal getContainer={false} visible={isTemplateModel} onCancel={()=>{setIndex('1')}}
                   onOk={handleOk}  width = "45%" centered footer = {[]}>
                <div className="content-model-title">
                    <div className='content-model-title-name'>选择项目模板</div>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    {
                        tempList?.list?.map((res,index)=>(
                            <div className='template-content'>
                                <div className='template-content-show'>
                                    <button type="primary" className='template-content-show-btn' onClick={()=>handleCancel(res.id)}>使用</button>
                                </div>
                                <div className='template-content-bg'>
                                    <div className='template-content-item' style={{backgroundImage:'url('+imgList[index%2]+')'}}>
                                        <div className='template-content-item-body'>
                                            {res.name}
                                        </div>
                                    </div>
                                </div>
                                <div className='template-content-item-footer'>
                                    更新于  {res.updateTime?.replace('T',' ')}
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='template-btn-box'>
                    <button type="primary" className='template-btn' onClick={()=>handleOk()}>跳过(不使用项目模板)</button>
                </div>
            </Modal>
        </div>
    )
}
