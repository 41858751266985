import {
    apiAddProjectUser,
    apiCreateProject, apiCreateProjectApproval,
    apiGetProjectList,
    apiQueryProjectDetail, apiQueryProjectMsg, apiQueryTaskTempList,
    apiRemoveProjectUser, apiSetProjectAdmin, apiUpdateProject,apiUpdateProjectNew,apiQueryProjectDetailAj,setProjectAdminSc,apiUpdateProjectDelet
} from "../api/apis";
import React, {useState} from "react";
import {message} from "antd";


export const ProjectDetailContext = React.createContext();

export const ProjectDetailProvider = (props) => {

    const [projectDetail, setProjectDetail] = useState({});
    const [projectDetailAj, setProjectDetailAj] = useState({});
    const [isPrCreateVisible, setIsPrCreateVisible] = useState(false);
    const [isPrUpdateVisible, setIsPrUpdateVisible] = useState(false);
    const [projectMsgList, setProjectMsgList] = useState([]);
    //
    // const getProjectDetail = (params) =>{
    //     apiQueryProjectDetail(params).then((res)=>{
    //         setProjectDetail(res)
    //         }
    //     )
    // }

    async function getProjectDetail(params) {
        return  new Promise((resolve) => {
            apiQueryProjectDetail(params).then((res)=>{
                console.log(res)
                setProjectDetail(res)
                resolve(res)
            })
        })
    }
    // 进来加载数据
    async function getProjectAj(params) {
        return  new Promise((resolve) => {
            apiQueryProjectDetailAj(params).then((res)=>{
                console.log(res)
                setProjectDetailAj(res)
                // resolve(res)
            })
        })
    }



    const createProjectApproval=(params)=>{
        apiCreateProjectApproval(params).then((res) => {
            message.success('项目审批创建成功', 1).then((res)=>{})
            }
        )
    }

    async function addProjectUser(params){
        let type = false
        await apiAddProjectUser(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    async function removeProjectUser(params){
        let type = false
        await apiRemoveProjectUser(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    async function setProjectAdmin(params){
        let type = false
        await apiSetProjectAdmin(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    async function updateProject(params){
        let type = false
        await apiUpdateProject(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }
    async function updateProjectNew(params){
        let type = false
        console.log(params)
        await apiUpdateProjectNew(params).then(res=>{
            console.log(res)
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }
    async function updateProjectDelet(params){
        let type = false
        console.log(params)
        await apiUpdateProjectDelet(params).then(res=>{
            console.log(res)
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    async function setDelete(params,ida){
        console.log(params)
        setProjectAdminSc({
            id: params
        }).then(()=>{
            getProjectDetail({
                id:ida,
            })
            getProjectAj({
                id:ida,
            })
        })
    }


    const getProjectMsg = (params) => {
        apiQueryProjectMsg(params).then((res)=>{
            console.log(res)
            if(params.pageNo===0){
                setProjectMsgList(res)
            }else{
                let tempList = projectMsgList.list.concat(res.list)
                res.list = tempList
                setProjectMsgList(res)
            }
        })
    }


    return (
        <ProjectDetailContext.Provider value={{
            projectDetail,
            isPrCreateVisible,
            setIsPrCreateVisible,
            isPrUpdateVisible,
            setIsPrUpdateVisible,
            getProjectDetail,
            addProjectUser,
            removeProjectUser,
            setProjectAdmin,
            updateProject,
            updateProjectNew,
            createProjectApproval,
            projectMsgList,
            getProjectMsg,
            getProjectAj,
            projectDetailAj,
            setDelete,
            updateProjectDelet
        }}>
            {props.children}
        </ProjectDetailContext.Provider>
    )
}