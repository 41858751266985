import React, {useState} from "react";
import {
    apiCreateContract,
    apiCreateInvoice,
    apiGetContractDetail,
    apiGetContractList,
    apiGetInvoiceDetail,
    apiGetInvoiceList,
    apiQueryTask,
    queryCustomerListByProjectId,
    updateInvoiceConfirmStatus,
} from "../api/apis";

export const ProjectFinanceContext = React.createContext();

export const ProjectFinanceProvider = (props) => {
    const [contractList, setContractList] = useState([]);
    const [contractDetail, setContractDetail] = useState({});
    const [invoiceList, setInvoiceList] = useState([]);
    const [curCustomerList, setCurCustomerList] = useState([]);
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const defaultPageSize = 5;

    const getContractList = (params) =>{
        apiGetContractList(params).then((res)=>
            setContractList(res))
        }

    async function getContractDetail (params){
        let res = await apiGetContractDetail(params)
        setContractDetail(res);
    }

    async function createContract(params){
        let type = false
        await apiCreateContract(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    const getInvoiceList = (params) =>{
        apiGetInvoiceList(params).then((res)=>
            setInvoiceList(res))
    }

    async function getInvoiceDetail (params){
        let res = await apiGetInvoiceDetail(params)
        setInvoiceDetail(res)
    }

    async function createInvoice(params){
        let type = false
        await apiCreateInvoice(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    async function getCurCustomerList(params){
        let res = await queryCustomerListByProjectId(params)
        setCurCustomerList(res)

    }

    async function confirmInvoiceStatus(params){
        let type = false
        await updateInvoiceConfirmStatus(params).then(res=>{
            if(res.success){
                type = true
            }else{
                type = false
            }
        })
        return type
    }

    return (
        <ProjectFinanceContext.Provider value={{
            contractList,getContractList,
            contractDetail,getContractDetail,
            createContract,defaultPageSize,
            invoiceList,getInvoiceList,
            invoiceDetail,getInvoiceDetail,
            createInvoice,curCustomerList,getCurCustomerList,confirmInvoiceStatus
        }}>
            {props.children}
        </ProjectFinanceContext.Provider>
    )
}