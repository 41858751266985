import {UserStore} from "./UserStore";

class RootStore {
    constructor(){
        this.userStore = new UserStore(this)
    }

    getStores = () => {
        return{
            userStore : this.userStore
        }
    }

}

export const AppStore = new RootStore();