import React, {useState} from "react";
import {
    apiCreateCustomer, apiCreateTask,
    apiCustomerData,
    apiCustomerDetail,
    apiCustomerList,
    apiCustomerUpdate,
    apiOfficeCreate, apiQueryTask, apiTaskList, apiTaskStartTimer, apiTaskStopTimer, apiUpdateTask
} from "../api/apis";
import {message} from "antd";
export const WorkBenchContext = React.createContext();

/**
 * 工作台
 * @param props
 * @returns {*}
 * @constructor
 */
export const WorkBenchProvider = (props) => {
    const [isWoCreateVisible, setIsWoCreateVisible] = useState(false);
    const [isWoUpdateVisible, setIsWoUpdateVisible] = useState(false);
    const [woList, setWoList] = useState({});
    const [woDetail,setWoDetail] = useState({});
    const [timingOf,setTimingOf] = useState({});//计时中的那条记录

    // const taskCreate = (params) => {
    //     apiCreateTask(params).then((res)=>{
    //         console.log('1111',res.data)
    //         message.success('创建成功',1).then(()=>{
    //             setIsCreateVisible(false);
    //         })
    //         //刷新
    //         taskList({
    //             ownerType:0,   //0个人 1 =项目
    //             pageNo:0,
    //             size:10
    //         })
    //         // customerData({})
    //
    //     })
    // }

    const workBenchList = (params) =>{
        apiTaskList(params).then((res)=>{
            setWoList(res)
        })
    }


    // //开始计时
    // const taskStartTimer = (params) =>{
    //     apiTaskStartTimer(params).then((res)=>{
    //         setTimingOf(res)
    //         message.success('开始计时',1).then(()=>{
    //         })
    //         taskDetail({
    //             id: params.ownerId
    //         })
    //     })
    // }
    //
    // //结束计时
    // const taskStopTimer = (params) =>{
    //     apiTaskStopTimer(params).then((res)=>{
    //         message.success('计时结束',1).then(()=>{
    //         })
    //         taskDetail({
    //             id: params.ownerId
    //         })
    //     })
    // }

    //


    const taskUpdate = (params) => {
        apiUpdateTask(params).then((res)=>{
            message.success('修改成功',1).then(()=>{
            })

        })
    }


    return (
        <WorkBenchContext.Provider
            value={{
                isWoCreateVisible,
                setIsWoCreateVisible,
                isWoUpdateVisible,
                setIsWoUpdateVisible,
                woDetail,
                woList,
                timingOf,
                setWoDetail,
                workBenchList,
                // taskCreate,
                taskUpdate,
                // taskStartTimer,
                // taskStopTimer
            }}>
            {props.children}
        </WorkBenchContext.Provider>
    )
}
