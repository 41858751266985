import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import "./department.less";
import {
    Row,
    Radio,
    Col,
    Divider,
    Image,
    Menu,
    Tabs,
    Space,
    Dropdown,
    Avatar,
    message,
    Modal,
    Form,
    Button,
    Select
} from "antd";
import {IconWholeLight} from "../../constants/images";
import {
    CaretDownFilled,
    EllipsisOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import ProTable from "@ant-design/pro-table";
import {DepartmentContext} from "../../provider/department_provider";
import Input from "antd/es/input";
import {useDidMount} from "../../common/state";
import {concat, split} from "lodash";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {ROLE_ADMIN, ROLE_NORMAL, ROLE_SUPER_ADMIN} from "../../constants/names";
import Text from "antd/lib/typography/Text";

const { Option } = Select;
const { SubMenu } = Menu;
const { TabPane } = Tabs;

const projectStatusList = ["预立案",'进行中','已完结','已搁置']

export const Department = forwardRef((props, ref) => {
    const {
        teamList,
        searchTeamUserParams,setSearchTeamUserParams,
        getTeamList,getTeamUserList,
        isAddTeamVisible, setIsAddTeamVisible,
        isUpdateTeamVisible, setIsUpdateTeamVisible,
        isInviteTeamUserVisible,setIsInviteTeamUserVisible,
        getTeamUserJoinList,teamQuit,getAllGradesList,setSearchTeamProjectParams,
        searchTeamProjectParams,getTeamProjectList,setTeamUsualStatus,getFewTeamList,
        fewTeamList
    } = useContext(DepartmentContext)

    const { userInfo}  = useContext(UserInfoContext)

    const [index, setIndex] = useState(0);
    const [openFlag, setOpenFlag] = React.useState(true);
    const [openFewFlag, setOpenFewFlag] = React.useState(false);
    const [curTeam, setCurTeam] = React.useState({})
    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [id, setId] = useState(0);

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getDepartment
    }));


    useDidMount(() => {
        getDepartment()
    });

    function getDepartment(){
        getTeamList()
        getFewTeamList()
        getAllGradesList()
    }

    const quitTeam=()=>{
        teamQuit({id:id})
        setVisible(false)
    }

    const deleteTeam=()=>{
        setTeamUsualStatus({id:id,targetState:0})
        setDeleteVisible(false)
    }

    const setVisibleAndId = (id) =>{
        setVisible(true)
        setId(id)
    }

    const setDeleteVisibleAndId = (id) =>{
        setDeleteVisible(true)
        setId(id)
    }


    const  editMenu = (
        <Menu>
            <Menu.Item key="1" onClick={()=>{setIsUpdateTeamVisible(true)}}>编辑团队</Menu.Item>
            <Menu.Item key="2" onClick={()=>{
                setIsInviteTeamUserVisible(true)
                getTeamUserJoinList({
                    id:index
                })
            }}>邀请成员</Menu.Item>
            <Menu.Item key="4" onClick={()=>{setVisibleAndId(index)}}>退出团队</Menu.Item>
            <Menu.Item key="5" onClick={()=>{setDeleteVisibleAndId(index)}}>删除团队</Menu.Item>

        </Menu>
    )

    const teamUserMenu = (
        <Menu>
            <Menu.Item key="2" onClick={()=>{setVisibleAndId(index)}}>退出团队</Menu.Item>
        </Menu>
    )

    const onOpenChange= keys =>{
        if(keys.indexOf('sub1') === -1){
            setOpenFlag(false)
        }else{
            setOpenFlag(true)
        }
        if(keys.indexOf('sub2') === -1){
            setOpenFewFlag(false)
        }else{
            setOpenFewFlag(true)
        }
    };

    const menuOnClick = (e) => {
        console.log('============',e)
        setIndex(e.key)
        if (parseInt(e.key)>0){
            setSearchTeamUserParams({
                ...searchTeamUserParams,
                id:e.key,
            })
            getTeamUserList({
                ...searchTeamUserParams,
                id:e.key,
            })
            setSearchTeamProjectParams({
                ...searchTeamProjectParams,
                teamId:parseInt(e.key),
            })
            getTeamProjectList({
                ...searchTeamProjectParams,
                teamId:parseInt(e.key),
            })
        }

    };

    function onPageChange(page, pageSize) {
        setSearchTeamUserParams({
            size:pageSize,
            pageNo:page-1,
            ...searchTeamUserParams,
        })
        if(index>0){
            getTeamUserList({
                size:pageSize,
                pageNo:page-1,
                ...searchTeamUserParams,
            })
        }
    }

    return(
        <div className='department'>
            <Modal
                centered
                visible={deleteVisible}
                onOk={() => deleteTeam()}
                onCancel={() => setDeleteVisible(false)}
            >
                <p>确认删除团队吗</p>
            </Modal>
            <Modal
                centered
                visible={visible}
                onOk={() => quitTeam()}
                onCancel={() => setVisible(false)}
            >
                <p>确认退出团队吗</p>
            </Modal>
            <div className='department-box card-box normal-tabs'>
                <Row className='department-title' justify="space-between">
                    <Col style={{display:'flex',alignItems:'center'}}><Image src={IconWholeLight} width={50} preview={false} />我的部门</Col>
                    <Col><Button size='middle' type="primary"  onClick={()=>{setIsAddTeamVisible(true)}}>新建团队</Button></Col>
                </Row>
                <Divider type="horizontal" orientation="center" className='divider-title'/>
                <Row className='department-row'>
                    <Col span={6} className='department-menu-col'>
                        <Menu
                            mode="inline"
                            defaultOpenKeys={['sub1']}
                            onOpenChange={onOpenChange}
                            style={{width:256,margin: '0 auto'}}
                            onClick={(e)=>{menuOnClick(e)}}
                        >
                            <SubMenu  key="sub1" icon={!openFlag?<PlusCircleOutlined />:<MinusCircleOutlined />} title={'律师事务所'} >
                                {teamList.map((item,) =>
                                    <Menu.Item key={item.teamId} onClick={()=>setCurTeam(item)}>{item.name??''}</Menu.Item>
                                )}
                            </SubMenu>
                            <SubMenu  key="sub2" icon={!openFewFlag?<PlusCircleOutlined />:<MinusCircleOutlined />} title={'历史团队'} >
                                {fewTeamList.map((item,) =>
                                    <Menu.Item key={item.teamId} onClick={()=>setCurTeam(item)}>{item.name??''}</Menu.Item>
                                )}
                            </SubMenu>
                        </Menu>
                    </Col>
                    <Col span={1} className='department-menu-divider'>
                        <Divider type="vertical" orientation="center" className='vertical-divider-title'/>
                    </Col>
                    <Col span={17} className='department-menu-right'>
                        {(index <= 0)?
                            <div className='department-menu-right-content' style={{height:"300px"}}>
                                <div className='content-title'>
                                    <div className='content-title-name'>
                                        <div style={{display:'flex',alignItems:'center'}}><Image src={IconWholeLight} width={40} preview={false}/>{index === 0?'律师事务所':'历史团队'}</div>
                                    </div>
                                </div>
                            </div>:
                            (curTeam.onTeam===0&&userInfo.role ===ROLE_NORMAL?
                            <div style={{height:"300px"}}>您不属于该团队成员，尚无权限查看该团队信息</div>:
                            <div className='department-menu-right-content'>
                                <div className='content-title'>
                                    <div className='content-title-name'>
                                        <div style={{display:'flex',alignItems:'center'}}><Image src={IconWholeLight} width={40} preview={false}/>{curTeam.name??'律师事务所'}</div>
                                        <div>{
                                            curTeam?.usualStatus === 1 ?
                                                <Dropdown overlay={userInfo.id===curTeam.adminId?editMenu:teamUserMenu} placement="bottomCenter">
                                                    <EllipsisOutlined style={{fontSize:"30px"}}/>
                                                </Dropdown>:
                                                <div/>
                                        }

                                        </div>
                                    </div>
                                </div>
                                    <div className='content-body'>
                                        <Tabs defaultActiveKey="1" tabBarGutter={40}>
                                            <TabPane tab="成员" key="1">
                                                <TeamUserListPage onPageChange={onPageChange}
                                                                  userInfo={userInfo}
                                                                  curTeam={curTeam}
                                                                  index={index}
                                                />
                                            </TabPane>
                                            <TabPane tab="项目" key="2">
                                                <TeamProjectListPage/>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                            </div>)
                        }
                    </Col>
                </Row>
            </div>
            <AddTeamModal isAddTeamVisible={isAddTeamVisible} setIsAddTeamVisible={setIsAddTeamVisible}/>
            <UpdateTeamModal isUpdateTeamVisible={isUpdateTeamVisible} setIsUpdateTeamVisible={setIsUpdateTeamVisible}
            teamId = {index}/>
            <InviteTeamUserModal isInviteTeamUserVisible={isInviteTeamUserVisible}
                                 setIsInviteTeamUserVisible = {setIsInviteTeamUserVisible}
                                 teamId = {index}/>
        </div>
    )

});

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};

//团队成员列表
function TeamUserListPage(props) {

    const {
        teamUserList,searchTeamUserParams,teamUserDismissal,accreditAdminAuth,
    } = useContext(DepartmentContext)

    const {onPageChange,userInfo,curTeam,index} = props
    const [visible, setVisible] = useState(false);
    const [teamId, setTeamId] = useState(0);
    const [id, setId] = useState(0);

    const setVisibleAndId = (teamId,id) =>{
        setVisible(true)
        setId(id)
        setTeamId(teamId)
    }

    const deleteTeamUser = ()=>{
        teamUserDismissal({teamId:teamId,userId:id})
        setVisible(false)
    }

    const teamUserTabColumn = [
        {
            title:'成员名',
            width:200,
            dataIndex:'nickName',
            render: (dom, record) => (
                <Space>
                    <Avatar size={25} src={record.headImg}/>
                    {record.realName}
                </Space>
            ),
        },
        {
            title: '级别',
            dataIndex: 'grade',
            render: (dom, record) => (
                <span>
                    {record.lawGradeVO?.gradeName??'实习律师'}
                </span>
            ),
        },
        {
            title:'团队管理员',
            dataIndex: 'belongAdmin',
            render: (dom, record) => (
                <Radio disabled={false} checked={dom === 1} onClick = {() => {if(userInfo.id === curTeam.adminId&&curTeam.adminId!==record.userId) accreditAdminAuth({teamId : curTeam.teamId,userId: record.userId})}}/>
            ),
        },
        {
            title:'状态',
            dataIndex: 'belongOnJob',
            render: (dom, record) => (
                <Space>
                    {dom === 1?'在职':'离职'}
                </Space>
            ),
        },
        {
            title:'操作',
            valueType: 'option',
            render: (_,record) => (
                <Dropdown overlay={userInfo.id === curTeam.adminId ? <Menu>
                    <Menu.Item key="1" onClick={() => {
                        setVisibleAndId(index, record.userId)
                    }}>移除成员</Menu.Item>
                </Menu> : <div/>} placement="bottomCenter" >
                <EllipsisOutlined style={{fontSize:"20px"}}/>
                </Dropdown>
            )
        }
    ];

    const  teamUserPaginationProps = {
        showSizeChanger: true,
        pageSize:  searchTeamUserParams.size,
        total: teamUserList.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };


    return (
        <div>
            <Modal
                centered
                visible={visible}
                onOk={() => deleteTeamUser()}
                onCancel={() => setVisible(false)}
            >
                <p>确认移除该成员吗</p>
            </Modal>
            <div className='content-body-number-span'>共<span className='content-body-number'>{teamUserList.totalNum}</span>位成员</div>
            <div className='project-box'>
                <ProTable
                    columns={teamUserTabColumn}
                    dataSource={teamUserList.list}
                    rowKey={r=>r.userId}
                    pagination={teamUserPaginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
        </div>)
}

//团队项目列表
export function TeamProjectListPage(props) {

    const {
        teamProjectList,searchTeamProjectParams,getTeamProjectList
    } = useContext(DepartmentContext)

    const projectTabColumns = [
        {
            title: '名称',
            dataIndex: 'projectName',
        },
        {
            title:'状态',
            dataIndex: 'state',
            hideInSearch:true,
            render:(dom) => (
                <Text>{projectStatusList[dom]}</Text>
            ),
        },
        {
            title:'创建人',
            dataIndex: 'creatorName',
            hideInSearch:true,
        },
        {
            title:'创建时间',
            dataIndex: 'createTime',
            valueType: 'date',
            hideInSearch:true,
        }
    ];

    const  teamProjectPaginationProps = {
        showSizeChanger: true,
        defaultPageSize:10,
        // pageSize:  searchTeamProjectParams.size,
        total: teamProjectList.totalNum,
        current:teamProjectList?.pageNo+1,
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };


    return (
        <div>
            <div className='project-box'>
                <ProTable
                    columns={projectTabColumns}
                    dataSource={teamProjectList.list}
                    rowKey={r=>r.projectId}
                    pagination={teamProjectPaginationProps}
                    options={false}
                    search={{
                        defaultCollapsed: false,
                        labelWidth: 'auto',
                        optionRender: (searchConfig, formProps, dom) => [
                            ...dom.reverse(),
                        ],
                    }}
                    dateFormatter="string"
                    request={async (params, sort, filter) => {
                        const res = await getTeamProjectList({
                            pageNo: params.current-1,
                            size: params.pageSize,
                            teamId:searchTeamProjectParams.teamId,
                            searchName: params.projectName
                        })
                        return Promise.resolve({
                            data: res.list,
                            success: true,
                            total:res.totalNum
                        });
                    }}
                />
            </div>
        </div>)
}

//添加团队弹窗
function AddTeamModal(props){

    const {isAddTeamVisible, setIsAddTeamVisible} = props

    const {teamCreate} = useContext(DepartmentContext)

    const handleOk = () => {
        setIsAddTeamVisible(false);
    };

    const handleCancel = () => {
        setIsAddTeamVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        teamCreate({
            name:e.name
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isAddTeamVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>创建团队</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="团队名称"
                                name="name"
                                rules={[{ required: true, message: '请输入团队名称' }]}
                            >
                                <Input placeholder="请输入团队名称" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//编辑团队弹窗
function UpdateTeamModal(props){

    const {isUpdateTeamVisible, setIsUpdateTeamVisible,teamId} = props

    const {teamNameUpdate} = useContext(DepartmentContext)

    const handleOk = () => {
        setIsUpdateTeamVisible(false);
    };

    const handleCancel = () => {
        setIsUpdateTeamVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        teamNameUpdate({
            str:e.str,
            id:teamId
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isUpdateTeamVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>编辑团队</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="团队名称"
                                name="str"
                                rules={[{ required: true, message: '请输入团队名称' }]}
                            >
                                <Input placeholder="请输入团队名称" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//邀请团队成员弹窗
function InviteTeamUserModal(props){

    const {isInviteTeamUserVisible, setIsInviteTeamUserVisible,teamId} = props

    const {inviteJoinTeam,} = useContext(DepartmentContext)

    const [selected, setSelected] = useState([]);

    const handleOk = () => {
        setIsInviteTeamUserVisible(false);
    };

    const handleCancel = () => {
        setIsInviteTeamUserVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        inviteJoinTeam({
            userIdList:selected,
            teamId:teamId
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isInviteTeamUserVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>邀请团队成员</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="团队成员"
                                name="str"
                            >
                                <SelectTeamMember selected={selected} setSelected={setSelected}/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//邀请团队成员选择器
function SelectTeamMember(props) {

    const {teamUserJoinList} = useContext(DepartmentContext)
    const [open, setOpen] = useState(false);
    const {selected, setSelected} = props


    function handleChange(value) {
        setSelected(split(value, ",", 9999))
    }

    return (
        <div>
            <Button type="dashed"
                    icon={<PlusOutlined/>}
                    shape="circle"
                    onClick={() => {
                        setOpen(true)
                    }}
                    onChange={handleChange}/>
            <Select
                className='select-member'
                mode="multiple"
                style={{width: '100%'}}
                open={open}
                onChange={handleChange}
                onBlur={() => {
                    setOpen(false)
                }}
                bordered={false}
            >
                {
                    teamUserJoinList.map((item,) =>
                        <Option key={item.userId} disabled ={item.belongJoinTeam===1}>{item.realName}</Option>)
                }
            </Select>

        </div>
    )
}
