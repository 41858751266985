import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import "./workbench.less";
import {message, Modal, Tabs} from "antd";
import {NoneDate} from "../../common/load";
import {TaskList} from "../user/user";
import {useDidMount, useUnMount} from "../../common/state";
import {WorkBenchContext} from "../../provider/work_bench_provider";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {ROLE_SUPER_ADMIN} from "../../constants/names";
import {TaskContext} from "../../provider/task_provider";



// export function Workbench(props) {
export const Workbench = forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const { workBenchList } = useContext(WorkBenchContext)
    const { userInfo  }  = useContext(UserInfoContext)
    const [isAdministrator, setIsAdministrator] = useState(true);//是否是管理员

    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const [state, setState] = useState(0);
    const {taskUpdate} = useContext(TaskContext)

    useDidMount(() => {
        getList()
    });

    function deleteWork(){
        if(state!==2){
            taskUpdate({
                taskId:id,
                state:2
            }).then((res)=>{
                workBenchList({
                    dayType:'1',
                    pageNo:0,
                    size:10,
                    state:'0'
                })
            })
            setVisible(false)
        }else{
            message.error('目前任务为已删除状态，不可重复删除')
        }
    }

    //获取列表-统计数据
    function getList(){
        if(userInfo !== null && userInfo.role !== null){
            if(userInfo.role === ROLE_SUPER_ADMIN){
                setIsAdministrator(true)
            }else{
                setIsAdministrator(false)
                workBenchList({
                    dayType:'1',
                    pageNo:0,
                    size:10,
                    state:'0'
                })
            }
        }
    }

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getList,
    }));

    return(
        <div className="workbench">
            <div className="card-box my-thing normal-tabs">
                <Tabs defaultActiveKey="1" tabBarGutter={40}>
                    <TabPane tab="今日任务" key="1">
                        <TaskList position={0} onClick={()=>props.onBottomClick()} setId={setId} setState={setState} setVisible={setVisible}/>
                    </TabPane>
                </Tabs>
            </div>
            <Modal
                centered
                visible={visible}
                onOk={() => deleteWork()}
                onCancel={() => setVisible(false)}
            >
                <p>确认删除该任务吗</p>
            </Modal>
        </div>
    )

});
