import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Card, Checkbox, Form, Input,Modal} from "antd";
import Tabs from "antd/lib/tabs";
import './style.less'
import Image from "antd/es/image";
import {Logo_Login, qrCode} from "../../constants/images";
import {message} from "antd";
import {apiLoginBySms, sendSMSCode} from "../../api/apis";
import {UserInfoContext} from "../../provider/userInfo_provider";
import {WX_QR_TYPE_LOGIN, WxQrCode} from "./wxQrLogin";
import {browserHistory} from "../../router";


export function Login() {

    const { TabPane } = Tabs;
    const [current,setCurrent] = useState('1');

    //tab 切换
    const handleClick = (value) =>{
        setCurrent(value);
    }

    return (
        <div className='login' style={{width:document.documentElement.clientWidth,height:document.documentElement.clientHeight}} >
            <div className='login-card'>
                <Card className='card'  bordered={true} >
                    {/*<Image className='logo' src={Logo_Login} preview={false}/>*/}
                    <div className='logo_name'>律所云办公</div>
                    <Tabs  defaultActiveKey={current} onChange={handleClick.bind(this)}>
                        <TabPane style={{height:290}} tab="验证码登录" key="1">
                            <CodeLogin/>
                        </TabPane>
                        <TabPane  style={{height:290}} tab="扫码登录" key="2">
                            <WxLogin/>
                            {/* <Image className='scan-img' src={qrCode}/>
                            <p className={'scan-text'}>请使用微信扫描二维码了解系统详情</p> */}
                        </TabPane>
                    </Tabs>
                </Card>
            </div>

            <div className='background-per'
                 style={{width:document.documentElement.clientWidth*0.35,
                     height:document.documentElement.clientWidth*0.35,
            }}>
            </div>

            <div className='background-bottom'
                 style={{width:document.documentElement.clientWidth,
                     height:document.documentElement.clientWidth*364/1920,}}>
            </div>
            {/*</div>*/}
        </div>
    );
}

export function CodeLogin (){

    const formRef = React.createRef();
    const [userName,setUserName] = useState('');
    let [recaptcha,setRecaptcha] = useState('')
    const {doLogin, testLogin} = useContext(UserInfoContext)

    let timeChange;

    //获取验证码按钮
    const Btn = () => {
        const [time, setTime] = useState(60);
        const [btnDisabled, setBtnDisabled] = useState(false);
        const [btnContent, setBtnContent] = useState('获取验证码');


        useEffect(() => {
            clearInterval(timeChange);
            return () => clearInterval(timeChange);
        }, []);

        useEffect(() => {
            if (time > 0 && time < 60) {
                setBtnContent(`${time}s`);
            } else {
                clearInterval(timeChange);
                setBtnDisabled(false);
                setTime(60);
                setBtnContent('获取验证码');
            }
        }, [time]);

        const getPhoneCaptcha = () => {

            console.log('formRef',formRef)
            console.log('userName',userName)
            // 注意，不要使用 setTime(t-1) ： 闭包问题会导致time一直为59
            timeChange = setInterval(() => setTime(t => --t), 1000);
            setBtnDisabled(true);

            //暂时不使用人机校验码
            sendRegisterSmsCode('google', )
            // recaptcha.execute("register")
            //     .then(token=>{
            //         console.log("handlerClickSmsCode token,",token)
            //         sendRegisterSmsCode('google', )
            //     }, err => {
            //         console.log("handlerClickSmsCode",err)
            //         message.error('获取recaptcha失败',1);
            //     })

        };


        const sendRegisterSmsCode = (version, verifyCode) => {
            sendSMSCode({
                userName:userName,
                recaptcha: "google",
            }).then(()=>{
                if(userName){
                    message.success('验证码已发送',1,btnContent);
                }
                else{
                    message.error('请填写手机号',1);
                }
            });
        }

        return (
            <Button  type="link" className={'code-btn'}  disabled={btnDisabled} onClick={getPhoneCaptcha}>
                {btnContent}
            </Button>
        );
    };

    const onFinish = (values) => {
        doLogin(values.userName,values.verifyCode)
        };


    return(
        <div>
            <Form
                ref={formRef}
                className="login-form" onFinish={onFinish}>
                <Form.Item
                    name="userName"
                    rules={[
                        {
                            required: true,
                            // pattern: new RegExp(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/),
                            message: '请输入正确的手机号!',
                        },
                    ]}
                >
                    {/*<ReCAPTCHA ref={e => recaptcha = e}/>*/}
                    <Input
                        className="login-form-input"
                        prefix={'+86'} placeholder="请输入手机号"
                        onInput={(e)=>{setUserName(e.target.value)}}
                    />
                </Form.Item>
                <Form.Item
                    name="verifyCode"
                    rules={[
                        {
                            required: true,
                            message: '请输入正确的验证码!',
                        },
                    ]}
                >
                    <Input
                        className="login-form-input"
                        placeholder="   请输入验证码"
                        suffix={
                            <Btn />
                        }
                    />
                </Form.Item>

                <Form.Item>

                    <Button
                        type='primary'
                        htmlType="submit"
                        className="login-form-button"
                    >
                       登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

const WxLogin = () => {
    const onSuccess = () => {
        browserHistory.push("/");
    }

    const onFailed = (e) => {
        const msg = e?.response?.data?.message
        console.log("WxLogin onFailed",msg)
        Modal.info({
            title: '微信登录失败',
            content: msg,
            onOk() {
                browserHistory.push("/");
            },
        })
    }

    return (
        <div>
            <WxQrCode type={WX_QR_TYPE_LOGIN} onSuccess={onSuccess} onFailed={onFailed}/>
            {/*<p className={'scan-text'}>请使用微信扫描二维码登录 “湖北瀛楚律师事务所数字化法律服务系统”</p>*/}
            <p className={'scan-text'}>请使用微信扫描二维码登录 “律师事务所数字化法律服务系统”</p>
        </div>
    )
}





