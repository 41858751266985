import React, {useContext, useState} from "react";
import {
    Divider,
    Menu,
    Modal,
    Form,
    Dropdown,
    Button,
    Input,
} from "antd";
import {
    EllipsisOutlined, FolderOpenTwoTone,
    PictureTwoTone,
    PlusOutlined, RollbackOutlined,
} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {ProjectFileContext} from "../../provider/file_provider";
import {useDidMount} from "../../common/state";
import {UploadBox} from "../../common/upload";
import {DownLoadBox} from "../../common/download";
import {ChangeFileNameModal} from "./ChangeFileNameModal";
import {formatFileSize} from "../../common/formatFileSize";

export function ProjectDocument(props) {
    const { ownerType,officeId,taskId,projectId} = props
    const [isAddVisible, setIsAddVisible] = useState(false);
    const [parentId, setParentId] = useState(0);
    const [visible, setVisible] = useState(false);
    const [fileId, setFileId] = useState(0);
    const [file, setSelectFile] = useState({})
    const [changeNameVisible, setChangeNameVisible] = useState(false)

    const { createFolder,getProjectView,projectFileList,deleteFile}  = useContext(ProjectFileContext)

    useDidMount(()=>{
        refresh(parentId)
    })

    const setVisibleAndFildId = (fileId) =>{
        setVisible(true)
        setFileId(fileId)
    }

    const showChangeNameDialog = (file) => {
        console.log('showChangeNameDialog',file)
        setSelectFile(file)
        setChangeNameVisible(true)
    }

    const userMenuOnClick = ()=>{
        console.log("delete========",fileId)
        deleteFile({
            fileId:fileId
        }).then(()=>{
            refresh(parentId)
        })
        setVisible(false)
    }

    function selectDoc(value){
        setParentId(value)
        refresh(value)
    }

    function reBack(){
        projectFileList.pop()
        setParentId(projectFileList[projectFileList.length-1].parentId)
    }

    const refresh = (parentId) => {
        getProjectView({
            ownerType: ownerType,
            parentId: parentId==0?null:parentId,
            projectId: projectId,
            officeId: officeId,
            taskId: taskId
        })
    }


    const optionMenu =(file, isFolder,fileId)=> (
        <Menu>
            {
                isFolder?<Menu.Item key="3" onClick={()=>showChangeNameDialog(file)}>重命名</Menu.Item>:
                    <Menu.Item key="0">
                        <DownLoadBox fileId={fileId}/>
                    </Menu.Item>
            }
            <Menu.Item key="1" onClick={()=>setVisibleAndFildId(fileId)}>删除</Menu.Item>
        </Menu>
    );

    const tabColumns =[
        {
            title: '文件名',
            dataIndex: 'name',
            key: 'name',
            hideInSearch:true,
            render: (dom,record) => [
                <div style={{display:'flex',alignItem:'center'}}>
                    {
                        record.isFolder?
                            <div>
                                <FolderOpenTwoTone twoToneColor="#FFB85A" style={{fontSize:'20px',marginRight:'5px'}}/><a onClick={()=>{selectDoc(record.fileId)}}> {dom}</a>
                            </div>
                            :
                            <div>
                                <PictureTwoTone twoToneColor="#FFB85A" style={{fontSize:'20px',marginRight:'5px'}}/> {dom}
                            </div>
                    }
                </div>
            ],
        },
        {
            title: '更新人',
            dataIndex: 'creatorName',
            key: 'creatorName',
            hideInSearch:true,
        },
        {
            title: '文件大小',
            dataIndex: 'size',
            hideInSearch:true,
            render: (dom,record) =>
                <div>
                    {formatFileSize(dom??0)}
                </div>
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateTime',
            hideInSearch:true,
        },
        {
            title:'操作',
            valueType: 'option',
            render: (_,record) => [
                <Dropdown overlay={optionMenu(record, record.isFolder,record.fileId)} placement="bottomCenter">
                    <EllipsisOutlined style={{fontSize:"20px",color:'#9398A4'}}/>
                </Dropdown>
            ],
        }
    ]

    const addMenu = (
        <Menu>
            <Menu.Item key="0" onClick={()=>{setIsAddVisible(true)}}>新建文件夹</Menu.Item>
            <Menu.Item key="1">
                <UploadBox projectId={projectId} parentId={parentId} ownerType={ownerType} officeId={officeId} taskId={taskId} title={"上传文件"} pageType ={1}/>
            </Menu.Item>
        </Menu>
    )

    return(
        <div className='seal-box'>
            <AddModal isAddVisible={isAddVisible} setIsAddVisible={setIsAddVisible} parentId={parentId} createFolder={createFolder}
                      getProjectView={getProjectView} projectFileList={projectFileList} ownerType={ownerType} officeId={officeId} taskId={taskId} projectId = {projectId}/>

            <ChangeFileNameModal
                visible={changeNameVisible}
                setVisible={setChangeNameVisible}
                file={file}
                onChangeSuccess={()=>refresh(parentId)}/>
            <div className='pro-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={projectFileList[projectFileList.length-1]?.list}
                    rowKey="key"
                    options={false}
                    search={{filterType: 'light'}}
                    dateFormatter="string"
                    toolBarRender={() => [

                        parentId==0? null:<Button type="default" icon={<RollbackOutlined />} onClick={()=>{reBack()}}>上一级</Button>
                        ,
                        <Dropdown overlay={addMenu} placement="bottomCenter">
                            <Button type="primary" key="primary" icon={<PlusOutlined />}>新建</Button>
                        </Dropdown>,
                    ]}
                />
            </div>
            <Modal
                centered
                visible={visible}
                onOk={() => userMenuOnClick()}
                onCancel={() => setVisible(false)}
            >
                <p>确认删除吗</p>
            </Modal>
        </div>
    )
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};
//新建文件夹
function AddModal(props){

    const {isAddVisible, setIsAddVisible,parentId,createFolder,projectId,getProjectView,ownerType,officeId,taskId} = props

    const handleOk = () => {
        setIsAddVisible(false);
    };

    const handleCancel = () => {
        setIsAddVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        createFolder({
            officeId: officeId,
            taskId: officeId,
            fileName: e.name,
            isFolder: true,
            ownerType: ownerType,
            parentId: parentId==0?null:parentId,
            projectId: projectId,
        }).then(()=>{
            getProjectView({
                ownerType: ownerType,
                parentId: parentId==0?null:parentId,
                projectId: projectId,
                officeId: officeId,
                taskId: taskId
            })
            setIsAddVisible(false);
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isAddVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>新建文件夹</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="文件夹名称"
                                name="name"
                                rules={[{ required: true, message: '请输入文件夹名称' }]}
                            >
                                <Input placeholder="请输入文件夹名称" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

