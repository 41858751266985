import React, {useContext, useState} from "react";
import "./lawFirm.less";
import {Image, Switch, Button, Divider, Modal, Form, Upload, message} from "antd";
import ProTable from '@ant-design/pro-table';
import {
    LawFirmActiveImg
} from "../../constants/images";
import {
    LoadingOutlined,
    PlusOutlined
} from '@ant-design/icons';
import Input from "antd/es/input";
import {LawFirmContext} from "../../provider/lawFirm_provider";
import {useDidMount} from "../../common/state";

export function LawFirmPage() {

    const {isModalVisible,setIsModalVisible,SetOfficeState,lawList,getLawList,defaultPageSize,officeUpdate} = useContext(LawFirmContext)

    useDidMount(() => {
        getLawList({
            pageNo: 0,
            size: defaultPageSize
        })
    });


    /**
     * 律所状态修改
     */
    const OnSwitchChange = (e,officeId) => {
        if(e){
            SetOfficeState(officeId,0)
        }else{
            SetOfficeState(officeId,1)
        }
    };

    const tabColumns = [
        {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
        },
        {
            title: '名称',
            dataIndex: 'lawFirmName',
        },
        {
            title: '负责人',
            dataIndex: 'lawFirmPrincipal',
        },
        {
            title: '手机号',
            dataIndex: 'principalPhone',
        },
        {
            title: '员工人数',
            dataIndex: 'lawFirmPeopleNumber',
        },
        {
            title:'项目数量',
            dataIndex: 'lawFirmProjectNumber',
        },
        {
            title:'状态',
            dataIndex: 'lawFirmState',
            render:(_,record) => (
                <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={record.lawFirmState==='0'?true:false}
                        onChange={(e)=>{OnSwitchChange(e,record.officeId)}}/>
            ),
        },
        {
            title:'创建时间',
            valueType: 'dateTime',
            dataIndex: 'createDate',
        },
        {
            title:'操作',
            valueType: 'option',
            render: (_,record,index) => [<a key={index} onClick={()=>{showEditModal(record)}}>编辑</a>],
        }
    ];

    const [modelTitle, setModelTitle] = useState('');
    const [modelRecord, setmodelRecord] = useState({});
    const [isCreat, setIsCreat] = useState(true);
  

    const showModal = () => {
        setModelTitle('新增律所')
        setmodelRecord({})
        setIsModalVisible(true);
        setIsCreat(true)
    };

    const showEditModal = (record,) => {
        setModelTitle('编辑律所')
        setmodelRecord(record)
        setIsModalVisible(true);
        setIsCreat(false)
    };

    function onPageChange(current, pageSize) {
        getLawList({
            pageNo: current-1,
            size:pageSize,
        })
    }
    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: lawList.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    return (
        console.log('lawList.list',lawList.list),
        <div className="law-firm card-box">
            <div className='law-firm-title'>
                <div className='law-firm-title-left'>
                    <Image src={LawFirmActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>律所管理</span>
                </div>
                <Button type="primary" shape="round" icon={<PlusOutlined />} size='large' onClick={showModal}>新建律所</Button>
            </div>
            <div className='project-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={lawList.list}
                    rowKey="key"
                    pagination={paginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <AddCustomerModal isModalVisible = {isModalVisible} setIsModalVisible={setIsModalVisible} officeUpdate={officeUpdate}
                              modelTitle={modelTitle} modelRecord={modelRecord} isCreat={isCreat}/>
        </div>
    )
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 20 },
};

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('只能上传JPG/PNG格式的文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('图片大小必须小于2MB!');
    }
    return isJpgOrPng && isLt2M;
}


//添加客户弹窗
function AddCustomerModal(props){

    const {isModalVisible,setIsModalVisible,modelTitle,modelRecord,isCreat,officeUpdate} = props

    const [isLoading,setLoading] = useState(false)
    const [imageUrl,setImageUrl] = useState('')
    const {officeCreate} = useContext(LawFirmContext)

    const [form] = Form.useForm()
    form.setFieldsValue({
        lawName:modelRecord.lawFirmName??'',
        lawPrincipal:modelRecord.lawFirmPrincipal??'',
        lawPhone:modelRecord.principalPhone??''
    })

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        if(isCreat){
          officeCreate(e.lawPhone,e.lawPrincipal,e.lawName)
        }else{
            //编辑律所
          officeUpdate(modelRecord.officeId,e.lawPhone,e.lawPrincipal)
          setIsModalVisible(false);
        }

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    function handleChange(info){
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                {
                    setLoading(false)
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传</div>
        </div>
    );

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isModalVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>{modelTitle}</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            form={form}
                            name="basic"
                            initialValues={modelRecord}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="律所名称"
                                name="lawName"
                                rules={[{ required: true, message: '请输入律所名称' }]}
                            >
                                <Input placeholder="请输入律所名称" className='content-model-body-form-input' disabled={isCreat?false:true}/>
                            </Form.Item>
                            <Form.Item
                                label="负责人"
                                name="lawPrincipal"
                                rules={[{ required: true, message: '请输入负责人姓名' }]}
                            >
                                <Input placeholder="请输入负责人姓名" className='content-model-body-form-input'/>
                            </Form.Item>
                            <Form.Item
                                label="手机号"
                                name="lawPhone"
                                rules={[{ required: true, message: '请输入手机号码' }]}
                            >
                                <Input type='tel' placeholder="请输入手机号码" className='content-model-body-form-input'/>
                            </Form.Item>

                            {/*<Form.Item*/}
                            {/*    label="律所图标"*/}
                            {/*    name="lawHeader"*/}
                            {/*    rules={[{ required: true, message: '请选择图标' }]}*/}
                            {/*>*/}
                            {/*    <Upload*/}
                            {/*        name="lawAvatar"*/}
                            {/*        listType="picture-card"*/}
                            {/*        className="avatar-uploader content-model-body-form-input"*/}
                            {/*        showUploadList={false}*/}
                            {/*        maxCount={1} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件*/}
                            {/*        action="" //TODO 上传地址*/}
                            {/*        beforeUpload={beforeUpload}*/}
                            {/*        onChange={handleChange}*/}
                            {/*    >*/}
                            {/*        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}*/}
                            {/*    </Upload>*/}
                            {/*</Form.Item>*/}

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}