import React, {useContext, useState} from "react";
import "./customerManagement.less";
import {Image, Button, Form, Row, Col, Select, DatePicker, Modal, message} from "antd";
import ProTable from '@ant-design/pro-table';
import {CustomerActiveImg} from "../../constants/images";
import {LawFirmContext} from "../../provider/lawFirm_provider";
import {CustomerManagementContext} from "../../provider/customer_manager_provider";
import {useDidMount} from "../../common/state";
import Text from "antd/lib/typography/Text";
import ExportJsonExcel from "js-export-excel";
const { Option } = Select;
const { RangePicker } = DatePicker;

export function CustomerManagementPage() {
    const {customerManageList,getCustomerManagementList,defaultPageSize} = useContext(CustomerManagementContext)
    const {allLawList,getAllLawList} = useContext(LawFirmContext)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const formSearchRef =React.createRef();

    useDidMount(() => {
        getAllLawList({
            pageNo: 0,
            size:100
        })
        getCustomerManagementList(
            {
                pageNo: 0,
                size: defaultPageSize
            }
        )
        getList()
    })

    const getList = (value) => {
        let searchValue = formSearchRef.current.getFieldsValue();
        getCustomerManagementList({
            grade: searchValue.grade,
            officeId: searchValue.lawName,
            type: searchValue.type,
            createStartAt:searchValue.time === undefined ? undefined : searchValue.time[0],
            createEndAt:searchValue.time === undefined ? undefined : searchValue.time[1],
            pageNo: value?.current??0,
            size: value?.size??defaultPageSize
        })
    }

    function onPageChange(page, size) {
        getList({
            current:page-1,
            size:size
        })
    }

    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: customerManageList?.totalNum,
        onChange:(page,size)=>onPageChange(page,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    //重置
    const handleFormReset =()=>{
        formSearchRef.current.resetFields();
        getList()
    };

    //查询
    const handleSearch =(e)=>{
        getList()
    };

    const showModal = ()=>{
        getList({
            page:0,
            size:0x7fffffff
        })
        setIsModalVisible(true)
    }

    const handleCancel=()=>{
        message.error('取消导出')
        setIsModalVisible(false)
    }


    const tabColumns = [
        {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
        },
        {
            title: '客户名称',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '客户类型',
            key: 'type',
            dataIndex: 'type',
            render:(dom) => (
                <Text>{dom===1?'机构':'自然人'}</Text>
            ),
        },
        {
            title: '联系电话',
            key:'phone',
            dataIndex: 'phone',
        },
        {
            title: '所属律所',
            key:'officeName',
            dataIndex: 'officeName',
        },
        {
            title: '添加律师电话',
            key: 'creatorName',
            dataIndex: 'creatorName',
        },
        {
            title: '添加律师姓名',
            key: 'creatorRealName',
            dataIndex: 'creatorRealName',
        },
        {
            title:'添加时间',
            valueType: 'dateTime',
            key: 'createAt',
            dataIndex: 'createAt',
        },
    ];

    //确认导出
    const checkOut = () =>{
        message.success('确认导出');
        setIsModalVisible(false)
        const data = customerManageList.list;//表格数据
        var option={};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if(data){
                    let obj = {
                        '客户名称': data[i].name??'/',
                        '客户类型': data[i].type===1?'机构':'自然人',
                        '联系电话': data[i].phone??'/',
                        '所属律所': data[i].officeName??'/',
                        '添加律师': data[i].creatorName??'/',
                        '添加时间': data[i].createAt.replace('T',' '),
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = '客户信息'
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                sheetFilter:['客户名称','客户类型','联系电话', '所属律所', '添加律师','添加时间'],
                sheetHeader:['客户名称','客户类型','联系电话', '所属律所', '添加律师','添加时间'],
            }
        ];

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    const renderSimpleForm =()=> {
        return (
            <Form
                ref={formSearchRef}
                onFinish={handleSearch.bind(this)}
                layout="inline">
                <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{width:'100%'}}>
                    <Col md={7} sm={24}>
                        <Form.Item label="所属律所" name="lawName">
                            <Select placeholder="请选择" allowClear>
                                {
                                    allLawList?.list?.map((item)=>
                                        <Option value={item.officeId} key={item.officeId}>{item.officeName}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={7} sm={24}>
                        <Form.Item label="客户类型" name="type">
                            <Select placeholder="请选择" allowClear>
                                <Option value="1">机构</Option>
                                <Option value="2">自然人</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={10} sm={36}>
                        <Form.Item label="添加时间" name="time">
                            <RangePicker
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24}>
                     <span
                         style={{float:"right"}}
                     >
                      <Button type="primary" htmlType="submit">
                        查询
                        </Button>
                      <Button style={{ marginLeft: 8 }} onClick={handleFormReset} htmlType="reset">重置</Button>
                     </span>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <div className="customer-management card-box">
            <div className='customer-management-title'>
                <div className='customer-management-title-left'>
                    <Image src={CustomerActiveImg} preview={false} className='tab-img' width={35}/>
                    <span>客户管理</span>
                </div>
                <Button type="primary" shape="round" size='large' onClick={()=>{showModal()}}>导出表格</Button>
            </div>
            {/*<Divider type="horizontal" orientation="center" className='divider-title'/>*/}

            <div className={'tableListForm'}>{renderSimpleForm()}</div>
            <div className='project-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={customerManageList.list}
                    rowKey={r => (r.id)}
                    pagination={paginationProps}
                    options={false}
                    search={false}
                    dateFormatter="string"
                />
            </div>
            <Modal title="导出表格" visible={isModalVisible} onOk={checkOut} onCancel={handleCancel}>
                <p>确认导出表格？</p>
            </Modal>
        </div>
    )
}

