import React, {useContext, useState} from "react";
import {
    Menu,
    Dropdown,
    Button,
    Space, Modal,
} from "antd";
import {EllipsisOutlined, LinkOutlined,} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {TaskList} from "../user/user";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {TaskContext} from "../../provider/task_provider";
import {ProjectListContext} from "../../provider/project_list_provider";

export function ProjectDetailTask() {

    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const  {setIsPrCreateVisible , setIsPrUpdateVisible,projectDetail} = useContext(ProjectDetailContext)
    const {queryProjectBySelf} = useContext(ProjectListContext)
    const {
        taskDetail,
        listByProjectId,
        projectTaskList,
        defaultPageSize,
        taskUpdateProjectDetail,
        queryMembersByProject
    } = useContext(TaskContext)

    function itemOnclick(value) {
        queryProjectBySelf()
        queryMembersByProject({
            id:projectDetail.id
        })
        taskDetail({id: value}).then(()=>{
            setIsPrUpdateVisible(true)
        })
    }

    function deleteOnclick() {
        taskUpdateProjectDetail({
            taskId: id,
            state:2
        }).then(()=>{
            listByProjectId({
                id: projectDetail.id,
                auditState: "",
                searchName: "",
                pageNo: 0,
                size: defaultPageSize
            })
        })
        setVisible(false)
    }

    const setVisibleAndId = (id) =>{
        setVisible(true)
        setId(id)
    }

    const optionMenu = (id,state) => (
        <Menu>
            <Menu.Item key="1" onClick={()=>itemOnclick(id)}>任务详情</Menu.Item>
            {
                state==2?'':  <Menu.Item key="2" onClick={()=>setVisibleAndId(id)}>删除任务</Menu.Item>
            }

        </Menu>
    );

    const tabColumns =[
        {
            title: '任务名称',
            dataIndex: 'title',
            key: 'title',
            render: (dom,record) => (
                <a onClick={()=>itemOnclick(record.id)}>
                    {dom}
                </a>
            ),
        },
        {
            title: '文件数量',
            dataIndex: 'fileNum',
            hideInSearch:true,
            render: (dom) => (
                <div>
                    <LinkOutlined /> {dom}
                </div>
            ),
        },
        {
            title: '创建日期',
            dataIndex: 'createTime',
            valueType:'dateTime',
            hideInSearch:true,
        },
        {
            title: '负责人',
            dataIndex: 'realName',
            hideInSearch: true,
            render: (dom) => (
                <Space>
                   {dom}
                </Space>
            ),
        },
        {
            title: '状态',
            dataIndex: 'state',
            valueType: 'select',
            valueEnum: {
                0: { text: '未完成', status: 'Default' },
                1: { text: '已完成', status: 'Processing' },
                2: { text: '已删除', status: 'Error' },
            },
        },
        {
            title:'操作',
            valueType: 'option',
            render: (_,record) => [
                <Dropdown overlay={optionMenu(record.id,record.state)} placement="bottomCenter">
                    <EllipsisOutlined style={{fontSize:"20px",color:'#9398A4'}}/>
                </Dropdown>
            ],
        }
    ]


    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: projectTaskList?.totalNum,
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    const addFun=()=>{
        setIsPrCreateVisible(true);
        queryProjectBySelf();
        queryMembersByProject({id:projectDetail.id})
    }

    return(
        <div className='seal-box'>
            <div className='pro-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={projectTaskList.list}
                    rowKey="key"
                    pagination = {paginationProps}
                    options={false}
                    search={{
                        defaultCollapsed: false,
                        labelWidth: 'auto',
                        optionRender: (searchConfig, formProps, dom)=>[
                            ...dom.reverse(),
                            <Button key="out" onClick={()=>{addFun()}}>新建任务</Button>
                        ],
                    }}
                    request={async (params) => {
                        const res = await listByProjectId({
                            id: projectDetail.id,
                            auditState: params.state,
                            searchName: params.title,
                            pageNo: params.current-1,
                            size: params.pageSize
                        });
                        return Promise.resolve({
                            data: res.list,
                            success: true,
                            total:res.totalNum
                        });
                    }}
                    dateFormatter="string"
                />
            </div>
            <Modal
                centered
                visible={visible}
                onOk={() => deleteOnclick()}
                onCancel={() => setVisible(false)}
            >
                <p>确认删除吗</p>
            </Modal>
            <TaskList position={2}/>
        </div>
    )
}