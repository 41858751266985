import {useDidMount} from "../../common/state";
import {Col, Input, Divider, Row, Space, Tag, Image, message} from "antd";
import ProTable from "@ant-design/pro-table";
import React, {useContext, useEffect, useState} from "react";
import {ConflictContext} from "../../provider/conflict_provider";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {SearchImg} from "../../constants/images";
import "./conflict.less";
import {NoneDate} from "../../common/load";

export function Conflict() {
    const projectStatusList = ["预立案",'进行中','已完结','已搁置']
    const projectLevelList = ["无",'低','中','高']
    const { getProjectConflictList,projectConflictList,defaultPageSize,getCustomerConflictList,customerConflictList} = useContext(ConflictContext)
    const {projectTypeList,getProjectTypeList} = useContext(ProjectCreatContext)
    const [ projectTypeMap, setProjectTypeMap] = useState([]);
    const [ searchValue, setSearchValue] = useState([]);

    useDidMount(()=>{
        getProjectTypeList()
    })

    useEffect(()=>{
        let list = []
        projectTypeList.map((item)=>{
            list = list.concat(item.secondList)
        })
        setProjectTypeMap(list)
    },[projectTypeList])

    function customerOnPageChange(page, size) {
        //客户查询利冲
        getCustomerConflictList({
            pageNo: page,
            searchName: searchValue,
            size: size
        })
    }

    const  customerPaginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: customerConflictList?.totalNum,
        onChange:(page,size)=>customerOnPageChange(page-1,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    function projectOnPageChange(page, size) {
        //项目查询利冲
        getProjectConflictList({
            pageNo: page,
            searchName: searchValue,
            size: size
        })
    }

    const  projectPaginationProps = {
        showSizeChanger: true,
        defaultPageSize:  defaultPageSize,
        total: projectConflictList?.totalNum,
        onChange:(page,size)=>projectOnPageChange(page-1,size),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    const customerTabColumns = [
        {
            title:'客户名称',
            key:'customerList',
            dataIndex:'customerList',
            render: (dom) => (
                <div>
                    {
                        dom.map((item,index)=>
                            <span key={index}>{item.customerName}</span>
                        )
                    }
                </div>
            )
        },
        {
            title:'项目名称',
            key:'projectName',
            dataIndex:'projectName',
        },
        {
            title:'案源律所',
            key:'sourceOffice',
            dataIndex:'sourceOffice',
        },
        {
            title: '项目类型',
            key: 'caseType',
            dataIndex: 'caseType',
            render: (dom) => (
                <div>{projectTypeMap[dom-1]?.value}</div>
            )
        },
        {
            title: '项目状态',
            key: 'state',
            dataIndex: 'state',
            render: (dom) => (
                <div>{projectStatusList[dom]}</div>
            )
        },
        {
            title: '负责人',
            key: 'adminName',
            dataIndex: 'adminName',
        },
        {
            title: '案号',
            key: 'projectNum',
            dataIndex: 'projectNum',
        },
        {
            title: '立案时间',
            key: 'createTime',
            dataIndex: 'createTime',
            valueType:'date',
        },
        {
            title:'项目等级',
            key: 'projectLevel',
            dataIndex: 'projectLevel',
            render: (dom) => (
                <div>
                    {projectLevelList[dom]}
                </div>
            ),
        },
    ];

    const projectTabColumns = [
        {
            title:'项目名称',
            key:'projectName',
            dataIndex:'projectName',
        },
        {
            title:'案源律所',
            key:'sourceOffice',
            dataIndex:'sourceOffice',
        },
        {
            title: '项目类型',
            key: 'caseType',
            dataIndex: 'caseType',
            render: (dom) => (
                <div>{projectTypeMap[dom-1]?.value}</div>
            )
        },
        {
            title: '项目状态',
            key: 'state',
            dataIndex: 'state',
            render: (dom) => (
                <div>{projectStatusList[dom]}</div>
            )
        },
        {
            title: '负责人',
            key: 'adminName',
            dataIndex: 'adminName',
        },
        {
            title: '案号',
            key: 'projectNum',
            dataIndex: 'projectNum',
        },
        {
            title: '立案时间',
            key: 'createTime',
            dataIndex: 'createTime',
            valueType:'date',
        },
        {
            title:'项目等级',
            key: 'projectLevel',
            dataIndex: 'projectLevel',
            render: (dom) => (
                <div>
                    {projectLevelList[dom]}
                </div>
            ),
        },
    ];

    function setValue(e){
        setSearchValue(e.target.value)
    }

    function search(){
        if(searchValue!=""){
            getCustomerConflictList({
                pageNo: 0,
                searchName: searchValue,
                size: defaultPageSize
            })
            getProjectConflictList({
                pageNo: 0,
                searchName: searchValue,
                size: defaultPageSize
            })
        }else{
            message.error("搜索内容不能为空")
        }
    }

    return(
        <div className='conflict-box card-box'>
            <Row justify="center"  align="middle">
                <Col span={7} className='col-title'>
                    <Input size="large" placeholder="请输入您想要搜索的客户或项目名" className='title-input'
                           onChange={setValue}
                           value={searchValue}
                           onPressEnter={()=>search()}
                           suffix={
                               <Image src={SearchImg} preview={false} width={20} onClick={()=>search()}/>
                           }
                    />
                </Col>
            </Row>
            {
                (projectConflictList?.totalNum??0)===0 && (customerConflictList?.totalNum??0)===0 ?
                    <div style={{marginTop:'40px'}}>
                        <NoneDate />
                    </div>
                    :
                    <div>
                    <div className='finance-box-title'>
                        <Row justify='start'>
                            <Col span={2} className='title-name'>客户名冲突</Col>
                        </Row>
                    </div>
                    <Divider type="horizontal" orientation="center" className='divider-title' />
                    <div className='pro-box'>
                        <ProTable
                            columns={customerTabColumns}
                            dataSource={customerConflictList.list}
                            rowKey={r => r.projectId}
                            pagination={customerPaginationProps}
                            options={false}
                            search={false}
                            dateFormatter="string"
                        />
                    </div>
                    <div className='finance-box-title'>
                        <Row justify='start'>
                            <Col span={2} className='title-name'>项目名冲突</Col>
                        </Row>
                    </div>
                    <Divider type="horizontal" orientation="center" className='divider-title'/>
                    <div className='pro-box'>
                        <ProTable
                            columns={projectTabColumns}
                            dataSource={projectConflictList.list}
                            rowKey={r => r.projectId}
                            pagination={projectPaginationProps}
                            options={false}
                            search={false}
                            dateFormatter="string"
                        />
                    </div>
                </div>
            }
        </div>
    )

}