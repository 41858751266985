import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import "./project.less";
import {ProjectList} from "./projectList";
import {ProjectDetail} from "./projectDetail";
import {ProjectCreat} from "./projectCreat";
import {ProjectCreatContext} from "../../provider/project_creat_provider";
import {ProjectListContext} from "../../provider/project_list_provider";
import {ProjectDetailContext} from "../../provider/project_detail_provider";

export const ProjectPage = forwardRef((props, ref) => {
    const [ index, setIndex] = useState("1");

    const {getProjectTypeList} = useContext(ProjectCreatContext)

    const {getProjectList,defaultPageSize} = useContext(ProjectListContext)

    const {projectDetail} = useContext(ProjectDetailContext)

    const {getProjectDetail} = useContext(ProjectDetailContext)

    const [ cateText, setCateText] = useState("全部");
    const [ cateValue, setCateValue] = useState([]);
    const [inputValue,setInputValue] = useState("");
    const [ cateSearch, setCateSearch] = useState("项目名称");
    const [ cateSearchValue, setCateSearchValue] = useState(0);

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: index==="1"?getList:(index==="2"?getDetail:function (){})
    }));

    function getList(){
        getProjectTypeList()
        getProjectList({
            searchName:'',
            stateList: [],
            searchType:"1", //1已加入
            sourceUserName:'',
            pageNo: 0,
            size:defaultPageSize
        })
        setCateText('全部')
        setCateValue([])
        setInputValue('')
        setCateSearch("项目名称")
        setCateSearchValue(0)
    }

    function getDetail(){
        getProjectDetail({
            id:projectDetail.id,
        })
    
    }

    return index==="1"?(
        <ProjectList setIndex={setIndex} cateText={cateText} setCateText={setCateText} cateValue={cateValue} setCateValue={setCateValue} inputValue={inputValue}
                     setInputValue={setInputValue} cateSearch={cateSearch} setCateSearch={setCateSearch} cateSearchValue={cateSearchValue} setCateSearchValue={setCateSearchValue}/>
    ):(
        index==='2'?(
            <ProjectDetail setIndex={setIndex}/>
            ):(
            <ProjectCreat setIndex={setIndex}/>
        )

    )
})