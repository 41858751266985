import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import Image from "antd/es/image";
import Menu from "antd/es/menu";
import Col from "antd/es/grid/col";
import Divider from "antd/es/divider";
import Row from "antd/es/grid/row";
import {
    IconWholeLight,
    IconWholeDark,
    IconInstitutionLight,
    IconInstitutionDark,
    IconPersonLight,
    IconPersonDark,
    IconServiceLight,
    IconServiceDark,
    SearchImg,
    IconHeadImgDark,
} from "../../constants/images";
import { RightOutlined} from "@ant-design/icons";
import Input from "antd/es/input";
import {Button, Modal, List, Tabs, Form} from "antd";
import './customer.less'
import {CustomerContext} from "../../provider/customer_provider";
import {useDidMount} from "../../common/state";
import ProTable,{ActionType} from "@ant-design/pro-table";




export const CustomerPage = forwardRef((props, ref) => {

    const {customerList,customerData,searchParams,setSearchParams} = useContext(CustomerContext);
    const [index, setIndex] = useState('0');
    const [pageSize, setPageSize] = useState(10);

    useDidMount(() => {
        getList()
    });

    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getList,
    }));

    //获取列表-统计数据
    const getList =()=>{
        customerData({})
        customerList({
            size:pageSize,
            pageNo:0,
            searchName:'',
            searchType:0,
        })
        setSearchParams({searchType:undefined , pageNo:0, size:pageSize,searchName:''})
        setIndex('0')
    }

    const callback = ( {key} ) => {
        setIndex(key)
        customerList({
            size:pageSize,
            pageNo:0,
            searchType:key ,
        })
        setSearchParams({searchType:key , pageNo:searchParams.pageNo, size:searchParams.size,searchName:''})
    };


    return (
        <div className="customer-all card-box">
            <Row justify="center">
                <Col span={6}>
                    <CustomerMenu index = {index} callback ={callback}/>
                </Col>
                <Col span={2} className='col-divider'>
                    <Divider type="vertical" orientation="center" className='divider-title'/>
                </Col>
                <Col span={16} className = 'content-right'>
                    <CustomerSearchDataList setIndex={setIndex} setPageSize={setPageSize}/>
                </Col>
            </Row>
        </div>
    );


});

//左侧目录
function CustomerMenu(props){

    const {index,callback} = props
    const {cuData} = useContext(CustomerContext)
    const menuList = [
        {
            key:0,
            title:'全部',
            num:cuData.wholeNum??0,
            img:index==="0"?IconWholeLight:IconWholeDark,
        },
        {
            key:1,
            title:'机构',
            num:cuData.institutionNum??0,
            img:index==="1"?IconInstitutionLight:IconInstitutionDark
        },
        {
            key:2,
            title:'自然人',
            num:cuData.personNum??0,
            img: index==="2"?IconPersonLight:IconPersonDark,
        },
        {
            key:3,
            title:'正在服务',
            num: cuData.processNum??0,
            img: index==="3"?IconServiceLight:IconServiceDark
        },
    ];

    return (
        <Menu
            defaultOpenKeys={[]}
            mode="vertical"
            theme="light"
            className = "customer-menu"
            onClick={callback}
            selectedKeys={[index]}
        >
            {menuList.map((item,) =>
                <Menu.Item key={item.key}>
                    <CustomerMenuItem
                        isActive ={item.key.toString() === index}
                        menuTitle ={item.title}
                        menuNum = {item.num}
                        img={item.img}
                    />
                </Menu.Item>
            )}
        </Menu>
    )
}

//目录item
function CustomerMenuItem(props){

    const {isActive,menuTitle,menuNum,img} = props

    return (
            <div className="menu-item-background">
                <Row justify="space-around"  align="middle">
                    <Col span = {6} className='img-box'>
                        <Image
                            src={img}
                            preview={false}
                            width = {33}
                        />
                    </Col>
                    <Col span = {10} className ={isActive?"text-active":"text-grey"}>{menuTitle}</Col>
                    <Col span = {5} className ={isActive?"text-active":"text-grey"}>{menuNum}</Col>
                    <Col span = {3}><RightOutlined className ={isActive?"text-active":"text-grey"}/></Col>
                </Row>
            </div>
    )
}

//右侧搜索分页列表
 function CustomerSearchDataList (props) {

    const {setIndex,setPageSize} = props
    const {isModalVisible, setIsModalVisible,setSearchParams,customerList,searchParams,cuList,customerDetail} = useContext(CustomerContext)


     function handleUpdate(id) {
         customerDetail({
             id:id,
         })

     }

    const showModal = () => {
        setIsModalVisible(true);
    };

     const tabColumns =[
         {
             title: '名称',
             dataIndex: 'name',
             key: 'name',
             render: (dom,record) => (
                 <div style={{display:'flex',alignItems:'center'}}>
                     <Image
                         //客户类型，1-机构， 2-自然人
                         src={record.type===2?IconHeadImgDark:IconInstitutionDark}
                         preview={false}
                         width = {33}
                     />
                     <a onClick={()=>handleUpdate(record.customerId)}>
                         {dom}
                     </a>
                 </div>
             ),
         },
         {
             title: '电话',
             dataIndex: 'phone',
             key: 'phone',
             hideInSearch:true,
         },
         {
             title: '邮箱',
             dataIndex: 'mail',
             key: 'mail',
             hideInSearch:true,
         },
         {
             title: '创建人',
             dataIndex: 'creatorName',
             key:'creatorName',
             hideInSearch:true,
         }
     ]

     function onPageChange(size) {
         setPageSize(size)
     }

     let paginationProps = {
         showSizeChanger: true,
         defaultPageSize:10,
         onShowSizeChange:(current,size)=>onPageChange(size),
         total: cuList.totalNum,
         current:cuList?.pageNo+1,
         showTotal:(total) => `共 ${total} 条`,
         pageSizeOptions:[5,10,20,100]
     };

    return (
        <div className='cuslist-box'>
            <div className='pro-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={cuList.list}
                    rowKey="key"
                    pagination={paginationProps}
                    options={false}
                    search={{
                        defaultCollapsed: false,
                        labelWidth: 'auto',
                        optionRender: (searchConfig, formProps, dom) => [
                            ...dom.reverse(),
                            <Button key="out" onClick={showModal}>新建客户</Button>,
                        ],
                    }}
                    dateFormatter="string"
                    request={async (params, sort, filter) => {
                        const res = await  customerList({
                            size:params.pageSize,
                            pageNo:params.current-1,
                            searchName:params.name,
                            searchType:searchParams.searchType,
                        })
                        return Promise.resolve({
                            data: res.list,
                            success: true,
                            total:res.totalNum
                        });

                    }}
                />
            </div>
            <AddCustomerModal isModalVisible = {isModalVisible} setIsModalVisible={setIsModalVisible} setIndex={setIndex}/>
        </div>
    )
}

//添加客户弹窗
function AddCustomerModal(props){

    const {isModalVisible,setIsModalVisible,setIndex} = props
    const {customerCreate,customerUpdate,cuDetail,setCuDetail} = useContext(CustomerContext)

    const { TabPane } = Tabs;

    const [indexTab, setIndexTab] = useState("1");

    const callbackTab = ( key ) => {
        setIndexTab(key)
    };


    const handleCancel = () => {
        setCuDetail({})
        setIsModalVisible(false);
    };

    //提交
    function onNaturalFormFinish(e) {
        const params ={
            customerId:cuDetail.customerId,
            type:indexTab,
            name:e.name,
            belong:e.belong,
            short:e.short,
            phone:e.phone,
            secondPhone:e.secondPhone,
            mail:e.mail,
            address:e.address,
            identity:e.identity,
            registerDate:e.registerDate,
            legalPerson:e.legalPerson,
            bankName:e.bankName,
            bankCardNumber:e.bankCardNumber
        }
        if(cuDetail.customerId){
            customerUpdate(params)
        }else {
            customerCreate(params)
            setIndex("0")
        }

    }

    function renderFormBtn(){
        return(
            <div className = "add-customer-button">
                <Button key="back" onClick={handleCancel} className = "customer-button-cancel">
                    取消
                </Button>
                <Button htmlType="submit" key="submit" type="primary" className = "customer-button-ok">
                    确定
                </Button>
            </div>
        )
    }

    const addCustomerForm=() =>{

        return(
            <div className = "add-customer-form">
                <Form
                    name="natural"
                    initialValues={
                        {
                            name: cuDetail.name === undefined ? '': cuDetail.name,
                            belong: cuDetail.belong === undefined ? '': cuDetail.belong,
                            short: cuDetail.short === undefined ? '': cuDetail.short,
                            phone: cuDetail.phone === undefined ? '': cuDetail.phone,
                            phoneRemark: cuDetail.phoneRemark === undefined ? '': cuDetail.phoneRemark,
                            secondPhone: cuDetail.secondPhone === undefined ? '': cuDetail.secondPhone,
                            secondPhoneRemark: cuDetail.name === undefined ? '': cuDetail.secondPhoneRemark,
                            mail: cuDetail.mail === undefined ? '': cuDetail.mail,
                            mailRemark: cuDetail.mailRemark === undefined ? '': cuDetail.mailRemark,
                            address: cuDetail.address === undefined ? '': cuDetail.address,
                            identity: cuDetail.identity === undefined ? '': cuDetail.identity,
                            identityRemark: cuDetail.identityRemark === undefined ? '': cuDetail.identityRemark,
                            addressRemark: cuDetail.addressRemark === undefined ? '': cuDetail.addressRemark,
                            legalPerson: cuDetail.legalPerson === undefined ? '': cuDetail.legalPerson,
                            legalPersonTitle: cuDetail.legalPersonTitle === undefined ? '': cuDetail.legalPersonTitle,
                            bankName:cuDetail.bankName ===undefined? '': cuDetail.bankName,
                            bankCardNumber:cuDetail.bankCardNumber ===undefined? '': cuDetail.bankCardNumber,

                        }
                    }
                    onFinish={onNaturalFormFinish}
                >
                    <Row className='model-row'>
                        <Col span={24}>
                            <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>{cuDetail.type === undefined ?(indexTab ==="1" ? "机构名称":"客户名称") :(cuDetail.type  === 1 ? "机构名称":"客户名称")}</div>
                            <Form.Item
                                name="name"
                                rules={[{ required: true ,message:'名称为必填字段'}]}
                            >
                                <Input placeholder="请填写名称"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='model-row'>
                        <Col span={24}>
                            <div className='project-input-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>{cuDetail.type === undefined ?(indexTab ==="1" ? "统一社会信用代码":"身份证号") :(cuDetail.type  === 1 ? "统一社会信用代码":"身份证号")}</div>
                            <Form.Item
                                name="identity"
                                rules={[{ required: true ,message:'证件为必填字段'}]}
                            >
                                <Input placeholder="请填写证件号"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        cuDetail.type === undefined ?(indexTab ==="1" ?
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>开户行</div>
                                    <Form.Item
                                        name="bankName"
                                    >
                                        <Input placeholder="请填写开户行"/>
                                    </Form.Item>
                                </Col>
                            </Row>:"") :
                            (cuDetail.type  === 1 ?   <Row className='model-row'>
                            <Col span={24}>
                                <div className='project-input-title'>开户行</div>
                                <Form.Item
                                    name="bankName"
                                >
                                    <Input placeholder="请填写开户行"/>
                                </Form.Item>
                            </Col>
                        </Row>:"")
                    }
                    {
                        cuDetail.type === undefined ?(indexTab ==="1" ?
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>银行卡号</div>
                                    <Form.Item
                                        name="bankCardNumber"
                                    >
                                        <Input placeholder="请填写银行卡号"/>
                                    </Form.Item>
                                </Col>
                            </Row>:"") :
                            (cuDetail.type  === 1 ?   <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>银行卡号</div>
                                    <Form.Item
                                        name="bankCardNumber"
                                    >
                                        <Input placeholder="请填写银行卡号"/>
                                    </Form.Item>
                                </Col>
                            </Row>:"")
                    }
                    <Row className='model-row'>
                        <Col span={11}>
                            <div className='project-input-title'>
                                <span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>
                                手机号</div>
                            <Form.Item
                                name="phone"
                                rules={[{ required: true ,message:'手机号为必填字段'}]}
                            >
                                <Input placeholder="请填写手机号"/>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className='project-input-title'>座机号</div>
                            <Form.Item
                                name="secondPhone"
                            >
                                <Input placeholder="请填写座机号"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='model-row'>
                        <Col span={11}>
                            <div className='project-input-title'>所属</div>
                            <Form.Item
                                name="belong"
                            >
                                <Input placeholder="请填写所属"/>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className='project-input-title'>简称</div>
                            <Form.Item
                                name="short"
                            >
                                <Input placeholder="请填写简称"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='model-row'>
                        <Col span={11}>
                            <div className='project-input-title'>邮箱</div>
                            <Form.Item
                                name="mail"
                            >
                                <Input placeholder="请填写邮箱"/>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <div className='project-input-title'>地址</div>
                            <Form.Item
                                name="address"
                            >
                                <Input placeholder="请填写地址"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        cuDetail.type === undefined ?(indexTab ==="1" ?
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>法定代表人</div>
                                    <Form.Item
                                        name="legalPerson"
                                    >
                                        <Input placeholder="请填写法定代表人"/>
                                    </Form.Item>
                                </Col>
                            </Row>:"") :
                            (cuDetail.type  === 1 ?   <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>法定代表人</div>
                                    <Form.Item
                                        name="legalPerson"
                                    >
                                        <Input placeholder="请填写法定代表人"/>
                                    </Form.Item>
                                </Col>
                            </Row>:"")
                    }
                    {renderFormBtn()}
                </Form>
            </div>
        );
    };

    return (<div className='content-model' >
        <Modal getContainer={false} visible={isModalVisible}
               onCancel={handleCancel} width = "50%"
               footer = {[]}>
            <div className="add-customer-title">{cuDetail.name === undefined ?'添加用户':'编辑用户'}</div>
            <div className="title-horizontal">
                <Divider type="horizontal" orientation="center" className='horizontal-divider-modal-title'/>
            </div>
            <div className = "customer-type-selected normal-tabs" style={{marginTop: '5%'}}>
                <Tabs
                    defaultActiveKey={cuDetail.type === undefined ?indexTab : cuDetail.type+'' }
                    onChange={callbackTab}
                    type = "card"
                    centered
                >
                    <TabPane
                        tab={
                        <Row className='tab-box-left' justify = "space-around">
                            <Image src={indexTab==="1"?IconInstitutionLight:IconInstitutionDark} preview={false} width = {22} className='tab-icon'/>
                            机构
                        </Row>
                        }
                             key="1"
                             disabled ={cuDetail.type !== undefined}
                    >
                        {addCustomerForm()}

                    </TabPane>
                    <TabPane
                        tab={
                        <Row className='tab-box-right' justify = "space-around">
                            <div><Image src={indexTab==="2"?IconPersonLight:IconPersonDark} preview={false} width = {22} className='tab-icon'/></div>
                            <div>自然人</div>
                        </Row>
                        }
                             key="2"
                             disabled ={cuDetail.type !== undefined}
                    >
                        {addCustomerForm()}
                    </TabPane>
                </Tabs>
            </div>

        </Modal>
    </div>)
}


