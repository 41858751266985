import React, {useContext, useState} from "react";
import {
    apiGetApprovalList,
    apiGetApprovalNum,
    apiPassApproval,
    apiRejectApproval, apiUpdateInvoiceAbandonStatus,
    apiUpdateInvoiceReceiveStatus, updateInvoiceNumber, updateInvoiceReceivable
} from "../api/apis";
import {DepartmentContext} from "./department_provider";
import {Button, Divider, Form, Modal} from "antd";
import Input from "antd/es/input";

export const ApprovalContext = React.createContext();

export const ApprovalProvider = (props) => {
    const [approvalList, setApprovalList] = useState({});

    const defaultPageSize = 10;

    const [approvalNum, setApprovalNum] = useState({})

    const [secondType,setSecondType] = useState(1)

    const [approvalInvoiceList, setApprovalInvoiceList] = useState({});

    const getApprovalList = (params) =>{
        apiGetApprovalList(params).then((res)=>
            setApprovalList(res))
    }

    const getApprovalNum = (params) =>{
        apiGetApprovalNum(params).then((res)=>
            setApprovalNum(res))
    }

    const getAllApprovalInvoiceList = (params) =>{
        apiGetApprovalList(params).then((res)=>
            setApprovalInvoiceList(res))
    }

    const passApproval = async (params) =>{
        return await apiPassApproval(params)
    }

    const rejectApproval = async (params) =>{
        return await apiRejectApproval(params)
    }

    const updateInvoiceReceiveStatus = async (params) =>{
        return await apiUpdateInvoiceReceiveStatus(params)
    }

    const updateInvoiceAbandonStatus = async (params) =>{
        return await apiUpdateInvoiceAbandonStatus(params)
    }

    const addNumberApi = async (params) =>{
        return await updateInvoiceNumber(params)
    }

    const apiUpdateInvoiceReceivable = async (params) =>{
        return await updateInvoiceReceivable(params)
    }


    return (
        <ApprovalContext.Provider value={{
            approvalList,
            setApprovalList,
            getApprovalList,
            defaultPageSize,
            getApprovalNum,
            approvalNum,
            passApproval,
            rejectApproval,
            secondType,
            setSecondType,
            addNumberApi,
            apiUpdateInvoiceReceivable,
            updateInvoiceReceiveStatus,
            getAllApprovalInvoiceList,
            approvalInvoiceList,
            updateInvoiceAbandonStatus
        }}>
            {props.children}
        </ApprovalContext.Provider>
    )
}