import {Router, Route} from 'react-router';
import {createBrowserHistory} from 'history';
import React from "react";
import {Block, ParentComp} from "./page/example/example";
import {App} from "./App";
import {BasicLayout} from "./page/basicLayout/basicLayout";
import {RootProvider} from "./provider/root_provider";
import {UserInfoProvider} from "./provider/userInfo_provider";
import {LawFirmProvider} from "./provider/lawFirm_provider";
import {CustomerProvider} from "./provider/customer_provider";
import {DepartmentProvider} from "./provider/department_provider";
import {TaskProvider} from "./provider/task_provider";
import {UserManageProvider} from "./provider/user_management_provider";
import {CustomerManageProvider} from "./provider/customer_manager_provider";
import {WorkBenchProvider} from "./provider/work_bench_provider";
import {ProjectCreatProvider} from "./provider/project_creat_provider";
import {ProjectListProvider} from "./provider/project_list_provider";
import {ProjectManageProvider} from "./provider/project_manager_provider";
import {ProjectDetailProvider} from "./provider/project_detail_provider";
import {ProjectStampProvider} from "./provider/project_stamp_provider";
import {ProjectFinanceProvider} from "./provider/project_finance_provider";
import {ProjectFileProvider} from "./provider/file_provider";
import {ApprovalProvider} from "./provider/approval_provider";
import {ConflictProvider} from "./provider/conflict_provider";
import {WxRedirectPage} from "./page/login/wxQrLogin";
import {reverse} from 'lodash'



export const browserHistory = createBrowserHistory();
export const Routers = () => (
    <Router history={browserHistory}>
            <Route exact path="/" component={BasicLayout}/>
            <Route path="/login" component={App}/>
            <Route path="/block" component={Block}/>
            <Route path="/parentComp" component={ParentComp}/>
            <Route path="/wxLoginCallback" component={WxRedirectPage}/>
    </Router>
)

const composeProviders = (...Providers) => (props) => (
    reverse(Providers).reduce((acc, Provider) => (
        <Provider>
            {acc}
        </Provider>
    ), props.children)
)

export const WrappedApp = composeProviders(
    RootProvider,
    UserInfoProvider,
    LawFirmProvider,
    CustomerProvider,
    DepartmentProvider,
    TaskProvider,
    UserManageProvider,
    CustomerManageProvider,
    WorkBenchProvider,
    ProjectCreatProvider,
    ProjectListProvider,
    ProjectManageProvider,
    ProjectDetailProvider,
    ProjectStampProvider,
    ProjectFinanceProvider,
    ProjectFileProvider,
    ApprovalProvider,
    ConflictProvider
)

// export const Providers = (props) => (
//     <RootProvider>
//         <UserInfoProvider>
//             <LawFirmProvider>
//                 <CustomerProvider>
//                     <DepartmentProvider>
//                         <TaskProvider>
//                         <UserManageProvider>
//                             <CustomerManageProvider>
//                                 <WorkBenchProvider>
//                                     <ProjectCreatProvider>
//                                         <ProjectListProvider>
//                                             <ProjectManageProvider>
//                                                 <ProjectDetailProvider>
//                                                     <ProjectStampProvider>
//                                                         <ProjectFinanceProvider>
//                                                             <ProjectFileProvider>
//                                                                 <ApprovalProvider>
//                                                                     <ConflictProvider>
//                                                                         {props.children}
//                                                                     </ConflictProvider>
//                                                                 </ApprovalProvider>
//                                                             </ProjectFileProvider>
//                                                         </ProjectFinanceProvider>
//                                                     </ProjectStampProvider>
//                                                 </ProjectDetailProvider>
//                                             </ProjectManageProvider>
//                                         </ProjectListProvider>
//                                     </ProjectCreatProvider>
//                                 </WorkBenchProvider>
//                             </CustomerManageProvider>
//                         </UserManageProvider>
//                         </TaskProvider>
//                     </DepartmentProvider>
//                 </CustomerProvider>
//             </LawFirmProvider>
//         </UserInfoProvider>
//     </RootProvider>
// )
