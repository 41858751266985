import React, {useContext, useState} from "react";
import {message, Upload} from "antd";
import {ProjectFileContext} from "../provider/file_provider";
import {ProjectDetailContext} from "../provider/project_detail_provider";
import SparkMD5 from 'spark-md5'
import {Base64} from 'js-base64'
import axios from "axios";

export function UploadBox(props) {

    const {parentId,ownerType,officeId,taskId,title,projectId,pageType} = props
    const { getView,getProjectView,createFile,confirmFile} = useContext(ProjectFileContext)
    // const { projectDetail } = useContext(ProjectDetailContext)

    function getMd5FromArrayBuffer(fileArrayBuffer) {
        const spark = new SparkMD5.ArrayBuffer()
        spark.append(fileArrayBuffer); // Append array buffer
        const md5Hex = spark.end()
        const baseMd5 = Base64.fromUint8Array(Uint8Array.from(Buffer.from(md5Hex, 'hex')));
        console.log("file baseMd5：" + baseMd5)
        return baseMd5;
    }

    async function readAsArrayBuffer(file) {
        return  new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload =async function (e) {
                resolve(fileReader.result)
            };

            fileReader.onerror = function () {
                console.warn('oops, something went wrong.');
                reject()
            };
            fileReader.readAsArrayBuffer(file);
        })
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        console.log("file======",file)
        const fileArrayBuffer = await readAsArrayBuffer(file);
        const fileMd5 = getMd5FromArrayBuffer(fileArrayBuffer);

        try {
        let createFileRes = await createFile({
            fileMd5: fileMd5,
            officeId: officeId,
            taskId: taskId,
            fileName: file.name,
            fileSize: file.size,
            isFolder: false,
            ownerType: ownerType,
            parentId: parentId == 0 ? null : parentId,
            projectId: projectId??null,
        })
            const config = {
                headers: {
                    "content-Type":"application/octet-stream",
                    "content-md5": fileMd5
                    // "Content-Disposition": 'file; filename="' + file.name + '"',
                },
                onUploadProgress: event => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    //TODO: setProgress(percent); 设置进度
                    if (percent === 100) {
                        //TODO: setTimeout(() => setProgress(0), 1000); 上传完毕
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };

            const res = await axios.put(
                createFileRes.ossUrl,
                fileArrayBuffer,
                config
            );
            // 文件上传完毕
            confirmFile({"fileId": createFileRes.fileId})
            onSuccess("Ok");
            if(pageType===1){
                getProjectView({
                    officeId: officeId,
                    taskId: taskId,
                    ownerType: ownerType,
                    parentId: parentId == 0 ?null : parentId,
                    projectId: projectId,
                })
            } else {
                getView({
                    officeId: officeId,
                    taskId: taskId,
                    ownerType: ownerType,
                    parentId: parentId == 0 ?null : parentId,
                    projectId: projectId,
                })
            }
            message.success("上传成功")
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            onError({ err });
        }
    };

    return(
        <Upload
            customRequest={uploadImage}
            multiple={true}
        >
            {title}
        </Upload>
    )
}