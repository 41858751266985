import React, {useEffect, useMemo, useState} from "react";

export const RootContext = React.createContext();

export const RootProvider = (props) => {
    // 全局loading 状态
    const [globalLoading, setGlobalLoading] = useState({})

    //TODO: useMemo
    return (
        <RootContext.Provider value={setGlobalLoading}>
            {props.children}
        </RootContext.Provider>
    )
}