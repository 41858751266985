
//uat环境
const configs = {
    TITLE: '律所测试',
    BASE_URL: 'https://uat.api.yingchulawyer.com/test/',
    WEB: "https://uat.api.yingchulawyer.com/test/",
    // BASE_URL: ' http://uat.api.yingchulawyer.com',
    // WEB: " http://uat.api.yingchulawyer.com",
};
export default configs;

