import React, {useState} from "react";
import {
    apiCancelAdminByOfficeId,
    apiLawFirmList,
    apiOfficeCreate,
    apiSetAdminToOffice,
    apiSetOfficeState
} from "../api/apis";
import {message} from "antd";
export const LawFirmContext = React.createContext();

/**
 * 律所
 * @param props
 * @returns {*}
 * @constructor
 */
export const LawFirmProvider = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [lawList, setLawList] = useState({});
    const [allLawList, setAllLawList] = useState({});//全部
    const lawFirmStateList = ["0","1"]
    const defaultPageSize = 10;

    const getAllLawList = (params) =>{
        apiLawFirmList(params).then((res)=>{
            setAllLawList(res)
        })
    }

    const getLawList = (params) =>{
        apiLawFirmList(params).then((res)=>{
            let resList = []
            //for循环用管理员id取当前律所的管理员姓名和手机号
            for(let i = 0; i < res.list.length; i += 1){
                let temp  = res.list[i]
                let lawFirmNo = res.pageNo+i+1
                //TODO res.list[i].adminId 管理员id
                resList.push(
                    {
                        key: temp.officeId,
                        officeId: temp.officeId,
                        lawFirmName: temp.officeName,
                        lawFirmPrincipal: temp.adminRealName,
                        principalPhone: temp.adminPhone,
                        lawFirmPeopleNumber: temp.staffNum,
                        lawFirmProjectNumber: temp.projectNum,
                        lawFirmState:lawFirmStateList[temp.state],//0：开，1：关,
                        createDate:temp.createTime,
                    }
                )
            }
            res.list = resList
            setLawList(res)
        })
    }

    const officeCreate = (adminPhone,adminRealName,name) => {
        console.log("adminPhone",adminPhone)
        console.log("adminRealName",adminRealName)
        console.log("name",name)
        apiOfficeCreate({
            address: '',
            adminPhone: adminPhone,
            adminRealName: adminRealName,
            brief: '',
            logo: '',
            name: name
        }).then((res)=>{
            message.success('创建成功',1).then(()=>{
                getLawList({
                    pageNo: 0,
                    size: defaultPageSize
                })
                setIsModalVisible(false);

            })
        })
    }

    const SetOfficeState = (officeId,state) => {
        console.log("adminPhone",officeId)
        console.log("adminRealName",state)
        apiSetOfficeState({
            officeId: officeId,
            state: state
        }).then((res)=>{
            console.log('officeId',res.data)
            message.success('修改成功',1).then(()=>{
                getLawList({
                    pageNo: 0,
                    size: defaultPageSize
                })
            })
        })
    }

    const officeUpdate = (officeId,adminPhone,adminRealName) => {
        console.log("adminPhone",adminPhone)
        console.log("adminRealName",adminRealName)
        console.log("officeId",officeId)
        apiCancelAdminByOfficeId({
            officeId: officeId,
        }).then((res)=>{
                apiSetAdminToOffice({
                    "adminPhone": adminPhone,
                    "adminRealName": adminRealName,
                    "officeId": officeId
                }).then((res) =>{
                    message.success('修改成功',1).then(()=>{
                    getLawList({
                        pageNo: 0,
                        size: defaultPageSize
                    })
                })
            })
        })
    }



    return (
        <LawFirmContext.Provider value={{officeCreate,setIsModalVisible,isModalVisible,SetOfficeState,lawList,getLawList,defaultPageSize,officeUpdate,allLawList,getAllLawList}}>
            {props.children}
        </LawFirmContext.Provider>
    )
}