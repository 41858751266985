import {useConstruct, useDidMount, useDidUpdate, useUnMount} from "../../common/state";
import React, {useRef, useState} from "react";
import {testApi} from "../../api/apis";
import {LoadBox} from "../../common/load";

export function Block() {
    const [count, setCount] = useState(0);
    const instance = useRef({});

    useConstruct(() => {
        instance.current.name = 1;
        console.log('Block Component----Construct');
    });

    useDidMount(() => {
        console.log('Block Component----componentDidMount');
    });

    useDidUpdate(() => {
        console.log('instance', instance);
        console.log('instance.current', instance.current);
        console.log('instance.current.name', instance.current.name);
        console.log('Block Component----componentDidUpdate');
    });

    useUnMount(() => {
        console.log('Block Component----componentWillUnmount');
    });

    console.log('Block render');
    return (

        /**
         * @LoadFunc 请求方法传递
         * @finishFunc 加载方法传递
         * @testApi 请求的方法
         * @res 请求方法的返回值
         */
        <LoadBox loadFunc={()=>testApi()} finishFunc={(res)=>{console.log("Res",res)}}>
            // 里面是要显示的组件
            <div style={{backgroundColor: '#eaeaea'}}>
                <p>Block组件</p>
                <p>count: {count}</p>
                <br />
                <button onClick={() => setCount(count + 1)}>点击 count + 1</button>
            </div>
        </LoadBox>
    );
}

export function ParentComp() {
    const [unMountBlock, setUnMountBlock] = useState(false);
    return (
        <div>
            <p>unMountBlock: {unMountBlock?'true':'false'}</p>
            <br />
            {!unMountBlock ? <Block /> : null}
            <br />
            <button onClick={() => setUnMountBlock(true)}>点击卸载 Block 组件</button>
        </div>
    );
}
