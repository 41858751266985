import 'moment/locale/zh-cn';
import Locale from 'antd/es/date-picker/locale/zh_CN';
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import  {Radio,
    Avatar,
    Button,
    Checkbox,
    Col,
    Collapse,
    Divider,
    Form,
    Image,
    Input,
    List, message,
    Modal,
    Popover,
    Row,
    Select,
    Tabs,
    Upload
} from "antd";

import {IconAlarmClockImg, LinkIcon, TargetIcon,TaskDelete,TaskDoing,TaskDone} from "../../constants/images";
import {CaretDownOutlined, DeleteTwoTone, DownOutlined, InfoCircleOutlined, MoreOutlined} from "@ant-design/icons";
import {isNull, omit, split, without} from "lodash";
import {UserDocument} from "./user-document";
import DatePicker from "antd/es/date-picker";
import "./user.less";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import Text from "antd/es/typography/Text";
import {TaskContext} from "../../provider/task_provider";
import {WorkBenchContext} from "../../provider/work_bench_provider";
import {useDidMount, useUnMount} from "../../common/state";
import dayjs from "dayjs";
import moment from "moment";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {ProjectListContext} from "../../provider/project_list_provider";
import {UploadListBox} from "../../common/uploadList";
import {ProjectFileContext} from "../../provider/file_provider";
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const ItemCheckBoxOptions = [
    {label:"所有任务",value:undefined},
    {label:"个人任务",value:0},
    {label:"项目任务",value:1}
]

const ItemStateCheckBoxOptions = [
    {label:"未完成",value:"0"},
    {label:"已完成",value:"1"},
    {label:"已删除",value:"2"}
]


// export function User(){
export const User = forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const { taskList } = useContext(TaskContext)

    useDidMount(() => {
        getList()
    });
    //获取列表-统计数据
    const getList =(index,searchName)=>{
        taskList({
            ownerType:undefined, //0个人 1 =项目
            stateList: ['0','1'],
            pageNo:0,
            size:10
        })
    }
    //子组件方法
    useImperativeHandle(ref, () => ({
        focusInput: getList,
    }));

    return(
        <div className="normal-tabs user-tabs">
            <Tabs defaultActiveKey="1"  tabBarGutter={40}>
                <TabPane tab="任务" key="1">
                    <Task/>
                </TabPane>
                <TabPane tab="文档" key="2">
                    <Documentation/>
                </TabPane>
            </Tabs>
        </div>
    )
});
//任务
export function Task() {

    const [visible, setVisible] = useState(false);
    const [id, setId] = useState(0);
    const [state, setState] = useState(0);
    const {taskUpdate,taskList} = useContext(TaskContext)
    const [surperTaskType,setSurperTaskType] = useState(undefined)
    const [surperTaskStateList,setSurperTaskStateList] = useState(['0','1'])

    function deleteWork(){
        if(state!==2){
            taskUpdate({
                taskId:id,
                state:2
            }).then((res)=>{
                taskList({
                    ownerType:surperTaskType,
                    stateList: surperTaskStateList,
                    pageNo:0,
                    size:10
                })
            })
            setVisible(false)
        }else{
            message.error('目前任务为已删除状态，不可重复删除')
        }
    }

    return(
        <div className="user normal-tabs card-box">
            <TaskList position={1} setSurperTaskType={setSurperTaskType} setSurperTaskStateList={setSurperTaskStateList}
                      setVisible={setVisible} setId={setId} setState={setState}/>
            <Modal
                centered
                visible={visible}
                onOk={() => deleteWork()}
                onCancel={() => setVisible(false)}
            >
                <p>确认删除该任务吗</p>
            </Modal>
        </div>
    )

}
//任务列表
export function TaskList(props) {
    const {
        isCreateVisible,
        setIsCreateVisible,
        isUpdateVisible,
        setIsUpdateVisible,
        setTaDetail,
        taskDetail,
        taskUpdate,
        taList,
        queryMembersByProject,taskList
    } = useContext(TaskContext)
    const {woList,isWoUpdateVisible,setIsWoUpdateVisible,isWoCreateVisible,setIsWoCreateVisible, } = useContext(WorkBenchContext)
    const  {isPrCreateVisible, setIsPrCreateVisible, isPrUpdateVisible, setIsPrUpdateVisible,} = useContext(ProjectDetailContext)
    const {queryProjectBySelf} = useContext(ProjectListContext)
    const {position,setSurperTaskType,setSurperTaskStateList,setVisible,setId,setState} = props
     async function itemOnclick(value,ownerId,ownerType) {
            taskDetail({id: value}).then(()=>{
                if(position === 1) {
                    setIsUpdateVisible(true)
                }else {
                    setIsWoUpdateVisible(true)
                }
            })
         if(ownerType===1 ){
             queryMembersByProject({
                 id:ownerId
             })
         }
         queryProjectBySelf()
     }

     const [taskType,setTaskType] = useState(undefined)

    const [taskStateList,setTaskStateList] = useState(['0','1'])


    //组件销毁的时候清除定时器
    useUnMount(() => {
        // setIsUpdateVisible(false)
    });

    function deleteTask(e,id,state){
        e.stopPropagation()
        if(position==1){
            setSurperTaskType(taskType)
            setSurperTaskStateList(taskStateList)
        }
        setId(id)
        setState(state)
        setVisible(true)
    }

    function listItemWithClock(item) {
        return (
            <List.Item key={item.id}>
                <div className="task-list-item-box" onClick={()=>{
                    itemOnclick(item.id,item.ownerId,item.ownerType);
                    }} >
                    <Row justify="space-between" align="bottom">
                        {/*//0未完成 1已完成 2已删除*/}
                        {item.timeout === 1 ?
                            <Col span={5} className="task-name-box">
                                <Row>
                                    <Col span={3}><InfoCircleOutlined className = "alarm-icon-style"/></Col>
                                    <Col span={21}>
                                        <div className="task-name-red">{item.title}</div>
                                        <div className="task-type">{item.ownerType===1?"项目任务":"个人任务"}</div>
                                    </Col>
                                </Row>
                            </Col>:
                            <Col span={5} className="task-name-box">
                                <Row>
                                    <Col span={3}/>
                                    <Col span={21}>
                                        <div className="task-name">{item.title}</div>
                                        <div className="task-type">{item.ownerType===1?"项目任务":"个人任务"}</div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {
                            item.fileList === undefined?
                                <Col className="task-link-box" span={2}/>:
                                <Col className="task-link-box" span={2} >
                                    <Image className="task-link-icon" preview={false} src={LinkIcon}/>
                                    <span>{item.fileList.length}</span>
                                </Col>
                        }
                        {
                            item.targetNums === 0?
                                <Col className="task-link-box" span={2}/>:
                                <Col className="task-link-box" span={2} >
                                    <Image className="task-link-icon" preview={false} src={TargetIcon}/>
                                    <span>{item.targetStateT1+'/'+item.targetNums}</span>
                                </Col>
                        }

                        <Col className={item.timeout === 1 ?"task-end-time-alarm":"task-end-time"} span={5}>
                            <span className='align-center'>{doAwayWithT(item.endTime)}结束</span>
                        </Col>
                        <Col className="task-head-box" span={6}>
                            <Row >
                                <Col span={12}><Avatar size={50} src={item.head === undefined ? "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" : item.head.url}/></Col>
                                <Col span={3} className="clock-icon"><Image src ={IconAlarmClockImg} preview={false} width = {21}/></Col>
                                <Col span={8} className="alarm-clock" style = {{color:"#9398A4"}}>{item.allCostTimeString}</Col>
                            </Row>
                        </Col>
                        <Col span={2} style={{display:'flex',alignItems:'center',height:'50px'}}>
                            {
                                item.state==2?'':  <DeleteTwoTone twoToneColor="#d81e06" style={{fontSize:'20px',margin: '0 auto'}} onClick={(e)=>{deleteTask(e,item.id,item.state)}}/>
                            }
                        </Col>

                    </Row>
                </div>
            </List.Item>
        )
    }

    const onTypeChange = e =>{
        setTaskType(e.target.value)
        taskList({
            ownerType:e.target.value,
            stateList: taskStateList,
            pageNo:0,
            size:10
        })
    }

    const flushList = () =>{
        taskList({
            ownerType:taskType,
            stateList: taskStateList,
            pageNo:0,
            size:10
        })
    }

    const onStateChange = e =>{
        if(e.length<1){
            setTaskStateList(['10'])
            taskList({
                ownerType:taskType,
                stateList: ['10'],
                pageNo:0,
                size:10
            })
        } else {
            setTaskStateList(e)
            taskList({
                ownerType:taskType,
                stateList: e,
                pageNo:0,
                size:10
            })
        }

    }


    function renderHeader() {
        return(
            <div>
                <Row>
                    <Col span={20}>
                        <Popover className="pop-box" placement="bottom" content={()=>{
                            return(
                                <Radio.Group
                                    options={ItemCheckBoxOptions}
                                    defaultValue={undefined}
                                    onChange ={onTypeChange}
                                />
                            )
                        }} trigger="click">
                            <Button type="text">
                                任务类型
                                <CaretDownOutlined />
                            </Button>
                        </Popover>
                        <Popover className="pop-box" placement="bottom" content={()=>{
                            return(
                                <Checkbox.Group
                                    options={ItemStateCheckBoxOptions}
                                    defaultValue={['0','1']}
                                    onChange = {onStateChange}
                                />
                            )
                        }} trigger="click">
                            <Button type="text">
                                任务状态
                                <CaretDownOutlined />
                            </Button>
                        </Popover>
                    </Col>
                    <Col span = {4}>
                        <a  onClick={()=>{setIsCreateVisible(true);queryProjectBySelf()}} style={{fontSize :"16px" }}>+新建任务</a>
                    </Col>
                </Row>
                <Row>
                    {/*<DownOutlined className = "down-line-assign"/>*/}
                    {/*<div className="assign-title-time">今天</div>*/}
                    {/*<div className="assign-title-line">|</div>*/}
                    <div className="assign-title-num">{taList.totalNum === undefined ? 0 : taList.totalNum +'个任务'}</div>
                </Row>
            </div>
        )
    }

    return position === 1?(
        //我的
        <div className="task-list-box">
            <Modal
                className="task-modal"
                centered
                visible={isUpdateVisible}
                width="75%"
                getContainer={false}
                footer={null}
                onCancel={()=>{setIsUpdateVisible(false);}}
            >
                <AssignDetailModal position={position} flushList={flushList}/>
            </Modal>
            <Modal
                className="task-modal"
                centered
                visible={isCreateVisible}
                getContainer={false}
                width="45%"
                footer={null}
                onCancel={()=>{setIsCreateVisible(false);}}
            >
                <AddAssignModal position={position} flushList={flushList}/>
            </Modal>
            <List
                header={renderHeader()}
                dataSource={taList.list}
                pagination={{
                    showSizeChanger: true,
                    total: taList.totalNum,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:[5,10,20,100],
                    current:taList.pageNo+1,
                    onChange: (page,size)=> {
                        taskList({
                            ownerType:taskType,
                            stateList: taskStateList,
                            pageNo:page-1,
                            size:size
                        })
                    },
                }}
                renderItem={item => (listItemWithClock(item))}
            />
        </div>
    ):(position === 0 ?
            //工作台
        <div className="task-list-box">
            <Modal
                className="task-modal"
                centered
                visible={isWoCreateVisible}
                getContainer={false}
                width="45%"
                footer={null}
                onCancel={()=>{setIsWoCreateVisible(false);}}
            >
                <AddAssignModal position={position} flushList={flushList}/>
            </Modal>
            <Modal
                className="task-modal"
                centered
                visible={isWoUpdateVisible}
                width="75%"
                getContainer={false}
                footer={null}
                onCancel={()=>{setIsWoUpdateVisible(false);}}
            >
                <AssignDetailModal position={position} flushList={flushList}/>
            </Modal>
            <div style={{height:'40px'}}>
                <a onClick={()=>{setIsWoCreateVisible(true);queryProjectBySelf()}} style={{fontSize:"16px",float:'right',paddingRight:'20px'}}>+新建任务</a>
            </div>
            <List
                dataSource={woList.list}
                // // pagination={{
                // //     onChange: page => {
                // //         console.log(page);
                // //     },
                //     // pageSize: taList.totalPages,
                // }}
                renderItem={item => (listItemWithClock(item))}
            />
            <div className="task-more-box" onClick={()=>props.onClick()}>
                <span>
                    点我跳转我的任务，查看更多
                </span>
                <MoreOutlined style={{fontSize:"40px"}} rotate={90} />
            </div>
        </div>
            //项目
            : <div className="task-list-box">
                <Modal
                    className="task-modal"
                    centered
                    visible={isPrCreateVisible}
                    getContainer={false}
                    width="45%"
                    footer={null}

                    onCancel={()=>{setIsPrCreateVisible(false);}}
                >
                    <AddAssignModal position={position} flushList={flushList}/>
                </Modal>
                <Modal
                    className="task-modal"
                    centered
                    visible={isPrUpdateVisible}
                    width="75%"
                    getContainer={false}
                    footer={null}
                    onCancel={()=>{setIsPrUpdateVisible(false);}}
                >
                    <AssignDetailModal position={position} flushList={flushList}/>
                </Modal>
            </div>

    )
}

//文档
export function Documentation() {
    return(
        <div className="user normal-tabs card-box">
            <UserDocument/>
        </div>
    )

}

//添加任务
function AddAssignModal(props) {
    const { taskCreate,
        taskList,
        setIsCreateVisible,
        queryMembersByProject,
        members,
        listByProjectId
       } = useContext(TaskContext)
    const {setIsWoCreateVisible,workBenchList} = useContext(WorkBenchContext)
    const {setIsPrCreateVisible,projectDetail} = useContext(ProjectDetailContext)
    const {noPageList} = useContext(ProjectListContext)
    const [ownerHidden,setOwnerHidden] = useState(true)
    const [selected,setSelected] = useState([]);
    const {position,flushList} = props
    const children = [];

    for (let i = 10; i < 36; i++) {
        children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    }
    //任务类型Change
    function handleTypeChange(value) {
        setOwnerHidden(value === 0)

    }
    //所属项目Change
    function handleChange(value) {
        queryMembersByProject({id:value})
    }
    const handleCancel = () => {
        if(position === 1){
            setIsCreateVisible(false)
        }else if(position === 0) {
            setIsWoCreateVisible(false)
        }else {
            setIsPrCreateVisible(false)
        }
    };

    const onFinish = (value) =>{
        taskCreate({
            ...value,
            membersId:selected,
            startTime: timeFormat(value.time[0]),
            endTime: timeFormat(value.time[1]),
            position:position,
        }).then(()=>{
            if(position === 1) {
                flushList()
                setIsCreateVisible(false)
            }else if(position === 0) {
                workBenchList({
                    dayType:'1',
                    pageNo:0,
                    size:10,
                    state:'0'
                })
                setIsWoCreateVisible(false)
            }else {
                listByProjectId({
                    id:projectDetail.id,
                    auditState: "",
                    searchName: "",
                    pageNo: 0,
                    size: 10
                })
                setIsPrCreateVisible(false)
            }
        })
    }
    function selectOnChange(value) {
        setSelected(split(value,",",9999))
    }

    return (
        <div className="add-assign-modal">
            <div className="add-assign-title">新建任务</div>
            <div className="title-horizontal">
                <Divider type="horizontal" orientation="center" className='horizontal-divider-modal-title'/>
            </div>
            <Form
                {...layout}
                className="add-form"
                style={{paddingTop:'5%',paddingBottom:'2%'}}
                initialValues={{ ownerType: position===2?1:undefined,ownerId:position===2?projectDetail.id:undefined}}
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="任务标题"
                    name="title"
                    rules={[{
                        required: true,
                        message: '请输入任务标题',
                    }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="任务类型"
                    name="ownerType"
                    rules={[{ required: true, message: '请选择所属项目!' }]}
                >
                    <Select onChange={handleTypeChange.bind(this)} disabled={position===2?true:false}>
                        <Option value={0}>个人任务</Option>
                        <Option value={1}>项目任务</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="所属项目"
                    name="ownerId"
                    hidden={position===2?false:ownerHidden}
                >
                    <Select onChange={handleChange} disabled={position===2?true:false}>
                        {
                            noPageList.map((item)=>
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                            )

                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="时间设置"
                    name="time"
                    rules={[{ required: true, message: '请选择时间!' }]}
                >
                    <RangePicker showTime />
                </Form.Item>

                <Form.Item
                    label="任务成员"
                    name="person"
                    // rules={[{ required: true, message: '请选择任务成员' }]}
                    hidden={position===2?false:ownerHidden}
                >
                    <SelectMember selected={selected} selectOnChange={selectOnChange} members={members}/>
                </Form.Item>
                <div className = "add-customer-button">
                  <Button key="back" onClick={handleCancel} className = "customer-button-cancel">
                            取消
                   </Button>
                   <Button htmlType="submit" key="submit" type="primary" className = "customer-button-ok">
                            确定
                   </Button>
                </div>
            </Form>

        </div>
    )

}

let timeStatus1 = false
let timerStart = dayjs()
//编辑任务
function AssignDetailModal(props) {

    const {setIsPrUpdateVisible,projectDetail} = useContext(ProjectDetailContext)
    const {setIsWoUpdateVisible,workBenchList} = useContext(WorkBenchContext)
    const {taDetail, timingOf,taskStartTimer,taskStopTimer,taskUpdate,taskDetail,taskList,setIsUpdateVisible, queryMembersByProject,
        members,listByProjectId} = useContext(TaskContext)
    const [ownerHidden,setOwnerHidden] = useState(true)
    const [selected,setSelected] = useState(taDetail.membersId === undefined ?[]:taDetail.membersId);
    const [checkoutList,setCheckoutList] = useState(taDetail.targetList === undefined ? [] : defaultCheckout(taDetail.targetList));

    const [inputValue,setInputValue] = useState(null);
    const [timeStatus,setTimeStatus] = useState(false);
    let [optionsWithDisabled,setOptionsWithDisabled] =useState(taDetail.targetList === undefined ? [] : taDetail.targetList ) ;
    const [lastTime,setLastTime] = useState(taDetail.timerList === undefined ?'': taDetail.timerList[taDetail.timerList.length-1])
    const [popoverStatus,setPopoverStatus] =useState(false);
    const [deleteStatus,setDeleteStatus] =useState(false);
    const [itemIndex,setItemIndex] =useState('');
    let timer = useRef();
    const [timeRemark,setTimeRemark] = useState('')//计时备注
    // const [timerStart,setTimerStart] = useState(dayjs())//点击开始计时时间点
    const [timeDifference,setTimeDifference] = useState(undefined)//点击开始计时时间点
    const updateFormRef = React.createRef();
    const {position,flushList } = props
    const {noPageList} = useContext(ProjectListContext)
    const [taskFileList,setTaskFileList] = useState([])
    const { downloadFile } = useContext(ProjectFileContext)
    const [isShowSpan,setIsShowSpan] = useState(false);


     useDidMount(() => {
         timer.current = setInterval(() => {
             computationTime()
         }, 1000)
     });

    //组件销毁的时候清除定时器
     useUnMount(() => {
         clearInterval(timer);
    });

     //计算时间差值
    function computationTime () {
        if(timeStatus1) {
            setTimeDifference(MillisecondToDate(dayjs().diff(timerStart)))
        }
    }


    //计时器时长转换器
    function MillisecondToDate(msd) {
        var time = parseFloat(msd) / 1000;
        if (null != time && "" !== time) {
            if (time > 60 && time < 60 * 60) {
                let minute = time / 60.0
                let millisecond = parseInt((parseFloat(minute) - parseInt(minute)) * 60)
                time = '00:'+ (parseInt(minute)<10 ? ('0'+ parseInt(minute)):parseInt(minute) )+ ":" +
                    (millisecond <10 ?'0'+ millisecond : millisecond )     ;
            }
            else if (time >= 60 * 60 && time < 60 * 60 * 24) {
                let hour = time / 3600.0
                let minute = (parseFloat(hour) - parseInt(hour)) * 60
                let millisecond = parseInt((parseFloat(minute) - parseInt(minute)) * 60)
                time = (parseInt(hour)<10 ?'0'+ parseInt(hour): parseInt(hour))+ ":" +
                    (minute <10 ?'0'+ minute : minute ) + ":" +
                    (millisecond <10 ?'0'+ millisecond : millisecond )
                 ;
            }
            else {
                time = '00:00:'+ (parseInt(time)<10 ? '0'+parseInt(time) :parseInt(time) );
            }
        }
        return time;

    }

    const searchOnChange = (e) => {
       setInputValue(e.target.value)
    };
    //回车
    const searchOnClick = () => {
        optionsWithDisabled.push({name:inputValue,state: 0})
        setOptionsWithDisabled(optionsWithDisabled)
        setInputValue(null)
        // taskUpdate({
        //     taskId: taDetail.id,
        //     targetList: optionsWithDisabled,
        // })
    };

    //删除
    const  deleteCheck =(index)=>{
        setOptionsWithDisabled(without(optionsWithDisabled,optionsWithDisabled[index]))
        setDeleteStatus(false)
        setItemIndex('')
        taskUpdate({
            taskId: taDetail.id,
            targetList: without(optionsWithDisabled,optionsWithDisabled[index]),
        })
    };
    //
    function selectOnChange(value) {
        setSelected(split(value,",",9999))
        taskUpdate({
            taskId: taDetail.id,
            membersId: value==""?undefined:split(value,",",9999)
        })
    }
    //任务类型Change
    function handleTypeChange(value) {
        setOwnerHidden(value === 0)

    }
    //选择项目
    function handleChange(value) {
        queryMembersByProject({id:value})
    }


    //取消
    function handleCancel  () {
        updateFormRef.current.resetFields();
    }
    //确认
    const onFinish =()=>{

    }

    //计时-开始/结束
    function timeChange(){
        if(timeDifference !== undefined){
            setTimeStatus(!timeStatus )
            setTimeDifference(undefined)
            taskStopTimer({
                timerId:timingOf.id,
            })
            timeStatus1 = false
        }else{
            setPopoverStatus(!timeStatus)
        }
    }
    //开始计时 -保存
    function timeStartChange(){
        clearInterval(timer);
        if(timeRemark !== ''){
            taskStartTimer({
                ownerId:taDetail.id,
                ownerType:0,
                title:timeRemark,
            })
            setTimeStatus(!timeStatus)
            setPopoverStatus(false)
            // setTimerStart(dayjs())
            timerStart = dayjs()
            timeStatus1 = true
        }else {
            message.error('请输入工作描述',1)
        }
    }
    //开始计时 -取消
    function timeStartCancel(){
        setPopoverStatus(false)
        setTimeRemark('')
    }

    //时间截取
    function timeCapture (time) {
        let _index = time.indexOf("_");//获取第一个"_"的位置
        let t_index = time.indexOf("T");//获取第一个"T"的位置
       return  time.substring(_index,t_index)
    }

    //修改任务
    function updateTask(e) {
        let value = updateFormRef.current.getFieldsValue()
        if( taDetail.title !== value.title ||
            taDetail.ownerType !== value.ownerType ||
            taDetail.detail !== value.detail)
        {
            taskUpdate({
                taskId:taDetail.id,
                startTime:!e ? timeFormat(value.time[0]):undefined,
                endTime: !e ? timeFormat(value.time[1]):undefined,
                targetList: optionsWithDisabled,
                ...omit(value,'')
            })

            if(position === 1) {
                flushList()

            }else if(position === 0) {
                workBenchList({
                    dayType:'1',
                    pageNo:0,
                    size:10,
                    state:'0'
                })

            }else {
                listByProjectId({
                    id:projectDetail.id,
                    auditState: "",
                    searchName: "",
                    pageNo: 0,
                    size: 10
                })
            }


        }

    }

    //修改删除检测项方法
    function updateTarget(index) {
        setDeleteStatus(true)
        setItemIndex(index)
    }

    //选中检测项
    function checkOut(e,i) {
        const list =[...optionsWithDisabled]
        let a = list.map((item, idx) => idx === i ? {...item, state: e.target.checked ? 1 :0 } : item)
        taskUpdate({
            taskId: taDetail.id,
            targetList: a,
        })
        setOptionsWithDisabled(a)
    }

    //遍历list 获取默认选中项
    function defaultCheckout(value) {
        let list = [...value]
        let check = []
        list.map((item, idx) => item.state === 1 ? check.push(idx) : undefined)
        return check;
    }

    function onChange() {
        //state:0/未完成，1/已完成，2/已删除
        let stateValue = 0
       if(taDetail.state===1 || taDetail.state===2){
           stateValue = 0

       }else if(taDetail.state===0){
           stateValue = 1

       }
        taskUpdate({
            taskId:taDetail.id,
            state:stateValue
        }).then(()=>{
            if(position==2){
                listByProjectId({
                    id: projectDetail.id,
                    auditState: "",
                    searchName: "",
                    pageNo: 0,
                    size: 10
                })
            }else if(position === 0) {
                workBenchList({
                    dayType:'1',
                    pageNo:0,
                    size:10,
                    state:'0'
                })

            }else if(position === 1){
                flushList()
            }
        })
    }

    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    return (
        <div className="add-assign-modal">
            <div className="add-assign-title">
                <Image onClick={onChange}  src={taDetail.state===1?TaskDone:(taDetail.state===0?TaskDoing:TaskDelete)} preview={false} width='28px'/>
                <span className='add-assign-title-name'>任务详情</span>
            </div>
            <div className="title-horizontal">
                <Divider type="horizontal" orientation="center" className='horizontal-divider-modal-title'/>
            </div>
            <div className=""/>
            <Form
                {...layout}
                ref={updateFormRef}
                className="add-form"
                style={{ paddingTop:'2%',paddingBottom:'2%'}}
                initialValues={
                    {
                        title:taDetail.title === undefined ? '' : taDetail.title,
                        ownerType:taDetail.ownerType === undefined ? '': taDetail.ownerType,
                        ownerId:taDetail.ownerType === 1 ? taDetail.ownerId :undefined,
                        time:[moment(taDetail.startTime === undefined ? undefined : taDetail.startTime),moment(taDetail.endTime === undefined  ? undefined : taDetail.endTime)],
                        detail:taDetail.detail === undefined ? '' : taDetail.detail,
                  }
                }
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}

            >
                <Row>
                    <Col span={11}>
                        <Form.Item
                            label="任务标题"
                            name="title"
                            // rules={[{
                            //     required: true,
                            //     message: '请输入任务标题',
                            // }]}
                        >
                            <Input   onBlur={()=>updateTask()} />
                        </Form.Item>
                        <Form.Item
                            label="任务类型"
                            name="ownerType"
                            // rules={[{ required: true, message: '请选择所属项目!' }]}
                        >
                            <Select onChange={handleTypeChange.bind(this)}
                                    onBlur={()=>updateTask()}
                                    // disabled={position===2?true:false}
                            >
                                <Option value={0}>个人任务</Option>
                                <Option value={1}>项目任务</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="所属项目"
                            name="ownerId"
                            hidden={taDetail.ownerType === 1?false:ownerHidden}
                        >
                            <Select onChange={handleChange}>
                                {
                                    noPageList.map((item)=>
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )

                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="时间设置"
                            name="time"
                            // rules={[{ required: true, message: '请选择时间!' }]}
                        >
                            <RangePicker
                                showTime
                                onOpenChange={e=>!e ?updateTask(e) :undefined}
                                // format={'YYYY-MM-DD HH:mm:ss'}
                                // locale={Locale}
                            />

                        </Form.Item>
                        <Form.Item
                            label="计时"
                            name="timer"
                            // rules={[{ required: true, message: '请选择时间!' }]}
                        >
                            <Collapse
                                collapsible="header"
                                ghost={true}
                                className="site-collapse-custom-collapse"
                            >
                                <Panel header={
                                    <div>
                                    <Text className='total-time' >{taDetail.allCostTime === undefined ? '--:--:--' : taDetail.allCostTime}</Text>
                                    </div>
                                } key="1"
                                       className="site-collapse-custom-panel"
                                       extra={

                                           <Popover
                                               content={
                                                   <div className='remark-area'>
                                                       <TextArea className='remark-text'
                                                                 style={{border: '1px solid #9398A4', borderRadius: '12px', width: '220px'}}
                                                                 rows={4}
                                                                 onChange={e=>setTimeRemark(e.target.value)}
                                                                 placeholder ={'请输入工作描述'}
                                                       />
                                                       <div style={{alignItems: 'center', width: '100%', padding:'8px 0 0'}}>
                                                           <Button
                                                               htmlType="submit"
                                                               key="submit"
                                                               className = "customer-button-ok"
                                                               style={{
                                                                   borderRadius: '20px'
                                                               }}
                                                               danger={timeStatus}
                                                               type="primary"
                                                               onClick={()=>timeStartChange()}
                                                           >
                                                               开始
                                                           </Button>
                                                           <Button
                                                               key="back"
                                                               onClick={()=>timeStartCancel()}
                                                               className = "customer-button-cancel"
                                                               style={{
                                                                   float:'right',
                                                                   borderRadius: '20px',
                                                                   color: '#9398A4',
                                                               }}
                                                           >
                                                               取消
                                                           </Button>
                                                       </div>
                                                   </div>
                                               }
                                               trigger="click"
                                               visible={popoverStatus}
                                           >
                                               <Button
                                                   className='start-button'
                                                   danger={timeDifference !== undefined}
                                                   type="primary"
                                                   onClick={()=>timeChange(0)}
                                               >{timeDifference !== undefined ? '结束计时':'开始计时'}</Button>
                                           </Popover>


                                       }
                                >
                                    {taDetail.timerList === undefined ? undefined :
                                        taDetail.timerList.map((item,index) =>
                                            <Popover
                                                key={item.id}
                                                placement="top"
                                                // title={text}
                                                content={
                                                    <Text>{doAwayWithT(item.startTime)+'至' + (item.endTime === undefined ? '--' : doAwayWithT(item.endTime))}</Text>
                                                }
                                                trigger="hover">
                                                <div className='time-item'>
                                                    <Row className='time-item-row'>
                                                        <Text>{item.title}</Text>
                                                        <Text>{item.costTimeString}</Text>
                                                    </Row>
                                                    <Divider type="horizontal" orientation="center" className='item-divider'/>
                                                </div>
                                            </Popover>
                                        )}

                                </Panel>
                            </Collapse>
                            {timeDifference !== undefined ?
                                <span>{timeDifference}</span>
                                : null
                            }
                        </Form.Item>

                        {
                            taDetail.ownerType === 0?<div/>:
                                <Form.Item
                                    label="任务成员"
                                    name="person"
                                    // rules={[{ required: true, message: '请选择任务成员' }]}
                                >

                                    <SelectMember selected={selected} selectOnChange={selectOnChange}
                                                  members={members}/>
                                </Form.Item>
                        }


                    </Col>
                    <Col span={1} className='department-menu-divider'>
                        <Divider type="vertical"  className='vertical-divider-title'/>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="附件"
                            name="file"
                        >
                            <UploadListBox title={"上传附件"} ownerType={taDetail.ownerType==0?0:1} isUpdate={true}
                                           otherList={taDetail.fileList} projectId={taDetail.ownerType==0?undefined:taDetail.ownerId} taskId={taDetail.id}/>
                        </Form.Item>
                        <Form.Item
                            label="已上传附件"
                            name="file"
                        >
                            {
                                taDetail.fileList.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                        </Form.Item>

                        <Form.Item
                            label="详情"
                            name="detail"
                            // rules={[{
                            //     required: true,
                            //     message: '请输入任务标题',
                            // }]}
                        >
                            <div className = "detail">
                            <TextArea
                                className='detail-area'
                                defaultValue ={taDetail.detail === undefined ? '' : taDetail.detail}
                                rows={4}

                            />
                            <div className = "update-customer-button">
                                <Button htmlType="submit" key="submit" type="primary" className = "customer-button-ok"
                                        onClick={()=>updateTask()}
                                >
                                    保存
                                </Button>
                                <Button key="back" onClick={handleCancel} className = "customer-button-cancel">
                                    取消
                                </Button>
                            </div>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="检测项"
                            name="testItem"
                        >
                            <Input
                                className='testItem-input'
                                onPressEnter={inputValue !== null?searchOnClick :null}
                                value={inputValue}
                                onChange={(e) =>{searchOnChange(e)}}
                                onFocus={()=>setIsShowSpan(true)}
                                onBlur={()=>setIsShowSpan(false)}
                                suffix={
                                    isShowSpan?
                                    <Text style={{color: '#cdcdcd',fontSize:"12px"}}>回车保存</Text>:''
                                }
                            />
                            {optionsWithDisabled.length !== 0 ?
                                <Checkbox.Group
                                    className='check-box-group'
                                    defaultValue={checkoutList}
                                    // onChange={onChange}
                                >
                                    {
                                        optionsWithDisabled.map((item,index) =>{
                                            return (
                                                <Row key={index+'row'} className='check-box-item'>
                                                    <Checkbox
                                                        key={index}
                                                        value={index}
                                                        onChange={(e) =>
                                                            checkOut(
                                                                e,
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        {item.name}
                                                    </Checkbox>
                                                    {
                                                        deleteStatus && itemIndex === index  ?

                                                            <Button
                                                                key={index+'bt'}
                                                                type="text"
                                                                onClick={()=>deleteCheck(index)}
                                                            >
                                                                {'确认删除'}
                                                            </Button>
                                                            :
                                                            <Button
                                                                key={index+'ic'}
                                                                icon={<DeleteOutlined />}
                                                                type="text"
                                                                onClick={()=>updateTarget(index)}
                                                            >
                                                            </Button>
                                                    }
                                                </Row>
                                            )
                                        })
                                    }

                                </Checkbox.Group>
                                : null
                            }

                        </Form.Item>

                    </Col>
                </Row>
            </Form>

        </div>
    )
}

//添加成员
export function SelectMember(props) {
    const {
        selected,
        selectOnChange,
        members
    } = props
    const children = [];
    const [open,setOpen] = useState(false);

    return(
        <div style={{ height: `${parseInt(selected.length/5)*28+35}px` }}>
        <Button type="dashed"
                icon={<PlusOutlined />}
                shape="circle"
                onClick={()=>{setOpen(true)}}
                // onChange={selectOnChange}
        />
            <Select
                className ='select-member'
                mode="multiple"
                style={{ width: '100%' }}
                open={open}
                onChange={selectOnChange}
                autoFocus={open}
                onBlur={()=>{setOpen(false)}}
                bordered={false}
                defaultValue={selected} // 默认
            >
                {
                    members?.map((res)=>
                        <Option key={res.userId} value={res.userId}>{res.realName}</Option>
                    )
                }
            </Select>

        </div>
    )

}

//时间处理 T
function doAwayWithT(data) {
    if(data){
        return new Date(+new Date(data)+8*3600*1000).toISOString().replace(/T/g,' ').replace(/\.[\d]{3}Z/,'')
    }
}

//日期格式化
function timeFormat(value) {
    return  dayjs(new Date(value)).format('YYYY-MM-DD HH:mm:ss').replace(" ","T");

}


