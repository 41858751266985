import React, {useContext, useEffect, useState} from "react";
import {
    Row,
    Col,
    Divider,
    Menu,
    Modal,
    Form,
    Dropdown,
    Button,
    Upload,
    Input,
    InputNumber, message,
} from "antd";
import {CaretDownOutlined,} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import {useDidMount} from "../../common/state";
import {ProjectStampContext} from "../../provider/project_stamp_provider";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {UploadListBox} from "../../common/uploadList";
import {ProjectFileContext} from "../../provider/file_provider";

const documentsTypeList = ['律师事务所所函','委托合同','法律意见书','尽职调查报告','法律见证书','法律顾问工作报告','委托授权书','律师调查专用介绍信','刑事会见介绍信','其他']
const stampTypeList = ['公章','合同专用章' ,'见证章' ,'财务章' ,'无需盖章']

export function ProjectDetailSeal() {

    const { getProjectStampList,projectStampList,defaultPageSize,queryPositionByOfficeId,riskUser,createProjectStamp,getProjectStampDetail,projectStampDetail }  = useContext(ProjectStampContext)
    const { projectDetail } = useContext(ProjectDetailContext)

    const [ isSealModal, setIsSealModal] = useState(false);
    const [ isSealDetailModal, setIsSealDetailModal] = useState(false);


    //新建弹窗
    function itemOnclick() {
        queryPositionByOfficeId({name:"r_administrative"})
        setIsSealModal(true)
    }

    function detailOnclick(value){
        getProjectStampDetail({
            id:value
        })
        setTimeout(()=>{
            setIsSealDetailModal(true)
        },100)
    }

    const tabColumns =[
        {
            title: '文书编号',
            dataIndex: 'documentsNumber',
            key: 'documentsNumber',
        },
        {
            title: '文书类型',
            dataIndex: 'documentsType',
            key: 'documentsType',
            hideInSearch:true,
            render: (dom) => (
                <div>
                    {documentsTypeList[dom-1]}
                </div>
            ),
        },
        {
            title: '盖章发起人',
            dataIndex: 'applyName',
            key: 'applyName',
            hideInSearch:true,
        },
        {
            title: '发起日期',
            dataIndex: 'createTime',
            valueType:'dateTime',
            hideInSearch:true,
            // sorter: (a, b) => a.totalTime - b.totalTime,
        },
        {
            title: '盖章类型',
            dataIndex: 'stampType',
            key: 'stampType',
            hideInSearch:true,
            render: (dom) => (
                <div>
                    {stampTypeList[dom]}
                </div>
            ),
        },
        {
            title: '盖章份数',
            dataIndex: 'stampNum',
            key: 'stampNum',
            hideInSearch:true,
        },
        {
            title: '状态',
            dataIndex: 'auditState',
            valueType: 'select',
            valueEnum: {
                0: { text: '未审核', status: 'Default' },
                1: { text: '已审核', status: 'Processing' },
                2: { text: '已驳回', status: 'Error' },
            },
        },
        {
            title:'操作',
            valueType: 'option',
            render: (dom,record) => (
                <a onClick={()=>detailOnclick(record.id)}>
                    详情
                </a>
            ),
        }
    ]

    const  paginationProps = {
        showSizeChanger: true,
        pageSize:  defaultPageSize,
        total: projectStampList?.totalNum,
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };


    return(
        <div className='seal-box'>
            <div className='pro-box'>
                <ProTable
                    columns={tabColumns}
                    dataSource={projectStampList.list}
                    rowKey="key"
                    pagination={paginationProps}
                    options={false}
                    search={{
                        defaultCollapsed: false,
                        labelWidth: 'auto',
                        optionRender: (searchConfig, formProps, dom) => [
                            ...dom.reverse(),
                            <Button key="out" onClick={()=>{itemOnclick(0)}}>申请盖章</Button>,
                        ],
                    }}
                    dateFormatter="string"
                    request={async (params = {}, sort, filter) => {
                        const res = await getProjectStampList({
                            id: projectDetail.id,
                            auditState: params.auditState,
                            searchName: params.documentsNumber,
                            pageNo:  params.current-1,
                            size: params.pageSize
                        });
                        return Promise.resolve({
                            data: res.list,
                            success: true,
                            total:res.totalNum
                        });
                    }}
                />
            </div>
            <SealModal isSealModal={isSealModal} setIsSealModal={setIsSealModal} projectDetail={projectDetail} riskUser={riskUser}
                       createProjectStamp={createProjectStamp} getProjectStampList={getProjectStampList} />
            <SealDetailModal projectStampDetail={projectStampDetail} setIsSealDetailModal={setIsSealDetailModal} isSealDetailModal={isSealDetailModal}/>
        </div>
    )
}


const layout = {
    wrapperCol: { span: 20 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};


//新建盖章弹窗
export function SealModal(props){

    const [ sealTypeText, setSealTypeText] = useState("公章");
    const [ sealTypeValue, setSealTypeValue] = useState(0);
    const [ sealNameText, setSealNameText] = useState("律师事务所所函");
    const [ sealNameValue, setSealNameValue] = useState(1);
    const [ sealEg, setSealEg] = useState("");
    const [sealFileList,setSealFileList] = useState([])

    const { TextArea } = Input;
    const [sealForm] = Form.useForm()

    const {isSealModal,setIsSealModal,projectDetail,riskUser,createProjectStamp,getProjectStampList} = props

    const handleOk = () => {
        setIsSealModal(false);
    };

    const handleCancel = () => {
        setIsSealModal(false);
    };

    const onChange = e => {
        console.log('Change:', e.target.value);
        setSealEg(e.target.value)
    };

    function onFormFinish(e) {
        console.log('eeeeee:', e.sealNumber);
        if(sealFileList.length==0){
            message.error('附件不能为空')
            return
        }
        createProjectStamp({
            annex: sealFileList,//附件list
            documentsNumber: "",
            documentsType: sealNameValue,
            mark: sealEg,
            projectId: projectDetail.id,
            stampNum: e.sealNumber==undefined?1:e.sealNumber,
            stampType: sealTypeValue
        }).then(()=>{
            getProjectStampList({
                id: projectDetail.id,
                auditState: "",
                searchName: "",
                pageNo: 0,
                size: 5
            })
            setIsSealModal(false)
            setSealFileList([])
            sealForm.resetFields()
        })

    }

    const onFinishFailed= (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const sealMenuOnClick = ({ key }) => {
        setSealTypeText(stampTypeList[key])
        setSealTypeValue(key)
    };

    const sealTypeMenu = (
        <Menu onClick={sealMenuOnClick}>
            <Menu.Item key="0">{stampTypeList[0]}</Menu.Item>
            <Menu.Item key="1">{stampTypeList[1]}</Menu.Item>
            <Menu.Item key="2">{stampTypeList[2]}</Menu.Item>
            <Menu.Item key="3">{stampTypeList[3]}</Menu.Item>
        </Menu>
    );
    const sealNameMenuOnClick = ({ key }) => {
        setSealNameText(documentsTypeList[key])
        setSealNameValue((parseInt(key)+1))
    };

    const sealNameMenu = (
        <Menu onClick={sealNameMenuOnClick}>
            <Menu.Item key="0">{documentsTypeList[0]}</Menu.Item>
            <Menu.Item key="1">{documentsTypeList[1]}</Menu.Item>
            <Menu.Item key="2">{documentsTypeList[2]}</Menu.Item>
            <Menu.Item key="3">{documentsTypeList[3]}</Menu.Item>
            <Menu.Item key="4">{documentsTypeList[4]}</Menu.Item>
            <Menu.Item key="5">{documentsTypeList[5]}</Menu.Item>
            <Menu.Item key="6">{documentsTypeList[6]}</Menu.Item>
            <Menu.Item key="7">{documentsTypeList[7]}</Menu.Item>
            <Menu.Item key="8">{documentsTypeList[8]}</Menu.Item>
            <Menu.Item key="9">{documentsTypeList[9]}</Menu.Item>
        </Menu>
    );

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isSealModal}
                   onOk={handleOk} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>盖章</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '0'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                projectName:projectDetail.name,
                                projectNum:projectDetail.projectNum,
                                sealNumber:1
                            }}
                            form={sealForm}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <div className='model-title'>盖章信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>项目名称</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>文书信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row' justify="space-between">
                                <Col span={7}>
                                    <div className='project-input-title-row'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>文书类型</div>
                                    <Form.Item
                                        name="sealName"
                                    >
                                        <Dropdown overlay={sealNameMenu} placement="bottomCenter" arrow>
                                            <Button>{sealNameText} <CaretDownOutlined /></Button>
                                        </Dropdown>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>盖章类型</div>
                                    <Form.Item
                                        name="sealType"
                                    >
                                        <Dropdown overlay={sealTypeMenu} placement="bottomCenter" arrow>
                                            <Button>{sealTypeText} <CaretDownOutlined /></Button>
                                        </Dropdown>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>盖章份数</div>
                                    <Form.Item
                                        name="sealNumber"
                                    >
                                        <InputNumber min={1} max={1000} keyboard='true'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>文书编号</div>
                                    <Form.Item
                                        name="sealNo"
                                    >
                                        <Input placeholder="无需填写，将在提交成功后，盖章人填写" disabled='true'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'><span style={{color:'#ff4d4f',fontSize:'14px',marginRight:'4px'}}>*</span>附件(上传Word文件)</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <UploadListBox title={"上传附件"} ownerType={1} setOtherList={setSealFileList} otherList={sealFileList}
                                           projectId={projectDetail.id} isUpdate={false}/>
                            <div className='model-audit'>
                                <span>审核人:</span><span>{riskUser.name}</span>
                            </div>
                            <div className='model-title'>备注</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <TextArea rows={4} className='model-textArea' onChange={onChange}/>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}

//盖章详情弹窗
export function SealDetailModal(props){

    const { TextArea } = Input;
    const { downloadFile } = useContext(ProjectFileContext)
    const {isSealDetailModal,setIsSealDetailModal,projectStampDetail} = props

    const handleCancel = () => {
        setIsSealDetailModal(false);
    };

    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isSealDetailModal} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>盖章详情</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '0'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                projectName:projectStampDetail.projectName,
                                projectNum:projectStampDetail.projectNumber,
                                sealName:documentsTypeList[projectStampDetail.documentsType-1],
                                sealType:stampTypeList[projectStampDetail.stampType],
                                sealNumber:projectStampDetail.stampNum,
                                sealNo:projectStampDetail.documentsNumber,
                                mark:projectStampDetail.mark
                            }}
                        >

                            <div className='model-title'>盖章信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>项目名称</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>文书信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row' justify="space-between">
                                <Col span={7}>
                                    <div className='project-input-title-row'>文书类型</div>
                                    <Form.Item
                                        name="sealName"
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章类型</div>
                                    <Form.Item
                                        name="sealType"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章份数</div>
                                    <Form.Item
                                        name="sealNumber"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>文书编号</div>
                                    <Form.Item
                                        name="sealNo"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>文书附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                projectStampDetail?.annex?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                            <div className='model-audit'>
                                <span>审核人:</span><span>{projectStampDetail.auditName}</span>
                            </div>
                            <div className='model-title'>备注</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Form.Item
                                name="mark"
                            >
                                <TextArea rows={4} className='model-textArea' disabled={true}/>
                            </Form.Item>

                        </Form>

                    </div>
                </div>
            </Modal>
        </div>
    )
}