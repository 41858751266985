import {useContext, useEffect} from "react";
import _ from 'lodash'
import {UserInfoContext} from "../../provider/userInfo_provider";

export const WX_QR_TYPE_LOGIN = "login"
export const WX_QR_TYPE_BIND = "bind"

const WX_APP_ID = "wxc22db01b8659d174"
const REDIRECT_PAGE = "https://law.yingchulawyer.com/wxLoginCallback/"

let isLoaded = false

export const WxQrCode = ({type, onSuccess, onFailed}) =>{
    const {doWxLogin, bindWx} = useContext(UserInfoContext)

    // 添加微信二维码
    useEffect(() => {
        if (!isLoaded) {
            addWxQrCode()
        }
        setTimeout(()=>{
            loadWxQrCode()
        }, isLoaded?0:500)
        isLoaded = true

        window.addEventListener('message', msgReceive, false);
        return ()=>{
            window.removeEventListener('message', msgReceive);
        }
    }, []);

    const msgReceive = async (event)=>{
        // console.log("msgReceive receive event",event)
        // if(!event.isTrusted) {
        //     return;
        // }
        const {code} = event.data
        console.log("msgReceive receive code",code);
        try {
            if (type === WX_QR_TYPE_LOGIN) {
                await doWxLogin(code)  // 登录
            } else if (type === WX_QR_TYPE_BIND) {
                await bindWx(code) // 绑定微信
            }
            if (onSuccess) {
                onSuccess()
            }
        } catch (e) {
            if (onFailed) {
                onFailed(e)
            }
        }
    }

    const addWxQrCode = () => {
        //引入“微信内嵌二维码”脚本
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
        document.head.appendChild(script);
    }

    const loadWxQrCode = () => {
        /**
         * 微信内嵌二维码自定义样式有两种方式实现
         * 第一种是把自定义样式写在一个css文件里面，部署到一个https链接上
         * 第二种是把自定义样式通过base64加密后设置在href上
         * */
        var customeStyle = "data:text/css;base64,Lnd4X3FyY29kZSBpZnJhbWUgeyB3aWR0aDogMTYwcHg7IGhlaWdodDogMTYwcHg7IG1hcmdpbjogMDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgeyBtYXJnaW46MDsgcGFkZGluZzogMDsgfQ0KLmxvZ2luUGFuZWwgLnRpdGxlLCAubG9naW5QYW5lbCAuaW5mbyB7IGRpc3BsYXk6IG5vbmU7IH0NCi5pbXBvd2VyQm94IC5xcmNvZGUgeyBtYXJnaW46IDA7IHdpZHRoOiAxNjBweDsgYm9yZGVyOiBub25lOyB9";

        console.log(window.WxLogin)

        new window.WxLogin({
            self_redirect: true,
            id: "wx_login_container",
            appid: WX_APP_ID,
            scope: "snsapi_login",
            redirect_uri: encodeURI(REDIRECT_PAGE),
            state: "" + (new Date()).getTime(),
            style: "black",
            href: customeStyle, //location.origin + "/css/wx_qrcode.css", //自定义微信二维码样式文件
        });

        //设置iframe标签可以进行跨域跳转
        var qrcodeBox = document.getElementById("wx_login_container");
        var iframes = qrcodeBox.getElementsByTagName("iframe");

        if (iframes.length){
            var ifr = iframes[0];
            ifr.setAttribute("sandbox", "allow-scripts allow-top-navigation allow-same-origin");
        }
    }

    return (
        <div>
            <div id='wx_login_container' style={{height:200, overflow:'hidden', background: 'white'}}/>
        </div>);
}

/**
    注意: 这个页面跑在生产上，本地修改不会生效，必须先部署！！！！
 **/
export const WxRedirectPage = (props) =>{
    useEffect(() => {
      setTimeout(()=>sendCode(), 200)
    }, []);

    const sendCode = () => {
        // 接收参数
        console.log("WxRedirectPage location",props.location)
        const query = props.location.search
        const arr = query.split('&')
        if (!_.isEmpty(arr)) {
            // 取出 code 值
            const code = arr[0].substr(6) //'?code=xxx'
            console.log("WxRedirectPage code",code)
            // 发送微信登录 code
            window.parent && window.parent.postMessage({code}, '*');
        }
    }

    return (
        <div style={{backgroundColor: 'white', height: 400}}>微信登录中...</div>
    )
}