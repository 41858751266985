import { Avatar, Button, Divider, Form, Image, Input, message, Modal, Steps, Select} from "antd";
import { PersonSetImg, wxChatCode, Done } from "../../constants/images";
import React, { useContext, useState } from "react";
import "./mine.less";
import { UserInfoContext } from "../../provider/userInfo_provider";
import { useDidMount } from "../../common/state";
import { UploadImageBox } from "../../common/uploadImage";
import _ from "lodash";
import { WX_QR_TYPE_BIND, WxQrCode } from "../login/wxQrLogin";

const { Option } = Select;
const { TextArea } = Input;

export function Mine(props) {

    const { isAdministrator } = props

    const [isPhoneModal, setIsPhoneModal] = useState(false);//个人页面修改手机弹窗
    const [isWxChatModal, setIsWxChatModal] = useState(false);//个人页面修改手机弹窗
    const [isUnbindWxModal, setUnbindWxModal] = useState(false); // 解除微信绑定确认界面
    const [isCasesModal, setCasesModal] = useState(false); // cases
    const { userInfoDetail, getUserInfoDetail, leaveOffice, unBindWx , getDateUser, getCasesHonorUser} = useContext(UserInfoContext)
    const [visible, setVisible] = useState(false);

    useDidMount(
        () => {
            getUserInfoDetail()
            // getDateUser()
        }
    )

    function phoneModalClick() {
        console.log("phoneModalClick")
        setIsPhoneModal(true)
    }

    function leaveOfficeFun() {
        leaveOffice()
        setVisible(false)
    }

    const handlerClickUnbindWx = () => {
        unBindWx().then((res) => {
            setUnbindWxModal(false)
        })
    }
    const [casesValue, setCasesValue] = useState('');
    const [isPersonalHonorModal, setPersonalHonorModal] = useState(false);
    const [personalHonorValue, setPersonalHonorValue] = useState('');

    const handleTextAreaChange = (e) => {
        setCasesValue(e.target.value);
    };
    const handleTextAreaChange2 = (e) => {
        setPersonalHonorValue(e.target.value);
    };

    return (
        <div className='set-box' style={{ minHeight: document.documentElement.clientHeight - 300 }}>
            <Modal
                centered
                visible={visible}
                onOk={() => leaveOfficeFun()}
                onCancel={() => setVisible(false)}
            >
                <p>确认离职吗</p>
            </Modal>
            <div className='set-title'>
                <Image src={PersonSetImg} width={35} preview={false} />个人设置
            </div>
            <Divider type="horizontal" orientation="center" className='divider-title' />
            <div className='set-content'>
                <div>
                    <UploadImageBox src={userInfoDetail?.headImg ? userInfoDetail?.headImg: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'} ></UploadImageBox>
                    {/*<Avatar size={120} src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' />*/}
                </div>
                <div className='set-content-form'>
                    <Form>
                        <Form.Item
                            label="姓名"
                            name="name"
                        >
                            <Input placeholder={userInfoDetail?.realName} suffix=' ' readOnly />
                        </Form.Item>
                        <Form.Item
                            label="手机"
                            name="phone"
                        >
                            <Input placeholder={userInfoDetail?.username} suffix={
                                <a onClick={() => phoneModalClick()}>修改</a>
                            } />
                        </Form.Item>
                        <Form.Item
                            label="微信"
                            name="wechat"
                        >{
                                _.isEmpty(userInfoDetail?.wxUnionId) ?
                                    <a onClick={() => setIsWxChatModal(true)}>点击绑定微信</a> :
                                    <a onClick={() => setUnbindWxModal(true)}>解除绑定</a>
                            }
                        </Form.Item>
                        <Form.Item
                            label="教育背景"
                            name="professionalField"
                        >
                                <Select placeholder={educations[userInfoDetail?.education-1]} onChange={(value)=>getDateUser(value,null)}>
                                    <Option value="1">本科</Option>
                                    <Option value="2">硕士</Option>
                                    <Option value="3">博士</Option>
                                </Select>
                        </Form.Item>
                        <Form.Item
                            label="专业领域"
                            name="education"
                        >
                            <Select mode="multiple" placeholder={userInfoDetail?.professionalFieldnew} onChange={(value)=>getDateUser(null,value)}>
                                    <Option value="1">民商事代理</Option>
                                    <Option value="2">刑事代理</Option>
                                    <Option value="3">常年法律顾问及专项法律服务</Option>
                                    <Option value="4">知识产权相关</Option>
                                    <Option value="5">公司并购、破产与清算</Option>
                                    <Option value="6">证券法律服务</Option>
                             </Select>

                        </Form.Item>
                        <Form.Item
                            label="经典案例"
                            name="cases"
                        >
                            <a onClick={() => setCasesModal(true)}>点击编辑经典案例</a>
                        </Form.Item>

                        <Form.Item
                            label="个人荣誉"
                            name="personalHonor"
                        >
                            <a onClick={() => setPersonalHonorModal(true)}>点击编辑个人荣誉</a>
                            {/*<TextArea autoSize={{minRows: 6, maxRows: 16}} placeholder={userInfoDetail?.personalHonor} onBlur={(e)=>getCasesHonorUser(null,e.target.value)} />*/}
                        </Form.Item>
                        <div className="set-button-box" style={{ opacity: isAdministrator ? '0' : '1' }}>
                            <Button className="set-button" type="primary" onClick={() => setVisible(true)} danger>
                                离职
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            <PhoneModal isPhoneModal={isPhoneModal} setIsPhoneModal={setIsPhoneModal} />
            <WxChatModal isWxChatModal={isWxChatModal} setIsWxChatModal={setIsWxChatModal} />
            <Modal
                centered
                visible={isUnbindWxModal}
                onOk={() => handlerClickUnbindWx()}
                onCancel={() => setUnbindWxModal(false)}
            >
                <p>确认解除绑定微信么?</p>
            </Modal>

            <Modal
                centered
                visible={isCasesModal}
                onOk={() => {
                    getCasesHonorUser(casesValue, null)
                    setCasesModal(false)
                } }
                onCancel={() => setCasesModal(false)}
            >
                <h3>编辑经典案例</h3>
                <TextArea autoSize={{minRows: 16, maxRows: 56}} placeholder={userInfoDetail?.cases}  onChange={handleTextAreaChange} ></TextArea>

            </Modal>
            <Modal
                centered
                visible={isPersonalHonorModal}
                onOk={() => {
                    getCasesHonorUser(null, personalHonorValue)
                    setPersonalHonorModal(false)
                } }
                onCancel={() => setPersonalHonorModal(false)}
            >
                <h3>编辑个人荣誉</h3>
                <TextArea autoSize={{minRows: 16, maxRows: 56}} placeholder={userInfoDetail?.personalHonor}  onChange={handleTextAreaChange2} ></TextArea>

            </Modal>

        </div>
    )

}

const layout = {
    labelCol: { span: 6, offset: 2 },
    wrapperCol: { span: 12 },
};
const educations = [
    '本科',
    '硕士',
    '博士'
]
const steps = [
    {
        title: '验证身份',
    },
    {
        title: '修改手机',
    },
    {
        title: '完成',
    },
];

const stepswxchat = [
    {
        title: '验证身份',
    },
    {
        title: '修改微信',
    },
    {
        title: '完成',
    },
];

const { Step } = Steps;

function PhoneModal(props) {

    const { isPhoneModal, setIsPhoneModal } = props

    const handleOk = () => {
        setIsPhoneModal(false);
    };

    const handleCancel = () => {
        setIsPhoneModal(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish", e)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };
    const done = () => {
        message.success('修改成功!')
        setIsPhoneModal(false)
        setCurrent(0)
    };

    const getCode = () => {

    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isPhoneModal}
                onOk={handleOk} onCancel={handleCancel} width="35%" centered footer={[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>修改手机</Divider>
                </div>
                <div className="content-model-body" style={{ marginTop: '5%' }}>
                    <>
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className="steps-content">
                            <div className='steps-content-title'>
                                {
                                    current === 0 ? '请使用微信扫描二维码验证' : (current === 1 ? '请使用您账号已认证的手机号验证' : '')
                                }
                            </div>
                            {
                                current === 0 ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <Image src={wxChatCode} preview={false} style={{ width: '200px' }} />
                                    </div>
                                ) : (current === 1 ? (
                                    <div className="content-model-body-form">
                                        <Form
                                            {...layout}
                                            name="basic"
                                            initialValues={{}}
                                            onFinish={onFormFinish}
                                            onFinishFailed={onFinishFailed}
                                        >

                                            <Form.Item
                                                label="手机号"
                                                name="oldPhone"
                                                rules={[{ required: true, message: '请填写手机号' }]}
                                            >
                                                <Input placeholder='请填写手机号' suffix=' ' />
                                            </Form.Item>
                                            <Form.Item
                                                label="验证码"
                                                name="oldCode"
                                                rules={[{ required: true, message: '请填写验证码' }]}
                                            >
                                                <Input placeholder='请填写验证码' suffix={
                                                    <a onClick={() => getCode()}>获取验证码</a>
                                                } />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                ) : (
                                    <div style={{ textAlign: 'center' }}>
                                        <Image src={Done} preview={false} width={260} />
                                    </div>
                                )
                                )
                            }

                        </div>
                        <div className="steps-action">
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={() => next()}>
                                    下一步
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" onClick={() => done()}>
                                    确认
                                </Button>
                            )}
                        </div>
                    </>

                </div>
            </Modal>
        </div>
    )
}
function WxChatModal(props) {
    const { isWxChatModal, setIsWxChatModal } = props

    const closeModal = () => setIsWxChatModal(false)

    return (
        <div>
            <Modal getContainer={false} visible={isWxChatModal}
                onOk={closeModal} onCancel={closeModal} footer={[]}>
                <WxQrCode type={WX_QR_TYPE_BIND} onSuccess={() => closeModal()} onFailed={() => closeModal()} />
                <p>请扫码绑定微信</p>
            </Modal>
        </div>
    )
}
