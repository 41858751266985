import React, {useState} from "react";
import {
    apiCreateTeam,
    apiQuitTeam,
    apiLawAddUser,
    apiDismissalTeamUser,
    apiQueryTeamList,
    apiQueryTeamUserList,
    apiUpdateTeamName,
    apiInviteJoinTeam,
    apiAccreditAdminAuth,
    apiQueryTeamUserJoinList,
    apiQueryUserListByCurOfficeId,
    apiQueryAllGrades,
    apiSetUserGrade,
    apiGetAllPosition,
    apiAddUserPosition,
    apiExpelUserByAdmin,
    apiQueryProjectByTeam,
    apiRestartLawyer,
    apiDeleteLawyerRecord,
    apiQueryFewTeamList, apiSetTeamUsualStatus,
} from "../api/apis";
import {Avatar, Dropdown, message, Radio, Space} from "antd";
import {CaretDownFilled} from "@ant-design/icons";
import {ROLE_ADMIN} from "../constants/names";
export const DepartmentContext = React.createContext();

/**
 * 律所
 * @param props
 * @returns {*}
 * @constructor
 */
export const DepartmentProvider = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAddTeamVisible, setIsAddTeamVisible] = useState(false);
    const [isUpdateTeamVisible, setIsUpdateTeamVisible] = useState(false);
    const [isInviteTeamUserVisible, setIsInviteTeamUserVisible] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [fewTeamList, setFewTeamList] = useState([]);
    const [teamUserList,  setTeamUserList] = useState({});
    const [searchTeamUserParams,setSearchTeamUserParams] = useState({id:undefined , pageNo:0, size:10});
    const [teamUserJoinList,setTeamUserJoinList] = useState([]);
    const [officeUserList,setOfficeUserList] = useState({});
    const [positionList,setPositionList] = useState([]);
    const [teamProjectList,  setTeamProjectList] = useState({});
    const [searchTeamProjectParams,setSearchTeamProjectParams] = useState({teamId:undefined , pageNo:0, size:10,searchName:""});
    const [gradesList,setGradesList] = useState([
        {grade:'g_high_partner',gradeName:'高级合伙人'},
        {grade:'g_partner',gradeName:'普通合伙人'},
        {grade:'g_full_time_lawyer',gradeName:'专职律师'},
        {grade:'g_lawyer',gradeName:'律师助理'},
        {grade:'g_intern_lawyer',gradeName:'实习律师'},
        {grade:'g_administrative',gradeName:'行政人员'},
        {grade:'g_advertise_admin',gradeName:'营销负责人'},
    ]);


    const lawAddUser = (params) => {
        apiLawAddUser(params).then((res)=>{
            message.success('添加成功',1).then(()=>{
                setIsModalVisible(false);
                getOfficeUserList({
                    pageNo: 0,
                    size: 10
                })
            })
        })
    }

    //获取团队列表
    const getTeamList = () => {
        apiQueryTeamList().then((res) =>{
            setTeamList(res)
        })
    }

    //获取不常用团队列表
    const getFewTeamList = () => {
        apiQueryFewTeamList().then((res) =>{
            setFewTeamList(res)
        })
    }

    //获取团队成员列表
    const getTeamUserList = (params) => {
        if(params.id>0)
        apiQueryTeamUserList(params).then((res) => {
            setTeamUserList(res)
        })
    }

    //获取律所所有成员列表以及团队加入情况
    const getTeamUserJoinList = (params) => {
        apiQueryTeamUserJoinList(params).then((res) => {
            setTeamUserJoinList(res.teamUserListVOList)
        })
    }

    //查询当前律所的用户列表
    const getOfficeUserList = async (params) => {
        let res = await apiQueryUserListByCurOfficeId(params)
        setOfficeUserList(res)
        return res
    }

    //查询等级列表
    const getAllGradesList = () => {
        apiQueryAllGrades().then((res) =>{
            setGradesList(res)
        })
    }

    //查询所有职位信息
    const getAllPositionList = () => {
        apiGetAllPosition().then((res) =>{
            setPositionList(res)
        })
    }

    //创建团队
    const teamCreate = (params) => {
        apiCreateTeam(params).then((res)=>{
            message.success('创建成功',1).then(()=>{
                setIsAddTeamVisible(false);
            })
            //刷新团队列表
            getTeamList()
        })
    }

    //修改团队名称
    const teamNameUpdate = (params) => {
        apiUpdateTeamName(params).then((res)=>{
            message.success('修改成功',1).then(()=>{
                setIsUpdateTeamVisible(false);
            })
            //刷新团队列表
            getTeamList()
            getFewTeamList()
        })
    }

    //团队管理员邀请律师加入团队
    const inviteJoinTeam = (params) => {
        apiInviteJoinTeam(params).then((res) => {
            message.success('修改成功',1).then(()=>{
                setIsInviteTeamUserVisible(false);
            })
            //刷新团队用户列表
            getTeamUserList(searchTeamUserParams)
        })
    }

    //用户退出团队
    const teamQuit = (params) => {
        apiQuitTeam(params).then((res) => {
            message.success('退出团队成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新团队用户列表
            getTeamUserList(searchTeamUserParams)
        })
    }

    //用户删除团队
    const setTeamUsualStatus = (params) => {
        apiSetTeamUsualStatus(params).then((res) => {
            message.success('删除团队成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新团队列表
            getTeamList()
            getFewTeamList()
        })
    }

    //团队管理员将团队用户踢出团队
    const teamUserDismissal = (params) => {
        apiDismissalTeamUser(params).then((res) => {
            message.success('移除团队成员成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新团队用户列表
            getTeamUserList(searchTeamUserParams)
        })
    }

    //管理员移交管理员权限
    const accreditAdminAuth = (params) => {
        apiAccreditAdminAuth(params).then((res) => {
            message.success('团队管理员权限交接成功',1).then(()=>{
                setIsModalVisible(false);
            })
            //刷新团队用户列表
            getTeamUserList(searchTeamUserParams)
        })
    }

    //管理员设置用户等级
    const setUserGrade = (userId,grade,pageNo) => {
        apiSetUserGrade({
            userId:userId,
            grade:grade
        }).then((res) => {
            message.success('管理员设置用户律师等级成功',1).then(()=>{})
            console.log('pro',pageNo)
            //刷新律所用户列表
            getOfficeUserList({
                pageNo: pageNo-1,
                size: 10
            })
        })
    }

    //管理员给用户添加职位
    const setUserPosition = (userId,position,pageNo) => {
        apiAddUserPosition({
            userId:userId,
            position:position
        }).then((res) => {
            message.success('管理员添加用户职位成功',1).then(()=>{})
            //刷新律所用户列表
            getOfficeUserList({
                pageNo: pageNo-1,
                size: 10
            })
        })
    }

    //管理员给用户踢出律所
    const expelUserByAdmin = (userId,pageNo) => {
        apiExpelUserByAdmin({
            id:userId
        }).then((res) => {
            message.success('管理员操作用户成功',1).then(()=>{
            })
            //刷新律所用户列表
            getOfficeUserList({
                pageNo: pageNo-1,
                size: 10
            })
        })
    }

    //律所管理员重新启用离职律师
    const restartLawyer = (userId,pageNo) => {
        apiRestartLawyer({
            id:userId
        }).then((res) => {
            message.success('管理员操作用户成功',1).then(()=>{
            })
            //刷新律所用户列表
            getOfficeUserList({
                pageNo: pageNo-1,
                size: 10
            })
        })
    }

    //律所管理员删除律师记录
    const deleteLawyerRecord = (userId,pageNo) => {
        apiDeleteLawyerRecord({
            id:userId
        }).then((res) => {
            message.success('管理员操作用户成功',1).then(()=>{
            })
            //刷新律所用户列表
            getOfficeUserList({
                pageNo: pageNo-1,
                size: 10
            })
        })
    }

    //获取团队成员列表
    const getTeamProjectList = async (params) => {
        let res = await apiQueryProjectByTeam(params)
        setTeamProjectList(res)
        return res
    }


    return (
        <DepartmentContext.Provider
            value={{
                isModalVisible,
                setIsModalVisible,
                lawAddUser,
                teamList,
                teamUserList,
                searchTeamUserParams,
                setSearchTeamUserParams,
                getTeamList,
                getTeamUserList,
                teamCreate,
                teamNameUpdate,
                inviteJoinTeam,
                teamQuit,
                teamUserDismissal,
                accreditAdminAuth,
                isAddTeamVisible,
                setIsAddTeamVisible,
                isUpdateTeamVisible,
                setIsUpdateTeamVisible,
                isInviteTeamUserVisible,
                setIsInviteTeamUserVisible,
                getTeamUserJoinList,
                teamUserJoinList,
                getOfficeUserList,
                officeUserList,
                setOfficeUserList,
                getAllGradesList,
                gradesList,
                setUserGrade,
                getAllPositionList,
                positionList,
                setUserPosition,
                expelUserByAdmin,
                getTeamProjectList,
                searchTeamProjectParams,
                teamProjectList,
                setSearchTeamProjectParams,
                restartLawyer,
                deleteLawyerRecord,
                getFewTeamList,
                fewTeamList,
                setTeamUsualStatus
            }}>
            {props.children}
        </DepartmentContext.Provider>
    )
}