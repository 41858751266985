import React, {useState} from "react";
import NoneData from "../assets/none_data.png";
import { Spin } from "antd";
import {useDidMount} from "./state";

/**
 * @loading 加载状态
 * @failed 失败状态
 * @LoadFunc 传入的请求方法
 * @finishFunc 传入的加载完成后执行的方法 成功会有res
 * @res 请求方法的返回值
 */
export function LoadBox(props) {
    const [loading, setLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [res, setRes] = useState(null);
    useDidMount(()=>{
        props.loadFunc().then((res)=>{
            props.finishFunc(res)
            setRes(res)
        }).finally(()=>{
            setTimeout(()=>{
                setLoading(false)
            },1000)
        }).catch(()=>{
            setFailed(true)
            props.finishFunc(res)
        })
    })

    if(loading){
        return(
            <Spin spinning={loading}>
                <NoneDate/>
            </Spin>
        )
    }else if(failed){
        return (
            <NoneDate/>
        )
    } else {
        return(
            <Spin spinning={loading}>
                {props.children}
            </Spin>
        )
    }
}

export function NoneDate() {
    return(
        <div>
            <img src={NoneData} width={120} height={174}  alt=""/>
        </div>
    )
}