import React, {useContext} from "react";
import {ProjectFileContext} from "../provider/file_provider";

export function DownLoadBox(props) {

    const {fileId} = props
    const { downloadFile } = useContext(ProjectFileContext)

    async function beforeFun() {
        console.log("fileId======", fileId)
        let res = await downloadFile({
            fileId:fileId
        })
        console.log("fileUrl======", res)
        window.location.href = res
    }

    return(
        <a onClick={()=>{beforeFun()}}>下载</a>
    )
}