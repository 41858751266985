import React, {forwardRef, useContext, useEffect, useState} from 'react';
import ProTable, { TableDropdown } from '@ant-design/pro-table';
import './approval.less'
import  {Col, Tooltip,Row, Divider, Form, Input, message, Modal, Switch, Avatar, Dropdown} from "antd";
import Text from "antd/es/typography/Text";
import Button from "antd/es/button";
import {SearchImg} from "../../constants/images";
import Image from "antd/es/image";
import {ApprovalContext} from "../../provider/approval_provider";
import {ProjectFinanceContext} from "../../provider/project_finance_provider";
import {ProjectStampContext} from "../../provider/project_stamp_provider";
import {ContractDetailModal, InvoiceDetailModal} from "../project/projectDetailFinance";
import {SealDetailModal} from "../project/projectDetailSeal";
import {ProjectFileContext} from "../../provider/file_provider";
import {isEmpty} from "lodash";
import {ProjectDetailContext} from "../../provider/project_detail_provider";
import {CopyOutlined} from '@ant-design/icons';
import copy from "copy-to-clipboard";


export const ApprovalTable  = forwardRef((props, ref) =>{

    const {tabIndex,pageNo,setPageNo} = props

    const {approvalList,updateInvoiceReceiveStatus,getApprovalList,defaultPageSize,secondType,passApproval,rejectApproval,getApprovalNum,addNumberApi,updateInvoiceAbandonStatus}  = useContext(ApprovalContext)

    const [isVisible,setIsVisible] = useState(false)
    const [isNumberVisible,setIsNumberVisible] = useState(false)
    const [isReceivableVisible,setIsReceivableVisible] = useState(false)
    const [invoiceId,setInvoiceId] = useState(0)

    const [approvalId,setApprovalId] = useState(0)

    const [contractDetailModel,setContractDetailModel] = useState(false)

    const [isInvoiceDetail,setIsInvoiceDetail] = useState(false)

    const [ isSealDetailModal, setIsSealDetailModal] = useState(false);

    const {contractDetail,getContractDetail,confirmInvoiceStatus,invoiceDetail,getInvoiceDetail} = useContext(ProjectFinanceContext)

    const {getProjectStampDetail,projectStampDetail }  = useContext(ProjectStampContext)

    const {getProjectDetail,projectDetail} = useContext(ProjectDetailContext)
    const [isProjectDetailModel,setIsProjectDetailModel] = useState(false);

    const onClickDetail = (secondType,sourceId)=>{
        if(secondType===5) {
            getContractDetail({id:sourceId}).then(()=>{
                setContractDetailModel(true)
            })
        }
        if(secondType===6) {
            getInvoiceDetail({id:sourceId}).then(()=>{
                setIsInvoiceDetail(true)
            })
        }
        if(secondType===7) {
            getProjectStampDetail({
                id:sourceId
            })
            setTimeout(()=>{
                setIsSealDetailModal(true)
            },100)
        }
        if(secondType>0 && secondType<5){
            getProjectDetail({id:sourceId})
            setTimeout(()=>{
                console.log("pppppppppppppppppppp")
                setIsProjectDetailModel(true)
            },100)
        }
    }

    const approvalTypeList=['','立案审批','结项审批','重启项目审批',
        '搁置项目审批','合同审批','发票审批','用章审批','团队邀请']

    const [columns, setColumns] = useState([]);

    const onChange = (page) =>{
        setPageNo(page)
    }

    const  paginationProps = {
        showSizeChanger: true,
        defaultPageSize:defaultPageSize,
        total: approvalList?.totalNum,
        current: pageNo,
        onChange:(page)=>onChange(page),
        showTotal:(total) => `共 ${total} 条`,
        pageSizeOptions:[5,10,20,100]
    };

    const handleShelf = (invoiceId,invoiceStatus)=>{
        if(invoiceId ===undefined||invoiceStatus === undefined) return
        let targetStatus = invoiceStatus == 1?0:1
        updateInvoiceReceiveStatus({
            "id": invoiceId,
            "targetState": targetStatus
        }).then(async ()=>{
            message.success('改变收取状态成功',1)
            await getApprovalList({
                    searchName:inputValue,
                    searchType:parseInt(tabIndex),
                    secondType:parseInt(secondType),
                    pageNo: pageNo-1,
                    size:defaultPageSize
                }
            )
        })
   }

    const changeAbandonStatus = (invoiceId,invoiceStatus)=>{
        if(invoiceId ===undefined||invoiceStatus === undefined) return
        let targetStatus = invoiceStatus == 1?0:1
        updateInvoiceAbandonStatus({
            "id": invoiceId,
            "targetState": targetStatus
        }).then(async ()=>{
            message.success('改变收取状态成功',1)
            await getApprovalList({
                    searchName:inputValue,
                    searchType:parseInt(tabIndex),
                    secondType:parseInt(secondType),
                    pageNo: pageNo-1,
                    size:defaultPageSize
                }
            )
        })
    }

   const handleNumber = (sourceId)=>{
       setInvoiceId(sourceId)
       getInvoiceDetail({id:sourceId}).then(()=>{
           setIsNumberVisible(true)
       })
   }

    const handleReceivable = (sourceId)=>{
        setInvoiceId(sourceId)
        getInvoiceDetail({id:sourceId}).then(()=>{
            setIsReceivableVisible(true)
        })
    }


    const approvalPass = (approvalId) =>{
        passApproval({
            id: approvalId
        }).then(async ()=>{
            message.success('审批通过成功',1)
            await getApprovalNum(
                {searchType:parseInt(tabIndex)}
            )
            await getApprovalList({
                searchName:inputValue,
                searchType:parseInt(tabIndex),
                secondType:parseInt(secondType),
                pageNo: pageNo-1,
                size:defaultPageSize
                }
            )
        })
    }

    const rejectModelLaunch = (approvalId) =>{
        setIsVisible(true)
        setApprovalId(approvalId)
    }

    const approvalReject = (approvalId,remark) =>{
        message.success('审批拒绝成功',1)
        rejectApproval({
            id: approvalId,
            str:remark
        }).then(async (res)=>{
            await getApprovalNum(
                {searchType:parseInt(tabIndex)}
            )
            await getApprovalList({
                    searchName:inputValue,
                    searchType:parseInt(tabIndex),
                    secondType:parseInt(secondType),
                    pageNo: pageNo-1,
                    size:defaultPageSize
                }
            )
            setIsVisible(false)
        })
    }

    function confirmFun(id){
        confirmInvoiceStatus({id:id}).then(()=>{
             getApprovalList({
                    searchName:inputValue,
                    searchType:parseInt(tabIndex),
                    secondType:parseInt(secondType),
                    pageNo: pageNo-1,
                    size:defaultPageSize
                }
            )
        })
    }

    useEffect(()=>{
        let columnList = [{
            title: '审批类型',
            dataIndex: 'secondType',
            key: 'secondType',
            hideInSearch:true,
            render: (dom, record) => (
                record.state==2?
                <div style={{color:'#ff4d4f'}}>
                    {approvalTypeList[dom]}
                </div>:
                    (
                        record.state==1?
                            <div style={{color:'#52c41a'}}>
                                {approvalTypeList[dom]}
                            </div>:
                            <div>
                                {approvalTypeList[dom]}
                            </div>
                    )

            ),
        },
            {
                title: secondType==8?'团队名称':'项目名称',
                dataIndex: 'name',
                key:'name',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f',cursor: 'pointer'}} onClick={()=>onClickDetail(record.secondType,record.sourceId)}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a',cursor: 'pointer'}} onClick={()=>onClickDetail(record.secondType,record.sourceId)}>
                                    {dom}
                                </div>:
                                <a onClick={()=>onClickDetail(record.secondType,record.sourceId)} >{record.name}</a>
                        )
                ),
            },
            {
                title: '发起人',
                dataIndex: 'creatorName',
                key: 'creatorName',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f'}}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a'}}>
                                    {dom}
                                </div>:
                                <div>
                                    {dom}
                                </div>
                        )

                ),
            },
            {
                title: '状态',
                dataIndex: 'state',
                key:'state',
                hideInSearch:true,
                render: (dom, record) => (
                    dom==2?
                        <div style={{color:'#ff4d4f'}}>
                            已驳回
                        </div>:
                        (
                            dom==1?
                                <div style={{color:'#52c41a'}}>
                                    已审核
                                </div>:
                                <div>
                                    未审核
                                </div>
                        )

                ),
                // valueType: 'select',
                // valueEnum: {
                //     0: { text: '未审核', status: 'Default' },
                //     1: { text: '已审核', status: 'Success' },
                //     2: { text: '已驳回', status: 'Error' },
                // },
            },
            {
                title: '审核员',
                dataIndex: 'auditName',
                key: 'auditName',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f'}}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a'}}>
                                    {dom}
                                </div>:
                                <div>
                                    {dom}
                                </div>
                        )

                ),
            },
            {
                title:'发起日期',
                key: 'createAt',
                dataIndex: 'createAt',
                valueType: 'dateTime',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f'}}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a'}}>
                                    {dom}
                                </div>:
                                <div>
                                    {dom}
                                </div>
                        )

                ),
            },
            {
                title:'审核时间',
                key: 'auditAt',
                dataIndex: 'auditAt',
                valueType: 'dateTime',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f'}}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a'}}>
                                    {dom}
                                </div>:
                                <div>
                                    {dom}
                                </div>
                        )

                ),
            },
            {
                title:'备注',
                key: 'remark',
                dataIndex: 'remark',
                hideInSearch:true,
                render: (dom, record) => (
                    record.state==2?
                        <div style={{color:'#ff4d4f'}}>
                            {dom}
                        </div>:
                        (
                            record.state==1?
                                <div style={{color:'#52c41a'}}>
                                    {dom}
                                </div>:
                                <div>
                                    {dom}
                                </div>
                        )

                ),
            },
        ]
        if(tabIndex == 1){
            if(secondType == 6){
                columnList.push({
                    title:'发票抬头',
                    key:'title',
                    dataIndex: 'title',
                    hideInSearch:true,
                    render:(dom, record) => (
                        record.state==2?
                            <div style={{color:'#ff4d4f'}}>
                                {dom}
                            </div>:
                            (
                                record.state==1?
                                    <div style={{color:'#52c41a'}}>
                                        {dom}
                                    </div>:
                                    <div>
                                        {dom}
                                    </div>
                            )
                    ),
                })
                columnList.push({
                    title:'是否领取',
                    key:'receiveStatus',
                    dataIndex: 'receiveStatus',
                    hideInSearch:true,
                    render:(dom, record) => (
                        <Switch
                                disabled={record.confirmStatus==1}
                                defaultChecked={dom===0?false:true}
                                onChange={() => handleShelf(record.sourceId,dom)}
                            />
                    ),
                })
                columnList.push({
                    title:'是否确认领取',
                    key:'confirmStatus',
                    dataIndex: 'confirmStatus',
                    hideInSearch:true,
                    render:(dom) => (
                        dom==1?'已确认':'未确认'
                    ),
                })
                columnList.push({
                    title:'发票编号',
                    valueType: 'option',
                    render: (_,record) =>
                        [<a key="a" onClick={()=>{handleNumber(record.sourceId)}}>编辑填写</a>]
                })
                columnList.push({
                    title:'发票回款',
                    valueType: 'option',
                    render: (_,record) =>
                        [<a key="a" onClick={()=>{handleReceivable(record.sourceId)}}>编辑填写</a>]
                })
                columnList.push({
                    title:'是否废弃',
                    key:'abandonStatus',
                    dataIndex: 'abandonStatus',
                    hideInSearch:true,
                    render:(dom, record) => (
                        <Switch
                            defaultChecked={dom !== 0}
                            onChange={() => changeAbandonStatus(record.sourceId,dom)}
                        />
                    ),
                })
            }
            columnList.push({
                title:'操作',
                valueType: 'option',
                render: (_,record) =>
                    record.state !== 0?[<div>-</div>]:
                            [<a key="a" onClick={()=>{approvalPass(record.id)}}>通过</a>,
                            <a key="b" onClick={()=>{rejectModelLaunch(record.id)}}>驳回</a>]

            })
        }
        if(tabIndex == 2){
            if(secondType == 6) {
                columnList.push({
                    title:'发票抬头',
                    key:'title',
                    dataIndex: 'title',
                    hideInSearch:true,
                    render:(dom, record) => (
                        record.state==2?
                            <div style={{color:'#ff4d4f'}}>
                                {dom}
                            </div>:
                            (
                                record.state==1?
                                    <div style={{color:'#52c41a'}}>
                                        {dom}
                                    </div>:
                                    <div>
                                        {dom}
                                    </div>
                            )
                    ),
                })
                columnList.push({
                    title:'是否领取',
                    key:'receiveStatus',
                    dataIndex: 'receiveStatus',
                    hideInSearch:true,
                    render: (dom) => (
                        <div>
                            {dom==0?"未领取":"已领取"}
                        </div>
                    ),
                })
                columnList.push({
                    title: '申请人确认',
                    dataIndex: 'confirmStatus',
                    key: 'confirmStatus',
                    hideInSearch:true,
                    render: (dom,recond) => (
                        <div>
                            {recond.receiveStatus==0?"":(dom==0?<button className='confirmBtn' onClick={()=>confirmFun(recond.sourceId)}>确认领取</button>:'已确认')}
                        </div>
                    ),
                })
            }
        }
        setColumns(columnList);
        },[secondType,tabIndex,pageNo]
    )

    const [inputValue,setInputValue] = useState("");


    const onClear = value =>{
        setInputValue('')
        setPageNo(1)
        getApprovalList({
            searchName:'',
            searchType:parseInt(tabIndex),
            secondType:parseInt(secondType),
            pageNo: 0,
            size:defaultPageSize
        })
    }
    const searchOnChange = (e) => {
        setInputValue(e.target.value)
    };
    const searchOnClick = () => {
        getApprovalList({
            searchName:inputValue,
            searchType:parseInt(tabIndex),
            secondType:parseInt(secondType),
            pageNo: 0,
            size:defaultPageSize
        })
    };

    return (
        <div>
            <ProTable
                className='approval-table'
                columns={columns}
                pagination = {paginationProps}
                dataSource={approvalList.list}
                rowKey={r => r.id}
                options={false}
                search={{filterType: 'light',}}
                dateFormatter="string"
                request={async (params = {}, sort, filter) => {
                    setPageNo(params.current)
                    const res = getApprovalList({
                        searchName:inputValue,
                        searchType:parseInt(tabIndex),
                        secondType:parseInt(secondType),
                        pageNo: params.current-1,
                        size:params.pageSize
                    })
                    return Promise.resolve({
                        data: res.list,
                        success: true,
                        total:res.totalNum
                    });

                }}
                headerTitle={
                    <div className='select-input'>
                        <Input
                            placeholder="搜索名称，发起人，审核人"
                            prefix={<Image src={SearchImg} preview={false} width={20}/>}
                            suffix={
                                <Button className='select-text' type="text" onClick ={(e)=>onClear(e)}>清除</Button>

                            }
                            onPressEnter={searchOnClick} value={inputValue} onChange={(e) =>{searchOnChange(e)}}
                        />

                    </div>
                }
            />
            <AddNumberModal isNumberVisible={isNumberVisible} setIsNumberVisible={setIsNumberVisible} invoiceId={invoiceId} addNumberApi={addNumberApi} invoiceDetail={invoiceDetail}/>
            <AddReceivableModal isReceivableVisible={isReceivableVisible} setIsReceivableVisible={setIsReceivableVisible} invoiceId={invoiceId} invoiceDetail={invoiceDetail}/>
            <RejectApprovalModal isVisible={isVisible} setIsVisible = {setIsVisible} approvalId={approvalId} approvalReject={approvalReject}/>
            <ContractDetailModal setContractDetailModel={setContractDetailModel} contractDetailModel={contractDetailModel} contractDetail={contractDetail}/>
            <InvoiceDetailModal isInvoiceDetail={isInvoiceDetail} setIsInvoiceDetail={setIsInvoiceDetail} invoiceDetail={invoiceDetail}/>
            <SealDetailUpdateModal tabIndex={tabIndex} projectStampDetail={projectStampDetail} setIsSealDetailModal={setIsSealDetailModal} isSealDetailModal={isSealDetailModal}/>
            <ProjectDetailModel isProjectDetailModel={isProjectDetailModel} setIsProjectDetailModel={setIsProjectDetailModel} projectDetail={projectDetail}/>
        </div>
        );
});


//驳回备注弹窗
function RejectApprovalModal(props){

    const {isVisible, setIsVisible,approvalId,approvalReject} = props


    const handleOk = () => {
        setIsVisible(false);
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        approvalReject(approvalId,e.remark)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>审核驳回</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="原因"
                                name="remark"
                                rules={[{ required: true, message: '请输入驳回原因' }]}
                            >
                                <Input placeholder="请输入驳回原因" className='content-model-body-form-input'/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//填写发票编号弹窗
function AddNumberModal(props){

    const {isNumberVisible, setIsNumberVisible,invoiceId,addNumberApi,invoiceDetail} = props


    const handleOk = () => {
        setIsNumberVisible(false);
    };

    const handleCancel = () => {
        setIsNumberVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        addNumberApi({id:invoiceId,str:e.invoiceNumber})
        setIsNumberVisible(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isNumberVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>填写发票编号</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="发票编号"
                                name="invoiceNumber"
                                rules={[{ required: true, message: '请输入发票编号' }]}
                                initialValue={invoiceDetail.invoiceNumber}
                            >
                                <Input placeholder="请输入发票编号" className='content-model-body-form-input' />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//填写发票回款弹窗
function AddReceivableModal(props){

    const {isReceivableVisible, setIsReceivableVisible,invoiceId,invoiceDetail} = props

    const {apiUpdateInvoiceReceivable} = useContext(ApprovalContext)

    const handleOk = () => {
        setIsReceivableVisible(false);
    };

    const handleCancel = () => {
        setIsReceivableVisible(false);
    };

    function onFormFinish(e) {
        console.log("onFormFinish",e)
        apiUpdateInvoiceReceivable({id:invoiceId,str:e.invoiceReceivable})
        setIsReceivableVisible(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 },
    };

    const tailLayout = {
        wrapperCol: { offset: 4, span: 20 },
    };

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isReceivableVisible}
                   onOk={handleOk} onCancel={handleCancel} width = "30%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>填写发票回款</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '5%'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{}}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="发票回款"
                                name="invoiceReceivable"
                                rules={[{ required: true, message: '请输入发票回款' }]}
                                initialValue={invoiceDetail.receivable}
                            >
                                <Input placeholder="请输入发票回款" className='content-model-body-form-input' />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <div className='content-model-body-form-btn-box'>
                                    <Button onClick={handleCancel} className='content-model-body-form-btn-style'>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                        确认
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
            </Modal>
        </div>)
}

//盖章详情弹窗带修改盖章编号的功能
function SealDetailUpdateModal(props){

    const { TextArea } = Input;
    const { downloadFile } = useContext(ProjectFileContext)
    const {tabIndex,isSealDetailModal,setIsSealDetailModal,projectStampDetail} = props

    const {updateDocumentsNumber} = useContext(ProjectStampContext)

    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };
    const layout = {
        wrapperCol: { span: 20 },
    };


    const handleCancel = () => {
        setIsSealDetailModal(false);
    };

    function onFormFinish(e) {
        if(!isEmpty(e.sealNo)){
            updateDocumentsNumber({
                id: projectStampDetail.id,
                str: e.sealNo
            }).then(()=>{
                setIsSealDetailModal(false)
            })
        }
    }

    const onFinishFailed= (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const documentsTypeList = ['律师事务所所函','委托合同','法律意见书','尽职调查报告','法律见证书','法律顾问工作报告','委托授权书','律师调查专用介绍信','刑事会见介绍信','其他']
    const stampTypeList = ['公章','合同专用章' ,'见证章' ,'财务章' ,'无需盖章']


    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    function copyValue(value){
        copy(value)
        message.success("复制成功").then()
    }

    return (
        <div className='content-model'>
            <Modal getContainer={false} visible={isSealDetailModal} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>盖章详情</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '0'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                projectName:projectStampDetail.projectName,
                                projectNum:projectStampDetail.projectNumber,
                                sealName:documentsTypeList[projectStampDetail.documentsType-1],
                                sealType:stampTypeList[projectStampDetail.stampType],
                                sealNumber:projectStampDetail.stampNum,
                                sealNo:projectStampDetail.documentsNumber,
                                mark:projectStampDetail.mark
                            }}
                            onFinish={onFormFinish}
                            onFinishFailed={onFinishFailed}
                        >

                            <div className='model-title'>盖章信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>项目名称</div>
                                    <Form.Item
                                        name="projectName"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectStampDetail.projectName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectStampDetail.projectNumber)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>文书信息</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Row className='model-row' justify="space-between">
                                <Col span={7}>
                                    <div className='project-input-title-row'>文书类型</div>
                                    <Form.Item
                                        name="sealName"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(documentsTypeList[projectStampDetail.documentsType-1])}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章类型</div>
                                    <Form.Item
                                        name="sealType"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(stampTypeList[projectStampDetail.stampType])}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <div className='project-input-title-row'>盖章份数</div>
                                    <Form.Item
                                        name="sealNumber"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectStampDetail.stampNum)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={24}>
                                    <div className='project-input-title'>文书编号</div>
                                    <Form.Item
                                        name="sealNo"
                                    >
                                        <Input disabled={tabIndex==1?false:true}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>文书附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                projectStampDetail?.annex?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                            <div className='model-audit'>
                                <span>审核人:</span><span>{projectStampDetail.auditName}</span>
                            </div>
                            <div className='model-title'>备注<CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(projectStampDetail.mark)}/></div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Form.Item
                                name="mark"
                            >
                                <TextArea rows={4} className='model-textArea' disabled={true}/>
                            </Form.Item>

                            {tabIndex == 1 ?
                                <Form.Item {...tailLayout}>
                                    <div className='content-model-body-form-btn-box'>
                                        <Button type="primary" htmlType="submit" className='content-model-body-form-btn-style'>
                                            保存
                                        </Button>
                                    </div>
                                </Form.Item>:<div/>
                            }
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

//项目审核弹窗
function ProjectDetailModel(props){

    const {isProjectDetailModel,setIsProjectDetailModel,projectDetail} = props
    const { downloadFile } = useContext(ProjectFileContext)

    const handleCancel = () => {
        setIsProjectDetailModel(false);
    };

    const { TextArea } = Input;

    const layout = {
        wrapperCol: { span: 20 },
    };

    async function downLoadFileFun(fileId) {
        let res = await downloadFile({
            fileId: fileId
        })
        window.location.href = res
    }

    function copyValue(value){
        copy(value)
        message.success("复制成功").then()
    }

    const projectState = ["预立案", "进行中", "已完结", "已搁置"]

    const proLevel = ["无", "中", "低", "高"]

    const sortList  = ["当事人","第三方","相关方","对方当事人关联公司","委托方","委托方关联公司","相关利益潜在第三方","合同特别限制代理方"];

    return(
        <div className='content-model'>
            <Modal getContainer={false} visible={isProjectDetailModel} onCancel={handleCancel} width = "50%" centered footer = {[]}>
                <div className="content-model-title">
                    <Divider className='content-model-title-name'>项目详情</Divider>
                </div>
                <div className = "content-model-body" style={{marginTop: '0'}}>
                    <div className = "content-model-body-form">
                        <Form
                            {...layout}
                            name="basic"
                            initialValues={{
                                name:projectDetail.name,
                                projectNum:projectDetail.projectNum,
                                state:projectState[projectDetail.state],
                                groupName:projectDetail.groupName,
                                projectLevel:proLevel[projectDetail.projectLevel],
                                caseType:projectDetail.caseTypeStr,
                                sourceUserName:projectDetail.sourceUserName,
                                undertakerUserName:projectDetail.undertakerUserName,
                                brokerType:projectDetail.brokerType,
                                detail:projectDetail.detail,

                            }}
                        >

                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>项目名称</div>
                                    <Form.Item
                                        name="name"
                                    >
                                        <Input disabled={true}  suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.name)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title'>案号</div>
                                    <Form.Item
                                        name="projectNum"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.projectNum)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={8}>
                                    <div className='project-input-title'>项目状态</div>
                                    <Form.Item
                                        name="state"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectState[projectDetail.state])}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <div className='project-input-title'>项目等级</div>
                                    <Form.Item
                                        name="projectLevel"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(proLevel[projectDetail.projectLevel])}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <div className='project-input-title'>所属团队</div>
                                    <Form.Item
                                        name="groupName"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.groupName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>
                                        项目成员
                                    </div>
                                    <Form.Item
                                        name="membersList"
                                    >
                                        {
                                            projectDetail?.membersList === undefined ? undefined :
                                                projectDetail.membersList.map((item,index) =>
                                                    item?.belongAdmin===1?(<Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                            <div className='right-item-box-body-row-space-left'>
                                                                <div style={{marginLeft:"10px"}}>
                                                                    <div className='right-item-box-body-title'>{item.realName}<span className='right-item-box-body-title-bold'>{item?.belongAdmin===1?'(项目管理员)':''}</span></div>
                                                                </div>
                                                            </div>
                                                            <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item.realName)}/>
                                                        </Row>):
                                                        (<Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                            <div className='right-item-box-body-row-space-left'>
                                                                <div style={{marginLeft:"10px"}}>
                                                                    <div className='right-item-box-body-title'>{item.realName}</div>
                                                                </div>
                                                            </div>
                                                            <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item.realName)}/>
                                                        </Row>)
                                                )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title-row'>客户</div>
                                    <Form.Item
                                        name="customerList"
                                    >
                                        {
                                            projectDetail?.customerList === undefined ? undefined :
                                                projectDetail.customerList.map((item,index) =>
                                                    <Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                        <div className='right-item-box-body-row-space-left'>
                                                            <div style={{marginLeft:"10px"}}>
                                                                <div className='right-item-box-body-title'>{item.name}</div>
                                                            </div>
                                                        </div>
                                                        <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item.name)}/>
                                                    </Row>
                                                )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row' justify="space-between">
                                <Col span={12}>
                                    <div className='project-input-title-row'>对方当事人</div>
                                    <Form.Item
                                        name="defendants"
                                    >
                                        {
                                            projectDetail?.defendants === undefined ?
                                                <Row className='right-item-box-body-row-space' style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                    <div className='right-item-box-body-row-space-left'>
                                                        <div style={{marginLeft:"10px"}}>
                                                            <div className='right-item-box-body-title'></div>
                                                        </div>
                                                    </div>
                                                    <CopyOutlined style={{marginLeft:'10px'}} />
                                                </Row> :
                                                projectDetail.defendants.map((item,index) =>
                                                        <Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                            <div className='right-item-box-body-row-space-left'>
                                                                <div style={{marginLeft:"10px"}}>
                                                                    <div className='right-item-box-body-title'>{item}</div>
                                                                </div>
                                                            </div>
                                                            <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item)}/>
                                                        </Row>
                                                )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <div className='project-input-title-row'>第三方</div>
                                    <Form.Item
                                        name="others"
                                    >
                                        {
                                            (projectDetail?.others === undefined || projectDetail?.others.length==0 )?
                                                <Row className='right-item-box-body-row-space' style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                    <div className='right-item-box-body-row-space-left'>
                                                        <div style={{marginLeft:"10px"}}>
                                                            <div className='right-item-box-body-title'></div>
                                                        </div>
                                                    </div>
                                                    <CopyOutlined style={{marginLeft:'10px'}} />
                                                </Row> :
                                                projectDetail.others.map((item,index) =>
                                                    <Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                        <div className='right-item-box-body-row-space-left'>
                                                            <div style={{marginLeft:"10px"}}>
                                                                <div className='right-item-box-body-title'>{item.name+"("+sortList[item.type]+")"}</div>
                                                            </div>
                                                        </div>
                                                        <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item.name)}/>
                                                    </Row>
                                                )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={6}>
                                    <div className='project-input-title'>案件类型</div>
                                    <Form.Item
                                        name="caseType"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.caseTypeStr)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <div className='project-input-title'>代理何方</div>
                                    <Form.Item
                                        name="brokerType"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.brokerType)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <div className='project-input-title'>案源律师</div>
                                    <Form.Item
                                        name="sourceUserName"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.sourceUserName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <div className='project-input-title'>承办律师</div>
                                    <Form.Item
                                        name="undertakerUserName"
                                    >
                                        <Input disabled={true} suffix={
                                            <Tooltip title="复制">
                                                <CopyOutlined onClick={()=>copyValue(projectDetail.undertakerUserName)}/>
                                            </Tooltip>
                                        }/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='model-row'>
                                <Col span={12}>
                                    <div className='project-input-title'>代理审级</div>
                                    <Form.Item
                                        name="auditList"
                                    >
                                        {
                                            projectDetail?.auditList === undefined ? undefined :
                                                projectDetail.auditList.map((item,index) =>
                                                    <Row className='right-item-box-body-row-space' key={index} style={{marginBottom:"5px",backgroundColor:'#f5f5f5',color:'rgba(0, 0, 0, 0.25)',border:'1px solid #d9d9d9',borderRadius:"2px",padding:'5px 0',display:'flex',alignItems:'center'}}>
                                                        <div className='right-item-box-body-row-space-left'>
                                                            <div style={{marginLeft:"10px"}}>
                                                                <div className='right-item-box-body-title'>{item}</div>
                                                            </div>
                                                        </div>
                                                        <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(item)}/>
                                                    </Row>
                                                )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='model-title'>合同附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                projectDetail?.contract?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                            <div style={{marginBottom:"20px"}}></div>
                            <div className='model-title'>用章附件</div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            {
                                projectDetail?.stamp?.map((res)=>
                                    <Row>
                                        <a onClick={()=>downLoadFileFun(res.id)}>{res.name}</a>
                                    </Row>
                                )
                            }
                            <div style={{marginBottom:"20px"}}></div>
                            <div className='model-title'>
                                项目详情
                                <CopyOutlined style={{marginLeft:'10px'}} onClick={()=>copyValue(projectDetail.detail)}/>
                            </div>
                            <Divider type="horizontal" orientation="center" className='divider-title' />
                            <Form.Item
                                name="detail"
                            >
                                <TextArea rows={4} className='model-textArea' disabled={true}/>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
