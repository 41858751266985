import React, {useContext, useState} from "react";
import {Avatar, Button, message, Upload} from "antd";
import {ProjectFileContext} from "../provider/file_provider";
import {UserInfoContext} from "../provider/userInfo_provider";
import {ProjectDetailContext} from "../provider/project_detail_provider";
import SparkMD5 from 'spark-md5'
import {Base64} from 'js-base64'
import axios from "axios";
import {UploadOutlined} from "@ant-design/icons";
import {omit} from "lodash";
import {TaskContext} from "../provider/task_provider";

export function UploadImageBox(props) {

    let {src,setOtherList,otherList,showOtherList,setShowOtherList} = props
    const [imageUrl,setImageUrl] = useState("")

    const { confirmImageFile,createImageFile} = useContext(ProjectFileContext)
    const { setHeadImg} = useContext(UserInfoContext)
    const [fileList,setFileList] = useState([])

    function getMd5FromArrayBuffer(fileArrayBuffer) {
        const spark = new SparkMD5.ArrayBuffer()
        spark.append(fileArrayBuffer); // Append array buffer
        const md5Hex = spark.end()
        const baseMd5 = Base64.fromUint8Array(Uint8Array.from(Buffer.from(md5Hex, 'hex')));
        console.log("file baseMd5：" + baseMd5)
        return baseMd5;
    }

    async function readAsArrayBuffer(file) {
        return  new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload =async function (e) {
                resolve(fileReader.result)
            };

            fileReader.onerror = function () {
                console.warn('oops, something went wrong.');
                reject()
            };
            fileReader.readAsArrayBuffer(file);
        })
    }

    const uploadImage = async options => {
        const { onSuccess, onError, file, onProgress } = options;
        console.log("file======",file)
        const fileArrayBuffer = await readAsArrayBuffer(file);
        const fileMd5 = getMd5FromArrayBuffer(fileArrayBuffer);
        let createFileRes = await createImageFile({
            normalFileType: 1002,
            fileMd5: fileMd5,
            fileName: file.name,
            fileSize: file.size,
        })

        const config = {
            headers: {
                "content-Type":"application/octet-stream",
                "content-md5": fileMd5
                // "Content-Disposition": 'file; filename="' + file.name + '"',
            },
            onUploadProgress: event => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                //TODO: setProgress(percent); 设置进度
                if (percent === 100) {
                    //TODO: setTimeout(() => setProgress(0), 1000); 上传完毕
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };

        try {
            const res = await axios.put(
                createFileRes.ossUrl,
                fileArrayBuffer,
                config
            );
            // 文件上传完毕
            confirmImageFile({"fileId": createFileRes.fileId})
            onSuccess("Ok");
            let imageUrl = createFileRes.ossUrl.split("?");
            setHeadImg(imageUrl[0]);
            setImageUrl(imageUrl[0]);
            message.success("上传成功")
            console.log("server res: ", res);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
    };

    const handleChange = info => {
        let fileList = [...info.fileList];
        //setShowOtherList(fileList)
    };

    return(
        <Upload
            customRequest={uploadImage}
            onChange={handleChange}
            multiple={false}
            showUploadList={false}
        >
            <Avatar size={120} src={imageUrl? imageUrl: src} />
        </Upload>
    )
}
